import { Box } from '@mui/material';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import MunicipalityFormList from '../components/MunicipalityFormList';

const HomeMunicipality: React.FC = () => (
  <Layout title="Formulaires">
    <Spinner show={false}/>
    <Box sx={{
      p: 2,
      height: 'calc(100vh - 64px)',
    }}>
      <MunicipalityFormList />
    </Box>
  </Layout>
);

export default HomeMunicipality;
