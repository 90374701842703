import {
  Box, Button, Card, CardContent, CardHeader, Container, Divider, IconButton, InputAdornment, Tooltip,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import translator from '../../../theme/translator.json';
import Utils from '../../../utils/Utils';
import ErrorService from '../../../services/ErrorService';
import Spinner from '../../../components/Spinner';
import UserService from '../../../services/UserService';
import { auth } from '../../../FirebaseConfig';
import AlertUtils from '../../../utils/AlertUtil';
import PromptForCredentials from '../../../components/PromptForCredentials';
import Municipality from '../../../models/Municipality';
import MunicipalityService from '../../../services/MunicipalityService';

const ChangeCode: React.FC = () => {
  const municipality = useAppSelector((state) => state.user.municipality) as Municipality;
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [showPromptCredential, setShowPromptCredential] = useState(false);

  const handlePromptCredentialAlert = (show: boolean) => {
    if (show) setShowPromptCredential(true);
    else setShowPromptCredential(false);
  };

  const generateCode = (formikP?: any) => {
    const newCode = Utils.generateCode();
    if (formikP) {
      formikP.getFieldHelpers('password').setValue(newCode);
    }
  };

  const changeCode = async (values: any) => {
    try {
      setLoading(true);
      const { password } = values;

      if (auth.currentUser) {
        const tempMunicipality = {
          ...municipality,
        };
        await UserService.updatePassword(auth.currentUser, password);
        tempMunicipality.password = password;
        await MunicipalityService.update(tempMunicipality);
      }

      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      // Change password needs reauthantification if credentials have timed out
      if (e.code === 'auth/requires-recent-login') {
        setLoading(false);
        setShowPromptCredential(true);
      } else {
        setLoading(false);
        ErrorService.handleError(e, dispatch);
      }
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          marginTop: '20px',
        }}
      >
        {
          municipality
            && <Formik
              initialValues={{
                password: municipality?.password,
              }}
              validationSchema={yup.object({
                password: yup.string().min(6).required(Utils.getTranslation(translator.formMessages.requiredField)),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                changeCode(values);
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form>
                  <Card>
                    <CardHeader
                      title={Utils.getTranslation(translator.pages.settings.changeCode.title)}
                      subheader={Utils.getTranslation(translator.pages.settings.changeCode.subheader)}
                    />
                    <Divider />
                    <CardContent>
                      <Field
                        component={TextField}
                        name="password"
                        type="text"
                        label={Utils.getTranslation(translator.pages.settings.changeCode.inputs.password)}
                        margin='normal'
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Générer un code">
                                <IconButton
                                  aria-label="Generate code"
                                  onClick={() => generateCode(formikProps)}
                                  onMouseDown={(e: any) => e.preventDefault()}
                                  color="info"
                                >
                                  <LoopIcon/>
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {Utils.getTranslation(translator.pages.settings.changeCode.submit)}
                      </Button>
                    </Box>
                  </Card>
                </Form>
              )}
            </Formik>
        }
      </Container>
      <Spinner show={loading} />
      {
        showPromptCredential
          && <PromptForCredentials show={showPromptCredential} setShow={handlePromptCredentialAlert}/>
      }
    </>
  );
};

export default ChangeCode;
