/* eslint-disable quote-props */
import Question from '../Question';
import { defaultBasedAverage, defaultNiveau } from '../SlotHelp.spec';
import SlotTypes from '../SlotTypes';
import QuestionType from '../enums/QuestionType';
import SlotUnit from '../enums/SlotUnit';

/* eslint-disable camelcase */
/* eslint-disable max-len */
const question1_1: Question = {
  id: '1',
  number: '1.1',
  title: 'Création d\'un document officiel démontrant son engagement',
  idEM: 1,
  type: QuestionType.ACTIVITY,
  description: ['Un document officiel prônant l’engagement moral de l’organisation municipale à égard de la préservation de la biodiversité (par exemple, une résolution d’appui) a été adopté par le conseil municipal.'],
  frequency: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoringFct: (data: string[]) => parseFloat(data[0]),
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_2: Question = {
  id: '2',
  title: 'Reconnaissance d\'une espèce emblématique',
  number: '1.2',
  type: QuestionType.ACTIVITY,
  idEM: 14,
  frequency: 1,
  description: ['Une espèce faunique ou floristique emblématique représentative de la biodiversité du territoire a été adoptée par le conseil municipal. Celle-ci peut être mise en valeur dans les communications de l\'organisation et faire l\'objet de démarches de conservation particulières.'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoringFct: (data: string[]) => parseFloat(data[0]),
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_3: Question = {
  id: '3',
  title: 'Publication d\'un plan stratégique local',
  number: '1.3',
  type: QuestionType.ACTIVITY,
  idEM: 1,
  frequency: 1,
  description: ['Un plan stratégique local pour la préservation de la diversité biologique a été publié. Il peut s\'agir d\'une politique, d\'un plan stratégique ou d\'un plan d\'action qui fixe des objectifs tangibles. Si applicable, indiquer la date d\'échéance.'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoringFct: (data: string[]) => parseFloat(data[0]),
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_4: Question = {
  id: '4',
  title: 'Mise en place de programmes de financement ou de services',
  number: '1.4',
  idEM: 13,
  frequency: 1,
  type: QuestionType.ACTIVITY,
  description: ['L’organisme municipal a mis en place des programmes offrant du financement ou des biens et services liés à des activités visant la conservation de la biodiversité. Ces programmes sont destinés aux citoyens, aux entreprises ou aux organisations. L’organisation municipale finance ces projets, mais n’y participe pas.'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoringFct: (data: string[]) => parseFloat(data[0]),
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_5: Question = {
  id: '5',
  title: 'Montants investis dans des programmes incitatifs',
  number: '1.5',
  frequency: 1,
  type: QuestionType.INDICATOR,
  description: ['Cet indicateur est mesuré selon les montants investis dans ces programmes, divisé par le nombre d\'habitants. Le nombre de points est ensuite déterminé en comparant le résultat à la moyenne des résultats des organismes municipaux du PEXBD.'],
  slots: [
    {
      type: SlotTypes.REPEATER,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 100) return 4;
        if (data >= 75) return 3;
        if (data >= 50) return 2;
        if (data >= 25) return 1;
        return 0;
      },
      score: '0',
      maxScore: 4,
      repeaterFields: [
        {
          label: 'Nom du programme',
          slug: 'name',
          width: '30%',
        },
        {
          label: 'Personnes visées',
          slug: 'people',
          width: '30%',
        },
        {
          label: 'Montant accordé',
          slug: 'amount',
          width: '20%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          width: '20%',
        },
      ],
      help: defaultBasedAverage,
      unit: SlotUnit.DOLLAR_PER_RESIDENT,
    },
  ],
};

const question1_6: Question = {
  id: '6',
  title: 'Allocation d\'un budget pour la biodiversité',
  number: '1.6',
  type: QuestionType.ACTIVITY,
  idEM: 13,
  frequency: 1,
  description: ['Une certaine partie du budget annuel de l’organisme municipal est alloué à des activités liées à la préservation de la biodiversité.'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_7: Question = {
  id: '7',
  title: 'Montant alloué à la biodiversité',
  number: '1.7',
  frequency: 1,
  type: QuestionType.INDICATOR,
  description: ['La liste détaillée des montants alloués annuellement aux ressources humaines et aux activités liées à la préservations de la biodiversité est complétée.'],
  slots: [
    {
      type: SlotTypes.REPEATER,
      items: [''],
      data: [
        {
          secteur: 'Ressources humaines',
          comment: '',
          amount: '0',
        },
        {
          secteur: 'Acquisition de connaissances',
          comment: '',
          amount: '0',
        },
        {
          secteur: 'Action terrain',
          comment: '',
          amount: '0',
        },
        {
          secteur: 'Lutte aux espèces envahissantes',
          comment: '',
          amount: '0',
        },
        {
          secteur: 'Sensibilisation',
          comment: '',
          amount: '0',
        },
        {
          secteur: 'Subventions accordées',
          comment: '',
          amount: '0',
        },
      ],
      repeaterFields: [
        {
          label: 'Secteur',
          slug: 'secteur',
          width: '30%',
        },
        {
          label: 'Montant alloué',
          slug: 'amount',
          width: '25%',
          type: 'number',
        },
        {
          label: 'Commentaire',
          slug: 'comment',
          width: '45%',
        },
      ],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 100) return 4;
        if (data >= 75) return 3;
        if (data >= 50) return 2;
        if (data >= 25) return 1;
        return 0;
      },
      score: '0',
      maxScore: 4,
      help: defaultBasedAverage,
      unit: SlotUnit.DOLLAR_PER_RESIDENT,
    },

  ],
};

const question1_8: Question = {
  id: '8',
  title: 'Création d\'un fond pour la protection de la biodiversité',
  number: '1.8',
  type: QuestionType.ACTIVITY,
  idEM: 13,
  frequency: 1,
  description: ['L\'organisme municipale a mis en place ou participe à un ou plusieurs fonds pour la biodiversité.'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_9: Question = {
  id: '9',
  title: 'Montant contribué à un fonds',
  number: '1.9',
  type: QuestionType.INDICATOR,
  frequency: 1,
  description: [],
  slots: [
    {
      type: SlotTypes.REPEATER,
      data: ['0'],
      items: [''],
      repeaterFields: [
        {
          label: 'Nom du Fonds',
          slug: 'fonds',
          width: '45%',
        },
        {
          label: 'Montant alloué',
          slug: 'amount',
          width: '35%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          width: '20%',
        },
      ],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 100) return 4;
        if (data >= 75) return 3;
        if (data >= 50) return 2;
        if (data >= 25) return 1;
        return 0;
      },
      score: '0',
      maxScore: 4,
      help: defaultBasedAverage,
      unit: SlotUnit.DOLLAR_PER_RESIDENT,
    },
  ],
};

const question1_10: Question = {
  id: '10',
  title: 'Prise en compte de la biodiversité dans le règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU).',
  number: '1.10',
  type: QuestionType.ACTIVITY,
  idEM: 1,
  frequency: 1,
  description: ['Prise en compte de la biodiversité dans le règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_11: Question = {
  id: '11',
  title: 'Prise en compte de la biodiversité dans le schéma d’aménagement et de développement (SAD) ou Plan métropolitain d’aménagement et de développement (PMAD)',
  number: '1.11',
  description: [''],
  type: QuestionType.ACTIVITY,
  idEM: 1,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_12: Question = {
  id: '12',
  title: 'Prise en compte de la biodiversité dans le plan de développement de la zone agricole (PDZA)',
  number: '1.12',
  type: QuestionType.ACTIVITY,
  idEM: 1,
  frequency: 1,
  description: [''],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_13: Question = {
  id: '13',
  title: 'Participation à un ou plusieurs programmes de conservation',
  number: '1.13',
  idEM: 14,
  frequency: 1,
  type: QuestionType.ACTIVITY,
  description: [''],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question1_14: Question = {
  id: '14',
  title: 'Montant investis par habitants pour ces programmes',
  number: '1.14',
  frequency: 1,
  type: QuestionType.INDICATOR,
  description: ['Montant total investit dans les programmes de conservation ou sensibilisation (calculé en $ par habitants)'],
  slots: [
    {
      type: SlotTypes.REPEATER,
      items: [''],
      data: ['0'],
      repeaterFields: [
        {
          label: 'Nom du programme',
          slug: 'name',
          width: '40%',
        },
        {
          label: 'Montant alloué',
          slug: 'amount',
          width: '40%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          width: '20%',
        },
      ],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 100) return 4;
        if (data >= 75) return 3;
        if (data >= 50) return 2;
        if (data >= 25) return 1;
        return 0;
      },
      score: '0',
      maxScore: 4,
      help: defaultBasedAverage,
      unit: SlotUnit.DOLLAR_PER_RESIDENT,
    },
  ],
};

export {
  question1_1,
  question1_2,
  question1_3,
  question1_4,
  question1_5,
  question1_6,
  question1_7,
  question1_8,
  question1_9,
  question1_10,
  question1_11,
  question1_12,
  question1_13,
  question1_14,
};
