import {
  Alert,
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography,
} from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { db } from '../../../FirebaseConfig';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import AlertUtils from '../../../utils/AlertUtil';
import Utils from '../../../utils/Utils';
import Informations from './steps/Informations';
import Instructions from './steps/Instructions';
import translator from '../../../theme/translator.json';
import FormState from '../../../models/enums/FormState';
import FormSidebar from './components/FormSidebar';
import { PEXBDFormContainer, PEXBDFormWrapper } from './components/StyledComponents';
import GeneratedFormStep from './steps/GeneratedFormStep';
import FormSpecGroup1 from './models/FormPEXBDGroup1.spec';
import FormSpecGroup2 from './models/FormPEXBDGroup2.spec';
import User from '../../../models/User';
import Permissions from '../../../models/enums/Permissions';
import PromptGenerateReport from './components/PromptGenerateReport';
import FormService from '../../../services/FormService';

type Props = {
  formId:string | undefined,
}

// TODO add auto-save or save on leave
const FormPEXBD: React.FC<Props> = ({ formId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;
  const [initialFormValues, setInitialFormValues] = useState([]) as any[];
  const [formIsInit, setFormIsInit] = useState(false);
  const [formState, setFormState] = useState('');
  const [formSpec, setFormSpec] = useState([]) as any;
  const [showGenerateReportPrompt, setShowGenerateReportPrompt] = useState(false);
  const [programId, setProgramId] = useState('');
  const [municipalityId, setMunicipalityId] = useState('');
  const [submitForAnalysisPopupOpen, setSubmitForAnalysisPopupOpen] = useState(false);
  const [submitFormCheckbox, setSubmitFormCheckbox] = useState(false);

  useEffect(() => {
    const fetchInitialValues = async () => {
      if (!initialFormValues || !formId || !user) return;

      const formDocRef = doc(db, 'Forms', formId);

      const formDoc = await getDoc(formDocRef);

      if (formDoc.exists() && formDoc.data().state && formDoc.data().formData) {
        setFormSpec();
        setFormState(formDoc.data().state);
        const { formData } = formDoc.data();

        setMunicipalityId(formDoc.data().municipalityId);
        setProgramId(formDoc.data().programId);
        if (formDoc.data().programId === '2Py0tJHhTfbE8qWPjcd4') setFormSpec(FormSpecGroup1);
        if (formDoc.data().programId === 'PlhU9DxMiv4loyvKLkHE') setFormSpec(FormSpecGroup2);

        // expose form state to values
        formData.isDisabled = ((formDoc.data().state !== FormState.IN_PROGRESS) && (user?.permission !== Permissions.ADMIN));
        setInitialFormValues(formData);
        setFormIsInit(true);
      }
    };
    fetchInitialValues();
  }, [user]);

  const saveData = async (formData:any) => {
    if (formId) {
      try {
        const formDocRef = doc(db, 'Forms', formId);

        await updateDoc(formDocRef, {
          formData,
        });
        AlertUtils.createSuccessAlert('Le formulaire a été sauvegardé.', dispatch);
      } catch {
        AlertUtils.createErrorAlert(Utils.getTranslation(translator.errorMessages.general.unknown), dispatch);
      }
    } else {
      AlertUtils.createErrorAlert('Il y a eu un problème en sauvegardant le formulaire.', dispatch);
    }
  };

  const downloadFormCSV = (values:any) => {
    if (formId) {
      // FormService.exportFormCSV(values, formId);
    }
  };

  function renderStepContent(step:number) {
    switch (step) {
      case 0:
        return <Instructions programId={programId} />;
      case 1:
        return <Informations />;
      default: {
        let stepNumberRelativeToForm = step - 2;

        for (let i = 0; i < formSpec.indicateurs.length; i += 1) {
          const numStepsInIndicateur = formSpec.indicateurs[i].subsections.length;
          if (numStepsInIndicateur <= stepNumberRelativeToForm) {
            // step is not in this indicator
            stepNumberRelativeToForm -= numStepsInIndicateur;
          } else if (
            formSpec.indicateurs
            && formSpec.indicateurs[i]
            && formSpec.indicateurs[i].subsections
            && formSpec.indicateurs[i].subsections[stepNumberRelativeToForm]
          ) {
            // step is in this indicator
            return <GeneratedFormStep path={`indicateurs[${i}].subsections[${stepNumberRelativeToForm}]`} spec={formSpec} />;
          }
        }
        return <div>Rien trouvé</div>;
      }
    }
  }

  function getStepTitle(step:number) : string {
    switch (step) {
      case 0:
        return 'Instructions';
      case 1:
        return 'Informations de base';
      default: {
        let stepNumberRelativeToForm = step - 2;

        for (let i = 0; i < formSpec.indicateurs.length; i += 1) {
          const numStepsInIndicateur = formSpec.indicateurs[i].subsections.length;
          if (numStepsInIndicateur <= stepNumberRelativeToForm) {
            // step is not in this indicator
            stepNumberRelativeToForm -= numStepsInIndicateur;
          } else if (
            formSpec.indicateurs
            && formSpec.indicateurs[i]
            && formSpec.indicateurs[i].title
          ) {
            // step is in this indicator
            return formSpec.indicateurs[i].title;
          }
        }
        return 'Rien trouvé';
      }
    }
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleSubmitFormForAnalysis = (formikSubmitFunction:any, id:string) => {
    formikSubmitFunction();
    setSubmitForAnalysisPopupOpen(false);
    FormService.update({
      id,
      state: FormState.WAITING_RESULT,
    });
    setFormState(FormState.WAITING_RESULT);
  };

  return (
    <>
      <PEXBDFormWrapper>
        <PEXBDFormContainer>
          <FormSidebar activeStep={activeStep} setActiveStep={setActiveStep}/>
          { formIsInit && formSpec
          && <>
            <Formik
              initialValues={initialFormValues}
              onSubmit={(values) => saveData(values)}
            >
              {(formikProps) => (
                <Box sx={{ width: '100%' }}>
                  <Form style={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                  }}>
                    {formState === FormState.WAITING_RESULT
                  && <Alert severity="info">L'analyse de vos données est en cours, vous ne pouvez plus modifier le formulaire.</Alert>}
                    {formState === FormState.DONE
                  && <Alert severity="info">
                    L'analyse de ce formulaire est complétée. Veuillez retourner à votre tableau de bord pour consulter les résultats ou sélectionner un autre formulaire.
                  </Alert>}
                    <Box sx={{
                      px: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 68, borderBottom: '1px solid #eeeeee',
                    }}>
                      <Typography variant="h2" align='center'>{getStepTitle(activeStep)}</Typography>
                      <Box>
                        {formState === FormState.IN_PROGRESS && <Button
                          onClick={() => setSubmitForAnalysisPopupOpen(true)}
                          sx={{ mr: 1 }}
                          color="primary"
                          variant="outlined"
                          disabled={formikProps.isSubmitting || formikProps.values.isDisabled}>
                        Soumettre pour analyse
                        </Button>}
                        <Button color="primary" variant="contained" type="submit" disabled={formikProps.isSubmitting || formikProps.values.isDisabled}>
                        Sauvegarder
                        </Button>
                      </Box>
                    </Box>
                    {renderStepContent(activeStep)}
                    <Box sx={{
                      px: 2, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'background.default', height: 68,
                    }}>
                      <Box>
                        {activeStep !== 0 && (
                          <Button
                            onClick={handleBack}
                            disabled={formikProps.isSubmitting}
                          >
                          Précédent
                          </Button>
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        {formState !== FormState.IN_PROGRESS
                        && user?.permission === Permissions.ADMIN
                        && <Button color="secondary" variant="outlined" onClick={() => downloadFormCSV(formikProps.values)}>
                        Télécharger les données
                        </Button>}
                        {formState === FormState.WAITING_RESULT
                        && user?.permission === Permissions.ADMIN
                        && <Button color="secondary" variant="contained" onClick={() => setShowGenerateReportPrompt(true)}>
                        Générer le rapport
                        </Button>}
                      </Box>
                      <Box>
                        {activeStep < 11 && <Button
                          onClick={handleNext}
                          variant="outlined"
                          color="primary"
                          disabled={formikProps.isSubmitting}
                        >
                          {(activeStep === 0) ? <>Remplir le formulaire</> : <>Suivant</>}
                        </Button>}
                      </Box>
                    </Box>
                  </Form>
                  <PromptGenerateReport
                    setShow={setShowGenerateReportPrompt}
                    show={showGenerateReportPrompt}
                    formId={formId || ''}
                    values={formikProps.values}
                    spec={formSpec}
                    programId={programId}
                    municipalityId={municipalityId}
                  />
                  <Dialog
                    open={submitForAnalysisPopupOpen}
                    onClose={() => setSubmitForAnalysisPopupOpen(false)}
                  >
                    <DialogTitle>
                  Soumettre le formulaire pour analyse
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Vous êtes sur le point de soumettre ce formulaire pour analyse. Une fois soumis, le formulaire ne pourra plus être modifié.
                      </DialogContentText>
                      <FormControlLabel
                        label="Je confirme que les renseignements fournis sont valides"
                        sx={{ marginTop: '20px' }}
                        control={
                          <Checkbox
                            checked={submitFormCheckbox}
                            onChange={(e) => {
                              setSubmitFormCheckbox(e.target.checked);
                            }}/>
                        }
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setSubmitForAnalysisPopupOpen(false)}>Annuler</Button>
                      <Button
                        onClick={() => handleSubmitFormForAnalysis(formikProps.submitForm, formId || '')}
                        variant="contained"
                        sx={{ color: 'white' }}
                        disabled={!submitFormCheckbox}>Soumettre pour analyse</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              )}
            </Formik>
          </>
          }
        </PEXBDFormContainer>
      </PEXBDFormWrapper>
    </>
  );
};

export default FormPEXBD;
