import '../theme/css/global.css';
import {
  Box, styled,
} from '@mui/material';
import AppBackground from './AppBackground';
import SidebarSmall from './SidebarSmall';

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingLeft: 80,
}));

type Props = {
  children?: React.ReactNode
  title: string
};

const LayoutNoHeader: React.FC<Props> = ({ children, title }) => (
  <>
    <LayoutRoot>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </LayoutRoot>
    <SidebarSmall
    />
    <AppBackground bgPos="top" />

  </>
);

export default LayoutNoHeader;
