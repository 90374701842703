/* eslint-disable @typescript-eslint/no-empty-function */
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';

import { ThemeProvider, CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  onSnapshot, doc, collection, query, orderBy,
} from 'firebase/firestore';
import {
  User as FirebaseUser,
} from 'firebase/auth';
import { db, auth } from './FirebaseConfig';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import themeConfig from './theme/ThemeConfig';
import Signup from './pages/auth/Signup';
import NotFound from './pages/NotFound';
import { useAppDispatch, useAppSelector } from './store/Hooks';
import {
  updateLanguage, updateUser, updateMunicipality, updateForms, updateMunicipalities, updateReports,
} from './store/slices/UserSlice';
import AlertsContainer from './components/AlertsContainer';
import User from './models/User';
import Spinner from './components/Spinner';
import PrivateRoutes from './routing/PrivateRoutes';
import ForgotPassword from './pages/auth/ForgotPassword';
import SupportedLanguages from './models/enums/SupportedLanguages';
import AdminRoutes from './routing/AdminRoutes';
import Logs from './pages/admin/Logs';
import Settings from './pages/settings/Settings';
import Notifications from './pages/Notifications';
import PasswordLogin from './pages/auth/PasswordLogin';
import ForgotCode from './pages/auth/ForgotCode';
import FormPage from './pages/FormPage';
import Municipality from './models/Municipality';
import Municipalities from './pages/Municipalities';
import Reports from './pages/Reports';
import Permissions from './models/enums/Permissions';
// import Municipality from './models/Municipality';

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let unsubscribe = () => {};

      auth.onAuthStateChanged((response) => {
        const firebaseUser = response as FirebaseUser;
        if (firebaseUser) {
          unsubscribe = onSnapshot(doc(db, 'Users', firebaseUser.uid), (result) => {
            const userDoc = result.data() as User;
            dispatch(updateUser(userDoc));
            dispatch(updateLanguage(userDoc.language));
            subscribeToMunicipality(userDoc);
          });
        } else {
          dispatch(updateUser(null));
          dispatch(updateLanguage(SupportedLanguages.DEFAULT));
        }
        setLoading(false);
      });
      return unsubscribe;
    } catch (e: any) {
      console.error(e);
      return e;
    }
  }, []);

  // bind to forms collections
  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.id) {
      const q = query(collection(db, 'Forms'), orderBy('studiedYear', 'desc'));
      unsubscribe = onSnapshot(q, (formsSnapshot) => {
        const forms : any[] = [];
        formsSnapshot.forEach((formDoc) => {
          const data = formDoc.data();
          if (user.municipalityId === data.municipalityId || user.permission === Permissions.ADMIN) {
            forms.push({
              id: formDoc.id,
              studiedYear: data.studiedYear,
              municipalityName: data.formData?.nomMunicipalite,
              programName: data.programName,
              state: data.state,
            });
          }
        });
        dispatch(updateForms(forms));
      });
    }
    return unsubscribe;
  }, [user]);

  // bind to municipality collection
  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.id) {
      const q = query(collection(db, 'Municipalities'), orderBy('name'));
      unsubscribe = onSnapshot(q, (municipalitiesSnapshot) => {
        const municipalities : any[] = [];
        municipalitiesSnapshot.forEach((municipalityDoc) => {
          const data = municipalityDoc.data();
          municipalities.push({
            id: municipalityDoc.id,
            name: data.name,
          });
        });
        dispatch(updateMunicipalities(municipalities));
      });
    }
    return unsubscribe;
  }, [user]);

  // bind to reports collection
  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.id) {
      const q = query(collection(db, 'Reports'), orderBy('studiedYear', 'desc'));
      unsubscribe = onSnapshot(q, (reportsSnapshot) => {
        const reports : any[] = [];
        reportsSnapshot.forEach((reportDoc) => {
          const data = reportDoc.data();
          if (user.municipalityId === data.municipalityId || user.permission === Permissions.ADMIN) {
            reports.push({
              id: reportDoc.id,
              programName: data.programName,
              municipalityName: data.municipalityName,
              studiedYear: data.studiedYear,
            });
          }
        });
        dispatch(updateReports(reports));
      });
    }
    return unsubscribe;
  }, [user]);

  const subscribeToMunicipality = (u: User) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => {};
    if (u) {
      const mId = u.municipalityId;
      if (mId) {
        unsubscribe = onSnapshot(doc(db, 'Municipalities', mId), (result) => {
          const municipalityDoc = result.data() as Municipality;
          dispatch(updateMunicipality(municipalityDoc));
        });
      }
    }
    setLoading(false);
    return unsubscribe;
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline/>
      <div className="app">
        {
          !loading
          && <BrowserRouter>
            <Routes>
              <Route element={<PrivateRoutes />} >
                <Route path="/" element={<Dashboard />}/>
                <Route path="/settings" element={<Settings />}/>
                <Route path="/notifications" element={<Notifications />}/>
                <Route path="/form/:formId" element={<FormPage />}/>
                <Route path="/reports" element={<Reports />}/>
                <Route path="/reports/:formId" element={<Reports />}/>
                <Route element={<AdminRoutes />}>
                  <Route path="/logs" element={<Logs />}/>
                  <Route path="/municipalities" element={<Municipalities />}/>
                  <Route path="/municipalities/:municipalityId" element={<Municipalities />}/>
                </Route>
                <Route path='/signup' element={<Signup />}/>
                <Route path='/forgot-password' element={<ForgotPassword />}/>
                <Route path='/forgot-code' element={<ForgotCode />}/>
              </Route>
              <Route path='/signup' element={<Signup />}/>
              <Route path='/forgot-password' element={<ForgotPassword />}/>
              <Route path='/forgot-code' element={<ForgotCode />}/>
              <Route path='/login' element={<Login />}/>
              <Route path='/municipality/login' element={<PasswordLogin />}/>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        }
        <AlertsContainer/>
        <Spinner show={loading}></Spinner>
      </div>
    </ThemeProvider>
  );
}

export default App;
