import {
  Box, Button, Collapse, List, ListItemButton, ListItemText, styled,
} from '@mui/material';
import { useFormikContext } from 'formik';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from 'react';
import LogoPEXBD from '../../../../theme/assets/PEXBD.png';
import HelpDialog from './dialogs/HelpDialog';

type Props = {
    activeStep:number,
    setActiveStep:(num: number) => void,
}

const PEXBDFormSidebar = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  position: 'relative',
  width: '250px',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
}));

const PEXBDFormNavItem = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const PEXBDFormNavItemParent = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const PEXBDFormNavItemChild = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  paddingLeft: '15px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
}));

const FormSidebar: React.FC<Props> = ({ activeStep, setActiveStep }) => {
  const { values }: { values: any} = useFormikContext();
  const [showHelp, setShowHelp] = useState(false);

  return (<>
    <PEXBDFormSidebar>
      <Box
        sx={{
          maxWidth: '200px',
          margin: '40px 20px 20px',
        }}>
        <img src={LogoPEXBD} alt="logo" />
      </Box>
      <List
        sx={{ width: '90%', marginLeft: '10%' }}
        component="nav"
      >
        <PEXBDFormNavItem
          selected={activeStep === 0}
          onClick={() => setActiveStep(0)}
        >
          <ListItemText
            primary="Instructions"
          />
        </PEXBDFormNavItem>
        <PEXBDFormNavItem
          disabled={!values.acceptedConditions}
          selected={activeStep === 1}
          onClick={() => setActiveStep(1)}
        >
          <ListItemText
            primary="Informations de base"
          />
        </PEXBDFormNavItem>
        <PEXBDFormNavItemParent
          disabled={!values.acceptedConditions || !values.population || !values.superficie}
          selected={activeStep >= 2 && activeStep < 3}
        >
          <PEXBDFormNavItemChild
            selected={activeStep >= 2 && activeStep < 3}
            onClick={() => setActiveStep(2)}
          >
            <ListItemText
              primary="Gouvernance"
            />
          </PEXBDFormNavItemChild>
          <Collapse in={activeStep >= 2 && activeStep <= 2} timeout="auto" unmountOnExit>
            <List
              sx={{ marginLeft: '10px', padding: 0 }}
              component="nav"
              dense
            >
              <PEXBDFormNavItemChild
                selected={activeStep === 2}
                onClick={() => setActiveStep(2)}
              >
                <ListItemText
                  primary="Intégration de la préservation de la biodiversité dans les activités municipales"
                />
              </PEXBDFormNavItemChild>
            </List>
          </Collapse>
        </PEXBDFormNavItemParent>
        <PEXBDFormNavItemParent
          disabled={!values.acceptedConditions || !values.population || !values.superficie}
          selected={activeStep >= 3 && activeStep <= 5}
        >
          <PEXBDFormNavItemChild
            selected={activeStep >= 3 && activeStep <= 5}
            onClick={() => setActiveStep(3)}
          >
            <ListItemText
              primary="Préservation"
            />
          </PEXBDFormNavItemChild>
          <Collapse in={activeStep >= 3 && activeStep <= 5} timeout="auto" unmountOnExit>
            <List
              sx={{ marginLeft: '10px', padding: 0 }}
              component="nav"
              dense
            >
              <PEXBDFormNavItemChild
                selected={activeStep === 3}
                onClick={() => setActiveStep(3)}
              >
                <ListItemText
                  primary="Territoires bénéficiant d'un statut de protection et corridors écologiques"
                />
              </PEXBDFormNavItemChild>
              <PEXBDFormNavItemChild
                selected={activeStep === 4}
                onClick={() => setActiveStep(4)}
              >
                <ListItemText
                  primary="Grands écosystèmes et espèces à statut"
                />
              </PEXBDFormNavItemChild>
              <PEXBDFormNavItemChild
                selected={activeStep === 5}
                onClick={() => setActiveStep(5)}
              >
                <ListItemText
                  primary="Espèces exotiques envahissantes"
                />
              </PEXBDFormNavItemChild>
            </List>
          </Collapse>
        </PEXBDFormNavItemParent>
        <PEXBDFormNavItemParent
          disabled={!values.acceptedConditions || !values.population || !values.superficie}
          selected={activeStep >= 6 && activeStep <= 8}
        >
          <PEXBDFormNavItemChild
            selected={activeStep >= 6 && activeStep <= 8}
            onClick={() => setActiveStep(6)}
          >
            <ListItemText
              primary="Multifonctionnalité"
            />
          </PEXBDFormNavItemChild>
          <Collapse in={activeStep >= 6 && activeStep <= 8} timeout="auto" unmountOnExit>
            <List
              sx={{ marginLeft: '10px', padding: 0 }}
              component="nav"
              dense
            >
              <PEXBDFormNavItemChild
                selected={activeStep === 6}
                onClick={() => setActiveStep(6)}
              >
                <ListItemText
                  primary="Santé et loisirs"
                />
              </PEXBDFormNavItemChild>
              <PEXBDFormNavItemChild
                selected={activeStep === 7}
                onClick={() => setActiveStep(7)}
              >
                <ListItemText
                  primary="Services écosystémiques"
                />
              </PEXBDFormNavItemChild>
              <PEXBDFormNavItemChild
                selected={activeStep === 8}
                onClick={() => setActiveStep(8)}
              >
                <ListItemText
                  primary="Engagement collectif"
                />
              </PEXBDFormNavItemChild>
            </List>
          </Collapse>
        </PEXBDFormNavItemParent>
      </List>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 20px',

        }}>
        <Button
          onClick={() => setShowHelp(true)}
          size='small'
          variant='contained'
          startIcon={<HelpIcon fontSize='large'/>}
        >
          Guide méthodologique
        </Button>
      </Box>
      <HelpDialog setShow={setShowHelp} show={showHelp}/>
    </PEXBDFormSidebar>
  </>);
};

export default FormSidebar;
