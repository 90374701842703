// TODO Make sure we don't have duplicate IDs
/* eslint-disable camelcase */
import {
  indicateur1_1,
  indicateur1_2,
  indicateur1_3,
  indicateur1_4,
  indicateur1_5,
  indicateur1_6,
  indicateur2_1,
  indicateur2_2,
} from './sousindicateurs/GouvernanceSousIndicateurs';

import {
  indicateur3_1,
  indicateur3_2,
  indicateur3_3,
  indicateur3_4,
  indicateur4_1,
  indicateur4_2,
  indicateur4_3,
  indicateur4_4,
  indicateur4_5,
  indicateur4_6,
  indicateur4_7_Group2,
  indicateur5_1,
  indicateur5_2,
  indicateur5_3,
  indicateur5_4,
  indicateur5_5,
  indicateur6_1,
  indicateur6_2,
  indicateur6_3,
  indicateur6_4_Group2,
  indicateur6_5_Group2,
  indicateur6_6_Group2,
  indicateur7_1,
  indicateur7_2_Group2,
} from './sousindicateurs/PreservationSousIndicateurs';

import {
  indicateur8_1,
  indicateur8_2_Group2,
  indicateur8_3_Group2,
  indicateur8_4_Group2,
  indicateur9_1_Group2,
  indicateur9_2_Group2,
  indicateur10_1_Group2,
  indicateur10_2,
  indicateur10_3,
  indicateur10_4_Group2,
  indicateur10_5_Group2,
  indicateur10_6_Group2,
} from './sousindicateurs/MultifonctionnaliteSousIndicateurs';

/* eslint-disable max-len */
const FormSpec = {
  version: 2,
  indicateurs: [
    {
      id: '',
      title: 'Gouvernance',
      steps: [
        {
          id: '',
          title: 'Indicateur 1: Intégration de la préservation de la biodiversité dans les activités municipales',
          sections: [
            indicateur1_1,
            indicateur1_2,
            indicateur1_3,
            indicateur1_4,
            indicateur1_5,
            indicateur1_6,
          ],
        },
        {
          id: '',
          title: 'Indicateur 2: Ressources attribuées à la biodiversité',
          sections: [
            indicateur2_1,
            indicateur2_2,
          ],
        },
      ],
    },
    {
      id: '',
      title: 'Préservation',
      steps: [
        {
          id: '',
          title: 'Indicateur 3: Territoire dont l\'affectation et l\'usage bénéficient d\'une vocation de conservation.',
          sections: [
            indicateur3_1,
            indicateur3_2,
            indicateur3_3,
            indicateur3_4,
          ],
        },
        {
          id: '',
          title: 'Indicateur 4: Fragmentation et corridors écologiques',
          sections: [
            indicateur4_1,
            indicateur4_2,
            indicateur4_3,
            indicateur4_4,
            indicateur4_5,
            indicateur4_6,
            indicateur4_7_Group2,
          ],
        },
        {
          id: '',
          title: 'Indicateur 5: Grands écosystèmes et occupation du sol',
          sections: [
            indicateur5_1,
            indicateur5_2,
            indicateur5_3,
            indicateur5_4,
            indicateur5_5,
          ],
        },
        {
          id: '',
          title: 'Indicateur 6: Espèces à statut',
          sections: [
            indicateur6_1,
            indicateur6_2,
            indicateur6_3,
            indicateur6_4_Group2,
            indicateur6_5_Group2,
            indicateur6_6_Group2,
          ],
        },
        {
          id: '',
          title: 'Indicateur 7: Espèces exotiques envahissantes',
          sections: [
            indicateur7_1,
            indicateur7_2_Group2,
          ],
        },
      ],
    },
    {
      id: '',
      title: 'Multifonctionnalité',
      steps: [
        {
          id: '',
          title: 'Indicateur 8: Santé et loisirs',
          sections: [
            indicateur8_1,
            indicateur8_2_Group2,
            indicateur8_3_Group2,
            indicateur8_4_Group2,
          ],
        },
        {
          id: '',
          title: 'Indicateur 9: Services écosystémiques',
          sections: [
            indicateur9_1_Group2,
            indicateur9_2_Group2,
          ],
        },
        {
          id: '',
          title: 'Indicateur 10: Engagement collectif lié à la biodiversité',
          sections: [
            indicateur10_1_Group2,
            indicateur10_2,
            indicateur10_3,
            indicateur10_4_Group2,
            indicateur10_5_Group2,
            indicateur10_6_Group2,
          ],
        },
      ],
    },
  ],
};

export default FormSpec;
