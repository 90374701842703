import _ from 'lodash';
import TooltipDetail from '../../components/TooltipDetail';
import SlotTypes from '../SlotTypes';

/* eslint-disable camelcase */
/* eslint-disable max-len */
const indicateur8_1 = {
  id: '',
  title: 'Aires de conservation aménagées pour le public',
  number: '8.1',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Proportion des aire de conservation/milieux naturels accessibles à la population qui sont aménagées, dans les limites des capacités de support de l\'écosystème',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      labels: ['0% - 50%', '51% - 75 %', '76% - 100 %'],
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '76-100% : 1 pt',
          '51-75% : 0.5 pt',
          '0-50% : 0 pt',
        ]}
      />,
      maxScore: 1,
    },
  ],
};

const indicateur8_2 = {
  id: '',
  title: 'Proximité des résidences à la nature',
  number: '8.2',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Proportion de résidents vivant à moins de 300m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50% par de la végétation haute',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '41 % et plus : 3 pts',
          '26-40% : 2 pts',
          '11-25% : 1 pt',
          '0-10% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur8_2_Group2 = {
  id: '',
  title: 'Proximité des résidences à la nature',
  number: '8.2',
  slots: [
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Proportion de résidents vivant à moins de 300m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50% par de la végétation haute',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '41 % et plus : 3 pts',
          '26-40% : 2 pts',
          '11-25% : 1 pt',
          '0-10% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur8_3 = {
  id: '',
  title: 'Proximité des établissements scolaires, des hôpitaux et des maisons pour personnes âgées à la nature',
  number: '8.3',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Proportion d\'établissements scolaires, d\'hôpitaux et de maisons pour personnes âgées situés à moins de 300 m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50 % par de la végétation haute',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '41 % et plus : 3 pts',
          '26-40% : 2 pts',
          '11-25% : 1 pt',
          '0-10% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur8_3_Group2 = {
  id: '',
  title: 'Proximité des établissements scolaires, des hôpitaux et des maisons pour personnes âgées à la nature',
  number: '8.3',
  slots: [
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Proportion d\'établissements scolaires, d\'hôpitaux et de maisons pour personnes âgées situés à moins de 300 m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50 % par de la végétation haute',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '41 % et plus : 3 pts',
          '26-40% : 2 pts',
          '11-25% : 1 pt',
          '0-10% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur8_4 = {
  id: '',
  title: 'Intégration du concept de « quartier vert / écoquartier » dans les documents de planification',
  number: '8.4',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Intégration du concept de « quartier vert/ecoquartier » pour les nouveaux aménagements dans les documents de planification',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur8_4_Group2 = {
  id: '',
  title: 'Intégration du concept de « quartier vert / écoquartier » dans les documents de planification',
  number: '8.4',
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'Intégration du concept de « quartier vert/ecoquartier » pour les nouveaux aménagements dans les documents de planification',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur9_1 = {
  id: '',
  title: 'Services écosystémiques touchant à l\'eau',
  number: '9.1',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      beforeText: 'Actions en faveur de la gestion de l\'eau:',
      items: [
        'Règlement interdisant l\'envoi des eaux pluviales dans les réseaux d\'égouts dans les nouvelles constructions',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Coefficient de perméabilité, en ne comptabilisant que les ouvrages végétalisés',
      data: '',
      score: '',
      beforeText: 'Surfaces perméables à l\'intérieur du périmètre d\'urbanisation:',
      scoringFct: (data:number) => {
        if (data >= 76) return 3;
        if (data >= 61) return 2;
        if (data >= 41) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '76 % et plus : 3 pts',
          '61-75% : 2 pts',
          '41-60% : 1 pt',
          '0-40% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur9_1_Group2 = {
  id: '',
  title: 'Services écosystémiques touchant à l\'eau',
  number: '9.1',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      beforeText: 'Actions en faveur de la gestion de l\'eau:',
      items: [
        'Règlement interdisant l\'envoi des eaux pluviales dans les réseaux d\'égouts dans les nouvelles constructions',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Coefficient de perméabilité, en ne comptabilisant que les ouvrages végétalisés',
      data: '',
      score: '',
      beforeText: 'Surfaces perméables à l\'intérieur du périmètre d\'urbanisation:',
      scoringFct: (data:number) => {
        if (data >= 76) return 3;
        if (data >= 61) return 2;
        if (data >= 41) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '76 % et plus : 3 pts',
          '61-75% : 2 pts',
          '41-60% : 1 pt',
          '0-40% : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur9_2 = {
  id: '',
  title: 'Services écosystémiques touchant à l\'air',
  number: '9.2',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Politique de l\'arbre',
      ],
      beforeText: 'Actions en faveur des arbres:',
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.INFO,
      text: 'Couverture de la végétation, limité au territoire urbanisé:',
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Superficie de la végétation basse',
      beforeText: 'Parc:',
      data: '',
      score: '',
      scoringFct: (data:number) => '',
      toolTipContent: <TooltipDetail
        title="Pas de pointage cette année"
        subtitle='Basé sur la progression de la superficie'
        elements={[
          'Augmentation : 1 pt',
          'Maintien : 0.5 pt',
          'Diminution : 0 pt',
        ]}
      />,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Part de la superficie de la végétation basse  par rapport à la superfie totale du territoire',
      data: '',
      score: '',
      hideToolTip: true,
      beforeText: ' ',
      scoringFct: (data:number) => '',
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[2].data`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / superficie;
      },
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Superficie de la végétation haute',
      beforeText: 'Arbre urbain:',
      data: '',
      score: '',
      scoringFct: (data:number) => '',
      toolTipContent: <TooltipDetail
        title="Pas de pointage cette année"
        subtitle='Basé sur la progression de la superficie'
        elements={[
          'Augmentation : 1 pt',
          'Maintien : 0.5 pt',
          'Diminution : 0 pt',
        ]}
      />,
    },
    {
      type: SlotTypes.ETOILES,
      item:
        'Part de la superficie de la végétation haute  par rapport à la superfie totale du territoire (indice canopée)',
      data: '',
      score: '',
      hideToolTip: true,
      beforeText: ' ',
      scoringFct: (data:number) => '',
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[4].data`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / superficie;
      },
    },
  ],
};

const indicateur9_2_Group2 = {
  id: '',
  title: 'Services écosystémiques touchant à l\'air',
  number: '9.2',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Politique de l\'arbre',
      ],
      beforeText: 'Actions en faveur des arbres:',
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.INFO,
      text: 'Couverture de la végétation, limité au territoire urbanisé:',
    },
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Superficie de la végétation basse',
      beforeText: 'Parc:',
      data: '',
      score: '',
      scoringFct: (data:number) => '',
      toolTipContent: <TooltipDetail
        title="Pas de pointage cette année"
        subtitle='Basé sur la progression de la superficie'
        elements={[
          'Augmentation : 1 pt',
          'Maintien : 0.5 pt',
          'Diminution : 0 pt',
        ]}
      />,
    },
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Part de la superficie de la végétation basse  par rapport à la superfie totale du territoire',
      data: '',
      score: '',
      hideToolTip: true,
      beforeText: ' ',
      scoringFct: (data:number) => '',
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[2].data`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / superficie;
      },
    },
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Superficie de la végétation haute',
      beforeText: 'Arbre urbain:',
      data: '',
      score: '',
      scoringFct: (data:number) => '',
      toolTipContent: <TooltipDetail
        title="Pas de pointage cette année"
        subtitle='Basé sur la progression de la superficie'
        elements={[
          'Augmentation : 1 pt',
          'Maintien : 0.5 pt',
          'Diminution : 0 pt',
        ]}
      />,
    },
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item:
        'Part de la superficie de la végétation haute  par rapport à la superfie totale du territoire (indice canopée)',
      data: '',
      score: '',
      hideToolTip: true,
      beforeText: ' ',
      scoringFct: (data:number) => '',
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[4].data`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / superficie;
      },
    },
  ],
};

const indicateur10_1 = {
  id: '',
  title: 'Programme de jardins partagés',
  number: '10.1',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'L\'organisation participe à des programme de jardins communautaires et collectifs et/ou d\'aménagements comestibles (nombres de programmes)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '1 pt par programme',
          'Maximum de 3 pts',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur10_1_Group2 = {
  id: '',
  title: 'Programme de jardins partagés',
  number: '10.1',
  slots: [
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'L\'organisation participe à des programme de jardins communautaires et collectifs et/ou d\'aménagements comestibles (nombres de programmes)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '1 pt par programme',
          'Maximum de 3 pts',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur10_2 = {
  id: '',
  title: 'Absence d\'un règlement défavorable à l\'aménagement de potager sur les terrains privés',
  number: '10.2',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Absence de règlement défavorable à l\'aménagement de potager sur toute ou partie des terrains privés',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur10_3 = {
  id: '',
  title: 'Pesticides',
  number: '10.3',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Non utilisation des pesticides dans les activités de l\'organisation municipale',
        'Campagne de sensibilisation à destination des citoyens',
      ],
      beforeText: 'Pesticides:',
      data: ['0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 2,
    },
  ],
};

const indicateur10_4 = {
  id: '',
  title: 'Programme de conservation et restauration de friche',
  number: '10.4',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre total de projets réalisés de conservation et restauration de friche en milieu urbain',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '1 pt par programme',
          'Maximum de 3 pts',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur10_4_Group2 = {
  id: '',
  title: 'Programme de conservation et restauration de friche',
  number: '10.4',
  slots: [
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Nombre total de projets réalisés de conservation et restauration de friche en milieu urbain',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '1 pt par programme',
          'Maximum de 3 pts',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur10_5 = {
  id: '',
  title: 'Programme de ruelles vertes ou bleues-vertes',
  number: '10.5',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisation participe à des programme de ruelles vertes ou bleues-vertes',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur10_5_Group2 = {
  id: '',
  title: 'Programme de ruelles vertes ou bleues-vertes',
  number: '10.5',
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisation participe à des programme de ruelles vertes ou bleues-vertes',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur10_6 = {
  id: '',
  title: 'Participation à des programmes nationaux ou internationaux de sensibilisation ou de reconnaissance',
  number: '10.6',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisation participe à des programmes nationaux ou internationaux de sensibilisation à la biodiversité ou de reconnaissance des actions posées',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur10_6_Group2 = {
  id: '',
  title: 'Participation à des programmes nationaux ou internationaux de sensibilisation ou de reconnaissance',
  number: '10.6',
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisation participe à des programmes nationaux ou internationaux de sensibilisation à la biodiversité ou de reconnaissance des actions posées',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

export {
  indicateur8_1,
  indicateur8_2,
  indicateur8_2_Group2,
  indicateur8_3,
  indicateur8_3_Group2,
  indicateur8_4,
  indicateur8_4_Group2,
  indicateur9_1,
  indicateur9_1_Group2,
  indicateur9_2,
  indicateur9_2_Group2,
  indicateur10_1,
  indicateur10_1_Group2,
  indicateur10_2,
  indicateur10_3,
  indicateur10_4,
  indicateur10_4_Group2,
  indicateur10_5,
  indicateur10_5_Group2,
  indicateur10_6,
  indicateur10_6_Group2,
};
