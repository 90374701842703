import SlotTypes from '../SlotTypes';

/* eslint-disable camelcase */
/* eslint-disable max-len */
const indicateur1_1 = {
  id: '',
  number: '1.1',
  title: 'Engagement moral',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Un document officiel prônant l\'engagement moral de l\'organisation municipale vis à vis de la préservation de la biodiversité (politique, charte, etc.) est adopté par le conseil municipal.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur1_2 = {
  id: '',
  title: 'Plan stratégique local',
  number: '1.2',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Un plan stratégique local pour la préservation de la diversité biologique a été publié et fixe des objectifs tangibles.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur1_3 = {
  id: '',
  title: 'Prise en compte de la biodiversité dans les différents outils d\'urbanisme et aménagement du territoire',
  number: '1.3',
  description: ['La notion de prise en compte de la biodiversité est incluse dans différents outils d\'urbanisme et aménagement du territoire :'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU)',
        'Schéma d\'aménagement et de développement (SAD) ou Plan métropolitain d\'aménagement et de développement (PMAD)',
        'Plan de développement de la zone agricole (PDZA)',
      ],
      data: ['0', '0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 3,
    },
  ],
};

const indicateur1_4 = {
  id: '',
  title: 'Espèce emblématique',
  number: '1.4',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Une espèce faunique ou floristique emblématique représentante de la biodiversité du territoire est adoptée par le conseil municipal.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur1_5 = {
  id: '48c01830-0e97-413d-b40a-f2d5ebdad867',
  title: 'Programmes de conservation ou de sensibilisation mis en place par l\'organisme municipal ou auxquels il participe',
  number: '1.5',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisme a mis en oeuvre ou participe à un ou plusieurs programmes de conservation ou sensibilisation.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 1,
    },
    {
      type: SlotTypes.REPEATER,
      data: [],
      repeaterFields: [
        {
          label: 'Nom du programme',
          slug: 'name',
          width: '40%',
        },
        {
          label: 'Montant alloué',
          slug: 'ammount',
          width: '40%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          width: '20%',
          type: 'number',
        },
      ],
      score: '',
      maxScore: 4,
    },
  ],
};

const indicateur1_6 = {
  id: '376d07e5-a2ac-4dc0-98b0-8e6f25c91e09',
  title: 'Programmes incitatifs (monétaires ou services) mis en oeuvre par l\'organisme municipal',
  number: '1.6',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Un ou des programmes incitatifs (monétaires ou services) liés à la conservation de la biodiversité et destinés aux citoyens ou aux organisations ont été mis en oeuvre',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 1,
    },
    {
      type: SlotTypes.REPEATER,
      data: [],
      repeaterFields: [
        {
          label: 'Nom du programme',
          slug: 'name',
          width: '30%',
        },
        {
          label: 'Personnes visées',
          slug: 'people',
          width: '30%',
        },
        {
          label: 'Montant accordé',
          slug: 'ammount',
          width: '20%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          width: '20%',
          type: 'number',
        },
      ],
      score: '',
      maxScore: 4,
    },
  ],
};

const indicateur2_1 = {
  id: '2e33c369-5de1-4b5e-a616-a65bf476cff7',
  title: 'Montant alloué à la biodiversité',
  number: '2.1',
  description: [
    'Montant alloué par l\'organisation municipale à la biodiversité (excluant les ressources investies dans un Fonds). Remplir le tableau si réalisé.',
  ],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'La liste détaillée des montants alloués annuellement aux ressources humaines et aux activités liées à la préservations de la biodiversité est complétée.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.REPEATER,
      data: [
        {
          secteur: 'Ressources humaines',
          comment: '',
          ammount: 0,
        },
        {
          secteur: 'Acquisition de connaissances',
          comment: '',
          ammount: 0,
        },
        {
          secteur: 'Action terrain',
          comment: '',
          ammount: 0,
        },
        {
          secteur: 'Lutte aux espèces envahissantes',
          comment: '',
          ammount: 0,
        },
        {
          secteur: 'Sensibilisation',
          comment: '',
          ammount: 0,
        },
        {
          secteur: 'Subventions accordées',
          comment: '',
          ammount: 0,
        },
      ],
      repeaterFields: [
        {
          label: 'Secteur',
          slug: 'secteur',
          width: '30%',
        },
        {
          label: 'Montant alloué',
          slug: 'ammount',
          width: '25%',
          type: 'number',
        },
        {
          label: 'Commentaire',
          slug: 'comment',
          width: '45%',
        },
      ],
      score: '',
      maxScore: 4,
    },
  ],
};

const indicateur2_2 = {
  id: '7a5ebb3b-a503-4cbf-93da-af8f56b816fd',
  title: 'Contribution à un fonds',
  number: '2.2',
  description: [],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'L\'organisme municipale a mis en place ou participe à un ou plusieurs fonds pour la biodiversité',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.REPEATER,
      data: [],
      repeaterFields: [
        {
          label: 'Nom du Fonds',
          slug: 'fonds',
          width: '45%',
        },
        {
          label: 'Montant alloué',
          slug: 'ammount',
          width: '35%',
          type: 'number',
        },
        {
          label: 'Année',
          slug: 'year',
          type: 'number',
          width: '20%',
        },
      ],
      score: '',
      maxScore: 4,
    },
  ],
};

export {
  indicateur1_1,
  indicateur1_2,
  indicateur1_3,
  indicateur1_4,
  indicateur1_5,
  indicateur1_6,
  indicateur2_1,
  indicateur2_2,
};
