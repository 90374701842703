enum SlotUnit {
    OPTIONS = 'options',
    DOLLAR_PER_RESIDENT = '$ CAD/habitant',
    DOLLAR = 'CAD',
    HECTARE = 'hectare',
    PERCENTAGE = '%',
    NUMBER_OF = 'nombre de'
  }

export default SlotUnit;
