import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import MunicipalitiesTable from '../components/MunicipalitiesTable';
import MunicipalityPage from './MunicipalityPage';

const Municipalities: React.FC = () => {
  const { municipalityId } = useParams();

  return (
    <Layout title="Municipalités" showBack={!!municipalityId}>
      <Spinner show={false}/>
      <Box sx={{
        p: 2,
        height: 'calc(100vh - 64px)',
        overflowX: 'hidden',
      }}>
        {
          municipalityId
            ? <MunicipalityPage municipalityId={municipalityId}/>
            : <MunicipalitiesTable />
        }
      </Box>
    </Layout>
  );
};

export default Municipalities;
