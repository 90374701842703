import {
  Chip, ThemeProvider, Tooltip, TooltipProps, createTheme, styled, tooltipClasses,
} from '@mui/material';
import { ReactNode } from 'react';
import ChipTooltip from './ChipTooltip';
import QuestionType from '../../models/enums/QuestionType';

type Props = {
    type: QuestionType,
    showText?: boolean,
    showTooltip?: boolean,
    showIcon?: boolean,
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const QuestionTypeChipTheme = createTheme({
  palette: {
    primary: {
      main: '#edda74',
    },
    secondary: {
      main: '#917e91',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: '9px',
        },
      },
    },
  },
});

const fetchColor = (type : QuestionType) : any => {
  switch (type) {
    case QuestionType.ACTIVITY:
      return 'primary';
    case QuestionType.INDICATOR:
      return 'secondary';
    default:
      return 'secondary';
  }
};

const fetchText = (type : QuestionType) : string => {
  switch (type) {
    case QuestionType.ACTIVITY:
      return 'Activité';
    case QuestionType.INDICATOR:
      return 'Indicateur';
    default:
      return 'Indicateur';
  }
};

const displayTooltipDetail = (type : QuestionType) : ReactNode => {
  switch (type) {
    case QuestionType.ACTIVITY:
      return <ChipTooltip
        title="Évalue les actions prises par les municipalités."
        elements={[]}
      />;
    case QuestionType.INDICATOR:
      return <ChipTooltip
        title="Évalue l'impact des activités entreprisent par les municipalités."
        elements={[]}
      />;
    default:
      return <ChipTooltip
        title="Autre"
        elements={[]}
      />;
  }
};

const QuestionTypeChip: React.FC<Props> = ({
  showText,
  type,
  showTooltip,
  showIcon,
}) => (
  <ThemeProvider theme={QuestionTypeChipTheme}>
    <NoMaxWidthTooltip title={showTooltip && displayTooltipDetail(type)}>
      <Chip sx={{
        fontWeight: 600,
      }} size="small" color={fetchColor(type)} label={showText && `${fetchText(type)}` } />
    </NoMaxWidthTooltip>
  </ThemeProvider>
);

QuestionTypeChip.defaultProps = {
  showText: false,
  showTooltip: true,
};

export default QuestionTypeChip;
