import { ArrowRightAlt, Delete, Rule } from '@mui/icons-material';
import {
  Box, Button, ListItemIcon, ListItemText, MenuItem, MenuList, Popover,
} from '@mui/material';
import {
  DataGrid, GridColDef, GridColumnHeaderParams, frFR, GridSelectionModel,
} from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../store/Hooks';
import Utils from '../utils/Utils';
import PromptDeleteForm from './PromptDeleteForm';
import PromptModifyStatus from './PromptModifyStatus';

interface PropsContainer {
    onClick?: any
  }

const FormsTable: React.FC<PropsContainer> = ({ onClick }) => {
  const [rows, setRows] = useState([]) as any[];
  const { forms } = useAppSelector((state) => state.user);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectedFormObjs, setSelectedFormObjs] = useState<any>([]);
  const actionBtnRef = useRef(null);
  const [openActionPopover, setOpenActionPopover] = useState(false);
  const [showModifyStatusPrompt, setShowModifyStatusPrompt] = useState(false);
  const [showDeleteFormPrompt, setShowDeleteFormPrompt] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'studiedYear',
      headerName: 'Année étudiée',
      width: 150,
    },
    {
      field: 'municipalityName',
      headerName: 'Municipalité',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'programName',
      headerName: 'Programme',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'Statut',
      minWidth: 100,
      valueGetter: (params) => Utils.getFormStatusDisplayString(params.row.state),
      flex: 1,
    },
    {
      field: 'link',
      width: 200,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '200px' }}>
          <Button
            variant="contained"
            size="small"
            disabled={!selectionModel.length}
            onClick={() => setOpenActionPopover(true)}
            ref={actionBtnRef}
          >Action</Button>
          <Popover
            anchorEl={actionBtnRef.current}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            onClose={() => setOpenActionPopover(false)}
            open={openActionPopover}
            PaperProps={{
              sx: { width: '300px' },
            }}
          >
            <MenuList>
              <MenuItem onClick={() => setShowModifyStatusPrompt(true)}>
                <ListItemIcon>
                  <Rule fontSize="small" />
                </ListItemIcon>
                <ListItemText>Modifier le statut</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setShowDeleteFormPrompt(true)}>
                <ListItemIcon>
                  <Delete fontSize="small" color='error' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'error.main' }}>Supprimer le(s) formulaire(s)</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{
          width: '100%', display: 'flex', justifyContent: 'flex-end', pr: '15px',
        }}>
          <Button
            variant="text"
            endIcon={<ArrowRightAlt />}
            sx={{
              textTransform: 'none',
            }}
            component={Link}
            to={`/form/${params.row.id}`}
          >
            {params.value}
          </Button>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (forms) {
      const formsRowsTemp: any[] = [];
      forms.forEach((form:any) => {
        formsRowsTemp.push(
          {
            ...form,
            link: 'consulter',
          },
        );
      });
      setRows(formsRowsTemp);
    }
  }, [forms]);

  return (
    <>
      <Box sx={{
        width: '100%',
        height: 1000,
        maxHeight: '100%',
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[30]}
          experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
            console.log(rows);
            setSelectedFormObjs(rows.filter((row:any) => newSelectionModel.includes(row.id)));
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            border: 'none',
            '.MuiDataGrid-row': {
              background: 'rgba(255,255,255,0.8)',
            },
            '.MuiDataGrid-row:nth-of-type(2n)': {
              background: 'rgba(255,255,255,0.2)',
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
      </Box>
      <PromptModifyStatus setShow={setShowModifyStatusPrompt} show={showModifyStatusPrompt} selectedForms={selectedFormObjs} />
      <PromptDeleteForm setShow={setShowDeleteFormPrompt} show={showDeleteFormPrompt} selectedForms={selectedFormObjs} totalRows={rows.length} />
    </>);
};

export default FormsTable;
