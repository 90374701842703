import { useEffect, useState } from 'react';
import {
  Alert, ThemeProvider, Typography, createTheme,
} from '@mui/material';
import QuestionSlot from '../models/QuestionSlot';

type Props = {
  slot: QuestionSlot,
  lastYearsData: any
  severity?: 'error' | 'warning' | 'info' | 'success' | undefined
}

const AlertTheme = createTheme({
  palette: {
    info: {
      main: '#0288d1',
    },
  },
});

const SlotNaNMessage: React.FC<Props> = ({ slot, lastYearsData }) => {
  const [erroMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    displayErrorMessage();
  }, [lastYearsData]);

  const displayErrorMessage = async () => {
    if (slot.compairedTo) {
      const lastYearData = await lastYearsData(slot.compairedTo);
      if (lastYearData) {
        setErrorMessage(undefined);
      } else {
        // eslint-disable-next-line max-len
        setErrorMessage('Données de l\'année précédente sont introuvables puisqu\'il s\'agit de votre première année d\'étude. Si ce n\'est pas le cas, veuillez contacter le support.');
      }
    }
  };
  return (
    <ThemeProvider theme={AlertTheme}>
      {(erroMessage && slot.compairedTo)
       && <Alert severity="error">
         <Typography>{erroMessage}</Typography>
       </Alert>
      }
      {(!erroMessage && slot.compairedTo)
         && <Alert severity="info">
           <Typography>Résultat calculé par rapport aux données de l’année précédente</Typography>
         </Alert>
      }
    </ThemeProvider>
  );
};

export default SlotNaNMessage;
