import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import Spinner from '../components/Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import FormPEXBD from './forms/pexbd/FormPEXBD';
import LayoutNoHeader from '../components/LayoutNoHeader';
import { db } from '../FirebaseConfig';
import FormPEXBDV2 from './forms/pexbd_v2/FormPEXBD_V2';

const FormPage: React.FC = () => {
  const { formId } = useParams();
  const [programId, setProgramId] = useState('');

  useEffect(() => {
    try {
      const fetchProgramId = async () => {
        if (!formId) return;

        const formDocRef = doc(db, 'Forms', formId);
        const formDoc = await getDoc(formDocRef);

        if (formDoc.exists() && formDoc.data().state && formDoc.data().formData) {
          const { formData } = formDoc.data();
          setProgramId(formData.programId);
        }
      };
      fetchProgramId();
    } catch (e: any) {
      console.error(e);
    }
  }, [formId]);

  return (
    <LayoutNoHeader title={Utils.getTranslation(translator.pages.dashboard.title)}>
      <Spinner show={false}/>
      {
        // Formulaire PEXBD V1 (groupe 1 & groupe 2)
        (programId === 'PlhU9DxMiv4loyvKLkHE' || programId === '2Py0tJHhTfbE8qWPjcd4')
        && <FormPEXBD formId={formId} />
      }
      {
        // Formulaire PEXBD V2
        programId === 'qqZTdJVoBk2f4DXjW4Ef'
        && <FormPEXBDV2 formId={formId} />
      }
    </LayoutNoHeader>

  );
};

export default FormPage;
