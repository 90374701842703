import * as yup from 'yup';

import {
  Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Slide, Tooltip, Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import {
  forwardRef, useMemo, useState,
} from 'react';
import { TextField } from 'formik-mui';
import { TransitionProps } from '@mui/material/transitions';
import LoopIcon from '@mui/icons-material/Loop';
import Spinner from './Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch } from '../store/Hooks';
import MunicipalityService from '../services/MunicipalityService';
import ContactInformation from '../models/ContactInformation';
import AlertUtil from '../utils/AlertUtil';
import { ADMIN_EMAIL, PHONE_REGEX_P } from '../theme/Constants';
import ErrorCodes from '../models/enums/ErrorCodes';
import UserService from '../services/UserService';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import Permissions from '../models/enums/Permissions';

interface Props {
  setShow: any,
  show: boolean,
  id?: string,
  initVals?: {
    name: string,
    contactName: string,
    contactEmail: string,
    contactPhone: string,
    password: string,
    adhesionYearPEXBD: number,
  }
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PromptCreateMunicipality: React.FC<Props> = ({
  setShow, show, id, initVals,
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [randomCode, setRandomCode] = useState('');

  const createMunicipality = async (values: any, resetForm: any) => {
    try {
      setLoading(true);
      const {
        password, name, contactName, contactEmail, contactPhone, adhesionYearPEXBD,
      } = values;

      let email = `contact+${name}${ADMIN_EMAIL}`;
      email = email.replace(/\s+/g, '');

      const firebaseMunicipality = await MunicipalityService.createAccount(email, password) as any;

      const contactInformation = {
        name: contactName,
        email: contactEmail,
        phone: contactPhone,
      } as ContactInformation;

      if (firebaseMunicipality) {
        await MunicipalityService.create(firebaseMunicipality.uid, name, email, contactInformation, password, adhesionYearPEXBD);
        await UserService.create(firebaseMunicipality.uid, name, email, Permissions.MUNICIPALITY, SupportedLanguages.DEFAULT);
      }
      resetForm();

      setLoading(false);
      setShow(false);
    } catch (e: any) {
      let message = '';
      switch (e.code) {
        case ErrorCodes.ALREADY_EXISTS: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.alreadyInUse);
          break;
        }
        case ErrorCodes.INVALID_ARGUMENT: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.passwordToShort);
          break;
        }
        default: {
          message = await Utils.getTranslation(translator.errorMessages.general.unknown);
          break;
        }
      }
      if (message) AlertUtil.createErrorAlert(message as string, dispatch);
      setLoading(false);
    }
  };

  const editMunicipality = async (values: any, resetForm: any, municipalityId:string) => {
    try {
      setLoading(true);
      const {
        password, name, contactName, contactEmail, contactPhone, adhesionYearPEXBD,
      } = values;

      await MunicipalityService.update({
        id: municipalityId,
        contactInformation: {
          email: contactEmail,
          name: contactName,
          phone: contactPhone,
        },
        name,
        password,
        adhesionYearPEXBD,
      });

      await MunicipalityService.updateUser({
        id: municipalityId,
        municipalityId,
        name,
      });

      setLoading(false);
      setShow(false);
      resetForm();
    } catch (e: any) {
      let message = '';
      switch (e.code) {
        case ErrorCodes.ALREADY_EXISTS: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.alreadyInUse);
          break;
        }
        case ErrorCodes.INVALID_ARGUMENT: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.passwordToShort);
          break;
        }
        default: {
          message = await Utils.getTranslation(translator.errorMessages.general.unknown);
          break;
        }
      }
      if (message) AlertUtil.createErrorAlert(message as string, dispatch);
      setLoading(false);
    }
  };

  const generateCode = async (formikP?: any) => {
    const rCode = Math.random().toString(36).slice(2, 8);
    if (formikP) {
      formikP.getFieldHelpers('password').setValue(rCode);
    }
    setRandomCode(rCode);
  };

  useMemo(() => {
    generateCode();
  }, []);

  return (
    <Container>
      <Formik
        initialValues={initVals || {
          name: '',
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          adhesionYearPEXBD: '',
          password: randomCode,
        }}
        enableReinitialize
        validationSchema={yup.object({
          name: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
          contactName: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
          contactEmail: yup.string()
            .email(Utils.getTranslation(translator.formMessages.invalidEmail))
            .required(Utils.getTranslation(translator.formMessages.requiredField)),
          contactPhone: yup
            .string()
            .matches(PHONE_REGEX_P, Utils.getTranslation(translator.formMessages.invalidPhone))
            .required(Utils.getTranslation(translator.formMessages.requiredField)),
          password: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
          adhesionYearPEXBD: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (id) {
            editMunicipality(values, resetForm, id);
          } else {
            createMunicipality(values, resetForm);
          }
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Box>
            <Form>
              <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                sx={{
                  backgroundColor: 'background.default !important',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {id
                    ? Utils.getTranslation(translator.components.promptCreateMunicipality.titleEdit)
                    : Utils.getTranslation(translator.components.promptCreateMunicipality.title)}
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box sx={{
                    margin: '0 0 15px 0',
                  }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {Utils.getTranslation(translator.components.promptCreateMunicipality.form.information.title)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          autoFocus
                          focused
                          fullWidth
                          component={TextField}
                          name="name"
                          type="string"
                          color='primary'
                          label={Utils.getTranslation(translator.components.promptCreateMunicipality.form.information.fields.name)}
                          margin='normal'
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          focused
                          fullWidth
                          component={TextField}
                          name="password"
                          type="string"
                          color='primary'
                          label={Utils.getTranslation(translator.components.promptCreateMunicipality.form.information.fields.password)}
                          margin='normal'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Générer un code">
                                  <IconButton
                                    aria-label="Generate code"
                                    onClick={() => generateCode(formikProps)}
                                    onMouseDown={(e: any) => e.preventDefault()}
                                    color="info"
                                  >
                                    <LoopIcon/>
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          focused
                          fullWidth
                          component={TextField}
                          name="adhesionYearPEXBD"
                          type="number"
                          color='primary'
                          label={'Année d\'hadésion PEXBD'}
                          margin='normal'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                    margin: '15px 0 0 0',
                  }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {Utils.getTranslation(translator.components.promptCreateMunicipality.form.contact.title)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          focused
                          fullWidth
                          component={TextField}
                          name="contactName"
                          type="string"
                          color='primary'
                          label={Utils.getTranslation(translator.components.promptCreateMunicipality.form.contact.fields.contactName)}
                          margin='normal'
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          focused
                          fullWidth
                          component={TextField}
                          name="contactEmail"
                          type="email"
                          color='primary'
                          label={Utils.getTranslation(translator.components.promptCreateMunicipality.form.contact.fields.contactEmail)}
                          margin='normal'
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          focused
                          fullWidth
                          component={TextField}
                          name="contactPhone"
                          type="phone"
                          color='primary'
                          label={Utils.getTranslation(translator.components.promptCreateMunicipality.form.contact.fields.contactPhone)}
                          margin='normal'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                  }}>
                    <Button
                      variant="outlined"
                      color='error'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setShow(false)}
                    >
                      {Utils.getTranslation(translator.components.promptCreateMunicipality.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={(e) => formikProps.submitForm()}
                    >
                      {id
                        ? Utils.getTranslation(translator.components.promptCreateMunicipality.submitEdit)
                        : Utils.getTranslation(translator.components.promptCreateMunicipality.submit)}
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          </Box>
        )}
      </Formik>
      <Spinner show={loading}/>
    </Container>
  );
};

export default PromptCreateMunicipality;
