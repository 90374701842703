import { responsiveFontSizes } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

declare module '@mui/material/styles/createTheme' { interface Theme { custom: any; } interface ThemeOptions { custom?: any; } }

// eslint-disable-next-line import/no-mutable-exports
let themeConfig = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2e7a38',
    },
    secondary: {
      main: '#0086f0',
    },
    text: {
      primary: '#071209',
    },
    success: {
      main: '#02b82c',
    },
    info: {
      main: '#41ad49',
      contrastText: '#071209',
    },
    divider: '#58595b',
    background: {
      default: '#f4fafd',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Poppins',
      'Arial',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.7rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '600',
    },
  },
  custom: {
    boxShaddow: '6px 6px 30px 0 rgba(0,0,0,0.25)',
  },
});

themeConfig = responsiveFontSizes(themeConfig);

export default themeConfig;
