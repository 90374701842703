import { styled } from '@mui/material';

export const PEXBDFormWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

export const PEXBDFormContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '95%',
  maxWidth: '1200px',
  height: '92%',
  borderRadius: theme.shape.borderRadius as number * 3,
  boxShadow: theme.custom.boxShaddow,
  display: 'flex',
  alignItems: 'stretch',
}));

export const PEXBDFormStepContent = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  padding: '0 16px 16px 16px',
}));

export const PEXBDFormSectionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  minHeight: '200px',
  marginTop: 16,
}));
