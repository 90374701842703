import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import ReportsTable from '../components/ReportsTable';
import ReportPage from './ReportPage';

const Reports: React.FC = () => {
  const { formId } = useParams();

  return (
    <Layout title="Rapports" showBack={!!formId}>
      <Spinner show={false}/>
      <Box sx={{
        p: 2,
        height: 'calc(100vh - 64px)',
        overflowX: 'hidden',
      }}>
        {
          formId
            ? <ReportPage formId={formId} />
            : <ReportsTable />
        }
      </Box>
    </Layout>
  );
};

export default Reports;
