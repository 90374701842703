import { Box } from '@mui/material';
import appBg from '../theme/assets/appBg.png';

type Props = {
  bgPos: 'top' | 'bottom'
};

const AppBackground: React.FC<Props> = ({ bgPos }) => (
  <Box sx={{
    position: 'absolute',
    bottom: bgPos === 'bottom' ? 0 : 'auto',
    left: bgPos === 'bottom' ? 0 : 'auto',
    right: bgPos === 'top' ? 0 : 'auto',
    top: bgPos === 'top' ? '-150px' : 'auto',
    width: '100%',
    transform: bgPos === 'top' ? 'rotate(180deg)' : 'rotate(0)',
    maxWidth: bgPos === 'top' ? '700px' : '680px',
    zIndex: '-1',
  }}>
    <img src={appBg}/>
  </Box>
);

export default AppBackground;
