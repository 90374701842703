import * as yup from 'yup';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Slide,
  Select, Button, FormHelperText,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  collection, onSnapshot, orderBy, query,
} from 'firebase/firestore';
import _ from 'lodash';
import Spinner from './Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import AlertUtil from '../utils/AlertUtil';
import ErrorCodes from '../models/enums/ErrorCodes';
import User from '../models/User';
import { db } from '../FirebaseConfig';
import FormService from '../services/FormService';
import MunicipalitesSelect from './MunicipalitesSelect';

interface Props {
  setShow: any,
  show: boolean
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PromptCreateForm: React.FC<Props> = ({ setShow, show }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;
  const [loading, setLoading] = useState(false);
  const [programsArr, setProgramsArr] = useState([]) as any[];

  useEffect(() => {
    if (user) {
      const q = query(
        collection(db, 'Programs'),
        orderBy('name'),
      );
      const unsub = onSnapshot(q, (programsSnapshot) => {
        const programsArrTemp: any[] = [];
        programsSnapshot.docs.forEach((pDoc) => {
          const data = pDoc.data();
          programsArrTemp.push(
            {
              id: pDoc.ref.id,
              name: data.name,
            },
          );
        });
        setProgramsArr(programsArrTemp);
      });
      return unsub;
    }
    return undefined;
  }, [user]);

  const createForms = async (v: any, resetForm: any) => {
    try {
      setLoading(true);
      const { program, year, municipalities } = v;
      await FormService.createForm(program, year, municipalities);
      setLoading(false);
      setShow(false);
      resetForm();
    } catch (e: any) {
      console.log(e);
      let message = '';
      switch (e.code) {
        case ErrorCodes.ALREADY_EXISTS: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.alreadyInUse);
          break;
        }
        case ErrorCodes.INVALID_ARGUMENT: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.passwordToShort);
          break;
        }
        default: {
          message = await Utils.getTranslation(translator.errorMessages.general.unknown);
          break;
        }
      }
      if (message) AlertUtil.createErrorAlert(message as string, dispatch);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{
          municipalities: [] as any,
          program: '',
          year: '',
          template: '',
        }}
        validationSchema={yup.object({
          municipalities: yup.array().of(yup.string()).required(Utils.getTranslation(translator.formMessages.requiredField)),
          program: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
          year: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)),
        })}
        onSubmit={(v, { setSubmitting, resetForm }) => {
          createForms(v, resetForm);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Box>
            <Form>
              <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                sx={{
                  backgroundColor: 'background.default !important',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                    Nouveau formulaire
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box sx={{
                    margin: '0 0 15px 0',
                    minWidth: '500px',
                  }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel>Programme</InputLabel>
                          <Select
                            name="program"
                            label="Programme"
                            value={formikProps.values.program}
                            onChange={formikProps.handleChange}
                            error={formikProps.touched.program && Boolean(formikProps.errors.program)}
                          >
                            {programsArr && programsArr.map((option: {id:string, name:string}) => (
                              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            ))}
                          </Select>
                          {formikProps.touched.program && formikProps.errors.program && <FormHelperText>{formikProps.errors.program}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ textAlign: 'right', mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel>Année étudiée</InputLabel>
                          <Select
                            name="year"
                            label="Année étudiée"
                            value={formikProps.values.year}
                            onChange={formikProps.handleChange}
                            error={formikProps.touched.year && Boolean(formikProps.errors.year)}
                          >
                            {_.range(new Date().getFullYear() - 3, new Date().getFullYear() + 7).map((year:number) => (
                              <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                          </Select>
                          {formikProps.touched.year && formikProps.errors.year && <FormHelperText>{formikProps.errors.year}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <MunicipalitesSelect />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                  }}>
                    <Button
                      variant="outlined"
                      color='error'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setShow(false)}
                    >
                      {Utils.getTranslation(translator.components.promptCreateMunicipality.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={formikProps.values.municipalities.length === 0}
                      sx={{ mt: 3, mb: 2 }}
                      onClick={(e) => formikProps.submitForm()}
                    >
                      Créer
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          </Box>
        )}
      </Formik>
      <Spinner show={loading}/>
    </Container>
  );
};

export default PromptCreateForm;
