import { Delete, Edit } from '@mui/icons-material';
import {
  Box, Button, Divider, Paper, Typography,
} from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import FormsTableSimple from '../components/FormsTableSimple';
import PromptCreateMunicipality from '../components/PromptCreateMunicipality';
import PromptDeleteMunicipality from '../components/PromptDeleteMunicipality';
import { db } from '../FirebaseConfig';
import Municipality from '../models/Municipality';

type Props = {
    municipalityId: string,
  };

const MunicipalityPage: React.FC<Props> = ({ municipalityId }) => {
  const [showPromptEditMunicipality, setShowPromptEditMunicipality] = useState(false);
  const [showPromptDeleteMunicipality, setShowPromptDeleteMunicipality] = useState(false);
  const [municipalityObj, setMunicipalityObj] = useState(undefined) as any;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'Municipalities', municipalityId), (result) => {
      const municipalityDoc = result.data() as Municipality;
      setMunicipalityObj(municipalityDoc);
    });

    return unsubscribe;
  }, []);

  return (
    <Box sx={{
      width: '100%',
      pt: 3,
    }}>
      {municipalityObj && <><Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button color='primary' endIcon={<Edit />} onClick={() => setShowPromptEditMunicipality(true)}>Modifier</Button>
        <Button color='error' endIcon={<Delete />} onClick={() => setShowPromptDeleteMunicipality(true)}>Supprimer</Button>
      </Box>
      <Typography variant="h3">Informations générales</Typography>
      <Paper elevation={2} sx={{
        p: 2,
        py: 1,
        my: 3,
        mb: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box sx={{ display: 'flex', my: 3, width: '100%' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Nom de l'organisation municipale ou MRC: <span style={{ fontWeight: '400' }}>{municipalityObj.name}</span>
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Année d'hadésion PEXBD: <span style={{ fontWeight: '400' }}>{municipalityObj.adhesionYearPEXBD}</span>
          </Typography>
        </Box>
        <Divider sx={{ width: '70%' }}/>
        <Box sx={{ display: 'flex', my: 3, width: '100%' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Personne à contacter: <span style={{ fontWeight: '400' }}>{municipalityObj.contactInformation.name}</span>
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Mot de passe: <span style={{ fontWeight: '400' }}>{municipalityObj.password}</span>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', my: 3, width: '100%' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Courriel de contact: <span style={{ fontWeight: '400' }}>{municipalityObj.contactInformation.email}</span>
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
            Numéro de téléphone: <span style={{ fontWeight: '400' }}>{municipalityObj.contactInformation.phone}</span>
          </Typography>
        </Box>
      </Paper>

      <Typography variant="h3">Formulaires</Typography>
      <FormsTableSimple formList={municipalityObj.formList} />
      <PromptCreateMunicipality
        setShow={setShowPromptEditMunicipality}
        show={showPromptEditMunicipality}
        id={municipalityId}
        initVals={{
          name: municipalityObj.name,
          contactName: municipalityObj.contactInformation.name,
          contactEmail: municipalityObj.contactInformation.email,
          contactPhone: municipalityObj.contactInformation.phone,
          password: municipalityObj.password,
          adhesionYearPEXBD: municipalityObj.adhesionYearPEXBD,
        }}
      />
      <PromptDeleteMunicipality
        setShow={setShowPromptDeleteMunicipality}
        show={showPromptDeleteMunicipality}
        id={municipalityId}
        name={municipalityObj.name}
      />
      </>}
    </Box>
  );
};
export default MunicipalityPage;
