import {
  Box, Divider,
} from '@mui/material';
import {
  collection, doc, getDoc, onSnapshot, query, where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  PointElement,
  LineElement,
} from 'chart.js';
import FormScores from '../components/FormScores';
import { db } from '../FirebaseConfig';
import { useAppSelector } from '../store/Hooks';
import User from '../models/User';
import Permissions from '../models/enums/Permissions';

type Props = {
    formId: string,
};

const ReportPage: React.FC<Props> = ({ formId }) => {
  const [reportData, setReportData] = useState(false) as any;
  const [reportsData, setReportsData] = useState([]) as any[];
  const [programId, setProgramId] = useState('');
  const user = useAppSelector((state) => state.user.user) as User;

  const fetchProgramId = async () => {
    const reportDoc = await getDoc(doc(db, 'Reports', formId));
    if (reportDoc) {
      const reportDocData = reportDoc.data();
      if (reportDocData && reportDocData.programId) setProgramId(reportDocData.programId);
    }
  };

  useEffect(() => {
    let unsubscribe;
    console.log(programId);
    if (!programId) {
      fetchProgramId();
    } else if (user && user.permission === Permissions.ADMIN) {
      const q = query(collection(db, 'Reports'), where('programId', '==', programId));
      unsubscribe = onSnapshot(q, (snapshot) => {
        const tempReportsData = [] as any[];
        snapshot.docs.forEach((reportDoc) => {
          tempReportsData.push(reportDoc.data());
          if (reportDoc.data().id === formId) setReportData(reportDoc.data());
        });
        setReportsData(tempReportsData);
      });
    } else if (user && user.permission === Permissions.MUNICIPALITY) {
      const q = query(collection(db, 'Reports'), where('municipalityId', '==', user.municipalityId), where('programId', '==', programId));
      unsubscribe = onSnapshot(q, (snapshot) => {
        const tempReportsData = [] as any[];
        snapshot.docs.forEach((reportDoc) => {
          tempReportsData.push(reportDoc.data());
          if (reportDoc.data().id === formId) setReportData(reportDoc.data());
        });
        setReportsData(tempReportsData);
      });
    }

    return unsubscribe;
  }, [user, programId]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
  );

  const getChartOptions = (title: string, stacked = false) => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        stacked,
      },
      y: {
        stacked,
      },
    },
  });

  const getResumeThematiqueData = () : ChartData<'bar', (number | [number, number] | null)[], unknown> => ({
    labels: ['Gouvernance', 'Préservation', 'Multifonctionnalité'],
    datasets: [
      {
        label: 'Niveau',
        data: [reportData.report.gouvernance.niveau, reportData.report.preservation.niveau, reportData.report.multifonctionnalite.niveau],
        backgroundColor: '#00AFFF',
      },
      {
        label: 'Étoiles',
        data: [reportData.report.gouvernance.etoiles, reportData.report.preservation.etoiles, reportData.report.multifonctionnalite.etoiles],
        backgroundColor: '#F49D1A',
      },
    ],
  });

  const getDetailGouvernanceData = () : ChartData<'bar', (number | [number, number] | null)[], unknown> => {
    const labels = [] as string[];
    const dataNiveau = [] as number[];
    const dataEtoiles = [] as number[];
    reportData.report.gouvernanceDetail.forEach((item:any) => {
      labels.push(item.number);
      dataNiveau.push(item.niveau || 0);
      dataEtoiles.push(item.etoile || 0);
    });

    return {
      labels,
      datasets: [
        {
          label: 'Niveau',
          data: dataNiveau,
          backgroundColor: '#00AFFF',
        },
        {
          label: 'Étoiles',
          data: dataEtoiles,
          backgroundColor: '#F49D1A',
        },
      ],
    };
  };

  const getDetailPreservationData = () : ChartData<'bar', (number | [number, number] | null)[], unknown> => {
    const labels = [] as string[];
    const dataNiveau = [] as number[];
    const dataEtoiles = [] as number[];
    reportData.report.preservationDetail.forEach((item:any) => {
      labels.push(item.number);
      dataNiveau.push(item.niveau || 0);
      dataEtoiles.push(item.etoile || 0);
    });

    return {
      labels,
      datasets: [
        {
          label: 'Niveau',
          data: dataNiveau,
          backgroundColor: '#00AFFF',
        },
        {
          label: 'Étoiles',
          data: dataEtoiles,
          backgroundColor: '#F49D1A',
        },
      ],
    };
  };

  const getDetailMultifonctionnaliteData = () : ChartData<'bar', (number | [number, number] | null)[], unknown> => {
    const labels = [] as string[];
    const dataNiveau = [] as number[];
    const dataEtoiles = [] as number[];
    reportData.report.multifonctionnaliteDetail.forEach((item:any) => {
      labels.push(item.number);
      dataNiveau.push(item.niveau || 0);
      dataEtoiles.push(item.etoile || 0);
    });

    return {
      labels,
      datasets: [
        {
          label: 'Niveau',
          data: dataNiveau,
          backgroundColor: '#00AFFF',
        },
        {
          label: 'Étoiles',
          data: dataEtoiles,
          backgroundColor: '#F49D1A',
        },
      ],
    };
  };

  const getMultiYearComparisonDetailData = () : ChartData<'bar', (number | [number, number] | null)[], unknown> => {
    const labels = [] as string[];
    const dataNiveauGouvernance = [] as number[];
    const dataEtoilesGouvernance = [] as number[];
    const dataNiveauPreservation = [] as number[];
    const dataEtoilesPreservation = [] as number[];
    const dataNiveauMultifonctionnalite = [] as number[];
    const dataEtoilesMultifonctionnalite = [] as number[];
    if (reportData.municipalityId) {
      reportsData.forEach((report:any) => {
        if (reportData.municipalityId === report.municipalityId) {
          console.log(report);
          labels.push(report.studiedYear);
          dataNiveauGouvernance.push(report.report.gouvernance.niveau || 0);
          dataEtoilesGouvernance.push(report.report.gouvernance.etoiles || 0);
          dataNiveauPreservation.push(report.report.preservation.niveau || 0);
          dataEtoilesPreservation.push(report.report.preservation.etoiles || 0);
          dataNiveauMultifonctionnalite.push(report.report.multifonctionnalite.niveau || 0);
          dataEtoilesMultifonctionnalite.push(report.report.multifonctionnalite.etoiles || 0);
        }
      });
    }

    return {
      labels,
      datasets: [
        {
          label: 'Gouvernance - Niveau',
          data: dataNiveauGouvernance,
          backgroundColor: '#00AFFF',
          borderColor: '#00AFFF',
          stack: 'Stack 0',
        },
        {
          label: 'Gouvernance - Étoiles',
          data: dataEtoilesGouvernance,
          backgroundColor: '#F49D1A',
          borderColor: '#F49D1A',
          stack: 'Stack 1',
        },
        {
          label: 'Préservation - Niveau',
          data: dataNiveauPreservation,
          backgroundColor: '#58c9fc',
          borderColor: '#58c9fc',
          stack: 'Stack 0',
        },
        {
          label: 'Préservation - Étoiles',
          data: dataEtoilesPreservation,
          backgroundColor: '#f2b04b',
          borderColor: '#f2b04b',
          stack: 'Stack 1',
        },
        {
          label: 'Multifonctionnalité - Niveau',
          data: dataNiveauMultifonctionnalite,
          backgroundColor: '#94ddff',
          borderColor: '#94ddff',
          stack: 'Stack 0',
        },
        {
          label: 'Multifonctionnalité - Étoiles',
          data: dataEtoilesMultifonctionnalite,
          backgroundColor: '#f5c884',
          borderColor: '#f5c884',
          stack: 'Stack 1',
        },
      ],
    };
  };

  return (
    <Box sx={{
      width: '100%',
      pt: 3,
    }}>
      {reportData && <>
        {console.log(reportData)}
        <FormScores data={reportData.report} />
        <Divider sx={{ mt: 6, mb: 4 }} />
        <Bar options={getChartOptions('Résumé des résultats par thématique')} data={getResumeThematiqueData()} />
        <Divider sx={{ my: 4 }} />
        <Bar options={getChartOptions('Détail des résultats pour la thématique Gouvernance')} data={getDetailGouvernanceData()} />
        <Divider sx={{ my: 4 }} />
        <Bar options={getChartOptions('Détail des résultats pour la thématique Préservation')} data={getDetailPreservationData()} />
        <Divider sx={{ my: 4 }} />
        <Bar options={getChartOptions('Détail des résultats pour la thématique Multifonctionnalité')} data={getDetailMultifonctionnaliteData()} />
      </>}
      {reportData && reportsData && (reportsData.length > 1)
      && <>
        <Divider sx={{ my: 4 }} />
        <Bar options={getChartOptions('Comparaison des résultats au fil du temps - Détail', true)} data={getMultiYearComparisonDetailData()} />
      </>}
    </Box>
  );
};
export default ReportPage;
