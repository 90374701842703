/* eslint-disable no-useless-catch */
import {
  signInWithEmailAndPassword, signOut,
} from 'firebase/auth';
import {
  doc, collection, setDoc, deleteDoc, updateDoc, getDoc, where, query, getDocs,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { auth, db, functions } from '../FirebaseConfig';
import ContactInformation from '../models/ContactInformation';
import Municipality from '../models/Municipality';
import User from '../models/User';

class MunicipalityService {
  /**
     * @param {string} email
     * @param {string} password
     * @returns {void}
     */
  static login = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password);

  /**
     * @returns {void}
     */
  static logout = () => signOut(auth);

  /**
     * @param {string} email
     * @param {string} password
     * @returns {void}
     */
  static createAccount = async (email: string, password: string) => {
    const createMunicipalityFirebaseAccount = httpsCallable(functions, 'auth-createMunicipalityFirebaseAccount');
    const firebaseMunicipality = await createMunicipalityFirebaseAccount({ email, password });
    return firebaseMunicipality.data;
  };

  /**
     * @param {string} id
     * @param {string} name
     * @param {string} email
     * @param {ContactInformation} contactInformation
     * @param {string} password
     * @returns {timestamp}
     */
  static create = async (id:string, name: string, email: string, contactInformation: ContactInformation, password: string, adhesionYearPEXBD:number) => {
    const newMunicipality = {
      id,
      name,
      email,
      contactInformation,
      formList: [],
      password,
      adhesionYearPEXBD,
    } as Municipality;

    return setDoc(doc(db, 'Municipalities', id), newMunicipality);
  };

  /**
     * @param {string} id
     * @returns {Municipality}
     */
  static get = async (id: string) => (await getDoc(doc(db, 'Municipalities', id))).data();

  /**
     * @param {string} password
     * @returns {Municipality}
     */
  static getByPassword = async (password: string) => {
    const municipalitiesRef = collection(db, 'Municipalities');

    const querySnapshot = await getDocs(query(municipalitiesRef, where('password', '==', password)));

    if (querySnapshot.empty) throw new Error('Municipaly does not exists');

    return querySnapshot.docs[0].data();
  };

  /**
     * @param {string} id
     * @returns {timestamp}
     */
  static deleteAccount = (id: string) => {
    const deleteMunicipalityFirebaseAccount = httpsCallable(functions, 'auth-deleteMunicipalityFirebaseAccount');
    return deleteMunicipalityFirebaseAccount({ id });
  };

  /**
     * @param {string} id
     * @returns {timestamp}
     */
  static delete = (id: string) => deleteDoc(doc(db, 'Municipalities', id));

  /**
     * @param {string} id
     * @param {string} password
     * @returns {timestamp}
     */
  static updatePassword = (id: string, password: string) => {
    const updatePasswordMunicipalityFirebaseAccount = httpsCallable(functions, 'auth-updatePasswordMunicipalityFirebaseAccount');
    return updatePasswordMunicipalityFirebaseAccount({ id, password });
  };

  /**
     * @param {string} id
     * @param {string} email
     * @returns {string}
     */
  static updateEmail = (id: string, email: string) => {
    const updateEmailMunicipalityFirebaseAccount = httpsCallable(functions, 'auth-updateEmailMunicipalityFirebaseAccount');
    return updateEmailMunicipalityFirebaseAccount({ id, email });
  };

  /**
     * @param {Municipality} municipality
     * @returns {timestamp}
     */
  static update = (municipality: Municipality) => updateDoc(doc(db, 'Municipalities', municipality.id), municipality);

  static updateUser = (municipalityUser: User) => updateDoc(doc(db, 'Users', municipalityUser.id), municipalityUser);
}

export default MunicipalityService;
