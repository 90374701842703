import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid, GridColDef, GridColumnHeaderParams, frFR,
} from '@mui/x-data-grid';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../store/Hooks';
import Utils from '../utils/Utils';

interface PropsContainer {
    formList: {id:string}[]
}

const FormsTableSimple: React.FC<PropsContainer> = ({ formList }) => {
  const [rows, setRows] = useState([]) as any[];
  const { forms } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (forms) {
      const formsRowsTemp: any[] = [];
      forms.forEach((form:any) => {
        if (formList.find((el) => el.id === form.id)) {
          formsRowsTemp.push(
            {
              ...form,
              link: 'consulter',
            },
          );
        }
      });
      setRows(formsRowsTemp);
    }
  }, [forms]);

  const columns: GridColDef[] = [
    {
      field: 'studiedYear',
      headerName: 'Année étudiée',
      width: 150,
    },
    {
      field: 'programName',
      headerName: 'Programme',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'Statut',
      minWidth: 100,
      valueGetter: (params) => Utils.getFormStatusDisplayString(params.row.state),
      flex: 1,
    },
    {
      field: 'link',
      width: 200,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <></>
      ),
      renderCell: (params) => (
        <Box sx={{
          width: '100%', display: 'flex', justifyContent: 'flex-end', pr: '15px',
        }}>
          <Button
            variant="text"
            endIcon={<ArrowRightAlt />}
            sx={{
              textTransform: 'none',
            }}
            component={Link}
            to={`/form/${params.row.id}`}
          >
            {params.value}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{
        width: '100%',
        height: 400,
        maxHeight: '100%',
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[]}
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            border: 'none',
            '.MuiDataGrid-row': {
              background: 'rgba(255,255,255,0.8)',
            },
            '.MuiDataGrid-row:nth-of-type(2n)': {
              background: 'rgba(255,255,255,0.2)',
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
      </Box>
    </>);
};

export default FormsTableSimple;
