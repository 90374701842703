import {
  Box,
  Tooltip, TooltipProps, Typography, styled, tooltipClasses,
} from '@mui/material';
import ChipTooltip from './ChipTooltip';

  type Props = {
    emNumber: number,
    emTitle?: string,
  }

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '550px',
  },
});

const EngagementChip: React.FC<Props> = ({
  emNumber,
  emTitle,
}) => (
  <NoMaxWidthTooltip title={
    <ChipTooltip
      title={emTitle || ''}
      elements={[]}
    />}>
    <Box className="em-btn" sx={{
      width: '45px',
      height: '45px',
      position: 'relative',
    }}>
      <Box sx={{
        padding: '5px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}>
        <Typography sx={{
          fontSize: 25, fontWeight: 600, color: '#5D544A', zIndex: 1, letterSpacing: '-1px',
        }}>{emNumber}</Typography>
        <Box sx={{
          position: 'absolute',
          width: '40px',
          height: '40px',
        }}>
          <svg width="100%" height="100%" viewBox="0 0 425 425" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="213" cy="212" r="171" stroke="#7ABB43" strokeWidth="8"/>
            <circle cx="212.5" cy="212.5" r="208.5" stroke="#7ABB43" strokeWidth="8"/>
            <circle cx="212.5" cy="212.5" r="133.5" stroke="#7ABB43" strokeWidth="8"/>
            <circle cx="213" cy="212" r="96" stroke="#7ABB43" strokeWidth="8"/>
          </svg>
        </Box>
      </Box>

    </Box>
  </NoMaxWidthTooltip>
);

export default EngagementChip;
