import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { PEXBDFormStepContent } from '../components/StyledComponents';
import FormSection from '../sections/FormSection';
// import StepSectionRenderer from './StepSectionsRenderer';

const GeneratedFormStep: React.FC<{path:string, spec: any}> = ({ path, spec }) => {
  const stepSpec = _.get(spec, path);
  const topOfSectionRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (topOfSectionRef.current) {
      topOfSectionRef.current.scrollIntoView({ block: 'start' });
    }
  }, [topOfSectionRef, stepSpec]);

  return (
    <PEXBDFormStepContent>
      <Box ref={topOfSectionRef} sx={{ height: '40px', width: '100%' }}></Box>
      {stepSpec.sections.map((section:any, index: number) => <Box key={index}>
        <FormSection
          sectionNumber={section.number}
          sectionTitle={section.title || ''}
          sectionDescription={section.description}
          sectionsPath={`${path}.questions`}
          fieldPath={`${path}.questions[${index}]`}
          slots={section.slots}
          frequency={section.frequency}
        />
      </Box>)}
    </PEXBDFormStepContent>
  );
};

export default GeneratedFormStep;
