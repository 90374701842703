import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import * as yup from 'yup';

import {
  Box, Paper,
} from '@mui/material';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/Hooks';

import ErrorService from '../../services/ErrorService';
import Spinner from '../../components/Spinner';
import translator from '../../theme/translator.json';
import Utils from '../../utils/Utils';
import AppBackground from '../../components/AppBackground';
import logoFull from '../../theme/assets/logo-full.png';

const ForgotCode: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const sendRecoverCodeLink = async (values: any) => {
    try {
      setLoading(true);

      // TODO should send an email with the municipality code
      console.log('send an email with the municipality code');

      setLoading(false);
      navigate('/municipality/login/?from=emailSent');
    } catch (e: any) {
      setLoading(false);
      ErrorService.handleError(e, dispatch);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
        }}
      >
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={yup.object({
            email: yup.string()
              .email(Utils.getTranslation(translator.formMessages.invalidEmail))
              .required(Utils.getTranslation(translator.formMessages.requiredField)),
          })}
          onSubmit={(values, { setSubmitting }) => {
            sendRecoverCodeLink(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '600px',
              width: '100%',
            }}>
              <Box sx={{
                maxWidth: '300px',
              }}>
                <img src={logoFull}/>
              </Box>
              <Paper sx={{
                margin: '15px',
              }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    width: '100%',
                  }}
                >
                  <Typography align="center" variant="h4">
                    {Utils.getTranslation(translator.pages.forgotCode.title)}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {Utils.getTranslation(translator.pages.forgotCode.abstract)}
                  </Typography>
                  <Form style={{ width: '100%' }}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label={Utils.getTranslation(translator.pages.forgotCode.email)}
                      margin='normal'
                      fullWidth
                    />
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {Utils.getTranslation(translator.pages.forgotCode.submit)}
                      </Button>
                    </Box>
                  </Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Link href="/municipality/login" variant="body2">
                      {Utils.getTranslation(translator.pages.forgotCode.toCodeLogin)}
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </Formik>
        <Spinner show={loading}/>
      </Box>
      <AppBackground bgPos="bottom"/>
    </Container>
  );
};

export default ForgotCode;
