import {
  Box, Button, Chip, Popover, Typography,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import { db } from '../../../../FirebaseConfig';

type Props = {
    isDisabled: boolean,
    frequency: number | undefined,
    adhesionYear: number,
    studiedYear: number,
    fieldPath: string,
    programId: string,
    municipalityId: string,
}

const UpdateFrequencyChip: React.FC<Props> = (props) => {
  const updateFrequencyChip = useRef(null);
  const [openUpdateFrequencyPopover, setOpenUpdateFrequencyPopover] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { setFieldValue } = useFormikContext();

  const displayNextUpdateContent = () : ReactNode => {
    if (!props.frequency) {
      setOpenUpdateFrequencyPopover(false);
      return (<></>);
    }

    const timeSinceUpdate = (props.studiedYear - props.adhesionYear) % props.frequency;
    const timeToNextUpdate = props.frequency - timeSinceUpdate;
    if (props.studiedYear <= props.adhesionYear || timeSinceUpdate === 0) {
      return (
        <Typography>{'Prochaine mise à jour: cette année'}</Typography>
      );
    }

    return (
      <>
        <Typography sx={{ py: 1 }}>{`Prochaine mise à jour: en ${props.studiedYear + timeToNextUpdate}`}</Typography>
        <Button variant='text' sx={{ textTransform: 'none' }} onClick={() => setDataFromLastYear()} disabled={isDisabled}>
            Utiliser les donnés de l'année précédente
        </Button>
      </>

    );
  };

  const setDataFromLastYear = async () => {
    setIsDisabled(true);
    const q = query(
      collection(db, 'Forms'),
      where('municipalityId', '==', props.municipalityId),
      where('programId', '==', props.programId),
      where('studiedYear', '==', props.studiedYear - 1),
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docData = querySnapshot.docs[0].data();
      if (!docData) return;
      const { formData } = docData;
      if (!formData) return;
      const previousYearSection = _.get(formData, props.fieldPath);
      if (!previousYearSection || !previousYearSection.slots) return;
      setFieldValue(`${props.fieldPath}.slots`, previousYearSection.slots);
    }

    setIsDisabled(false);
    setOpenUpdateFrequencyPopover(false);
  };

  return (
    <>
      <Chip
        icon={<UpdateIcon />}
        size="small"
        color="primary"
        disabled={props.isDisabled}
        label={(props.frequency) ? `Tous les ${props.frequency} ans` : 'annuel'}
        ref={updateFrequencyChip}
        onClick={() => props.frequency && setOpenUpdateFrequencyPopover(true)}
        sx={{ opacity: 0.6 }} />
      {props.frequency && <Popover
        anchorEl={updateFrequencyChip.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={() => setOpenUpdateFrequencyPopover(false)}
        open={openUpdateFrequencyPopover}
      >
        <Box sx={{ p: 1, textAlign: 'center' }}>
          {displayNextUpdateContent()}
        </Box>
      </Popover>}
    </>
  );
};

export default UpdateFrequencyChip;
