import Typography from '@mui/material/Typography';

import * as yup from 'yup';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button, Container, FormControlLabel, IconButton, InputAdornment, Paper,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Switch, TextField } from 'formik-mui';
import { browserLocalPersistence, browserSessionPersistence, setPersistence } from 'firebase/auth';
import UserService from '../../services/UserService';
import { useAppDispatch } from '../../store/Hooks';
import ErrorService from '../../services/ErrorService';
import { auth } from '../../FirebaseConfig';
import Spinner from '../../components/Spinner';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';
import logoFull from '../../theme/assets/logo-full.png';
import AppBackground from '../../components/AppBackground';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoginExpanded } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const login = async (values: any) => {
    try {
      setLoading(true);

      // Closing the window would not clear any existing state
      if (values.rememberMe) setPersistence(auth, browserLocalPersistence);

      // Closing the window would clear any existing state
      else setPersistence(auth, browserSessionPersistence);
      await UserService.login(values.email, values.password);

      setLoading(false);
      navigate('/');
    } catch (e: any) {
      ErrorService.handleError(e, dispatch);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Formik
          initialValues={{
            email: '',
            password: '',
            rememberMe: true,
          }}
          validationSchema={yup.object({
            email: yup.string()
              .email(Utils.getTranslation(translator.formMessages.invalidEmail))
              .required(Utils.getTranslation(translator.formMessages.requiredField)),
            password: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
            stayConnected: yup.boolean(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            login(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '600px',
            }}>
              <Box sx={{
                maxWidth: '300px',
              }}>
                <img src={logoFull}/>
              </Box>
              <Paper sx={{
                margin: '15px',
              }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '30px 20px 0',
                  }}
                >
                  <Typography variant="h3" sx={{ fontWeight: 600 }}>Connexion au portail</Typography>
                  <Button href="/municipality/login" color='secondary' variant="contained" sx={{ my: 5 }}>
                    Je représente une municipalité
                  </Button>
                  <Box sx={{
                    display: 'flex', width: '40%', alignItems: 'center', mb: 2,
                  }}>
                    <Box sx={{
                      width: '100%', height: '1px', background: '#eeeeee', mx: 2,
                    }}></Box>
                    <Typography fontSize={14}>ou</Typography>
                    <Box sx={{
                      width: '100%', height: '1px', background: '#eeeeee', mx: 2,
                    }}></Box>
                  </Box>
                </Box>
                <Accordion
                  defaultExpanded={!!isLoginExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                  >
                    <Typography variant="h5">Connexion administrateur.rice</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                      }}
                    >
                      <Typography variant="h3">
                        {Utils.getTranslation(translator.pages.login.title)}
                      </Typography>
                      <Form>
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          color='primary'
                          label={Utils.getTranslation(translator.pages.login.email)}
                          margin='normal'
                          fullWidth
                        />
                        <Field
                          component={TextField}
                          name="password"
                          margin='normal'
                          color='primary'
                          type={showPassword ? 'text' : 'password'}
                          label={Utils.getTranslation(translator.pages.login.password)}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={(e: any) => e.preventDefault()}
                                  color="info"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Field component={Switch} type="checkbox" name="rememberMe" />
                          }
                          label={Utils.getTranslation(translator.pages.login.rememberMe)}
                        />
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            {Utils.getTranslation(translator.pages.login.submit)}
                          </Button>
                        </Box>
                      </Form>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Box>
          )}
        </Formik>
        <Spinner show={loading}/>
      </Box>
      <AppBackground bgPos="bottom"/>
    </Container>
  );
};

export default Login;
