import '../theme/css/global.css';
import {
  Box, styled,
} from '@mui/material';
import { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import AppBackground from './AppBackground';

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

type Props = {
  children?: React.ReactNode
  title: string,
  showBack?: boolean
};

const Layout: React.FC<Props> = ({ children, title, showBack }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <LayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </LayoutRoot>
      <Navbar title={title} onSidebarOpen={() => setSidebarOpen(true)} showBack={showBack}/>
      <Sidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <AppBackground bgPos="top" />
    </>
  );
};

export default Layout;
