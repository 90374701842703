import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import AlertUtil from '../../../../../utils/AlertUtil';
import { useAppDispatch } from '../../../../../store/Hooks';

interface Props {
  setShow: any,
  show: boolean
}

const HelpDialog: React.FC<Props> = ({ setShow, show }) => {
  const dispatch = useAppDispatch();

  const [pdfUrl, setPdfUrl] = useState('');

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    fetchPdfUrl().then((url: any) => {
      setPdfUrl(url);
    }).catch(() => {
      AlertUtil.createErrorAlert('Impossible d\'afficher le guide méthodologique', dispatch);
    });
  }, []);

  const fetchPdfUrl = async () => {
    const storage = getStorage();
    const pdfRef = ref(storage, 'gs://reseau-environnement-dev.appspot.com/PEXBD_guide méthodologique_2024.pdf'); // Replace with your PDF path in Firebase Storage

    try {
      const url = await getDownloadURL(pdfRef);
      return url;
    } catch (error) {
      return null;
    }
  };

  return (
    <Container>
      <Dialog
        fullScreen
        open={show}
        onClose={() => handleClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Guide méthodologique
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          src={pdfUrl}
          style={{ width: '100%', height: '100%' }}
          frameBorder="0"
        >
      This browser does not support PDFs. Please download the PDF to view it.
        </iframe>

      </Dialog>
    </Container>);
};
export default HelpDialog;
