import {
  Box, Button, Card, CardContent, CardHeader, Container, Divider,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import translator from '../../../theme/translator.json';
import Utils from '../../../utils/Utils';
import ErrorService from '../../../services/ErrorService';
import Spinner from '../../../components/Spinner';
import { PHONE_REGEX_P } from '../../../theme/Constants';
import Municipality from '../../../models/Municipality';
import MunicipalityService from '../../../services/MunicipalityService';
import AlertUtils from '../../../utils/AlertUtil';

const ContactInformation: React.FC = () => {
  const municipality = useAppSelector((state) => state.user.municipality) as Municipality;
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const updatemunicipality = async (values: any) => {
    try {
      const { contactName, contactEmail, contactPhone } = values;
      setLoading(true);

      // Big f to readonly property
      const tempMunicipality = {
        ...municipality,
      };
      const tempTempContactInformation = tempMunicipality.contactInformation;

      const tempContactInformation = {
        ...tempTempContactInformation,
      };
      tempContactInformation.name = contactName;
      tempContactInformation.email = contactEmail;
      tempContactInformation.phone = contactPhone;

      tempMunicipality.contactInformation = tempContactInformation;

      await MunicipalityService.update(tempMunicipality);

      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      ErrorService.handleError(e, dispatch);
      setLoading(false);
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          marginTop: '20px',
        }}
      >
        {
          municipality
        && <Formik
          initialValues={{
            contactName: municipality?.contactInformation.name,
            contactEmail: municipality?.contactInformation.email,
            contactPhone: municipality?.contactInformation.phone,
          }}
          validationSchema={yup.object({
            contactName: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
            contactEmail: yup.string()
              .email(Utils.getTranslation(translator.formMessages.invalidEmail))
              .required(Utils.getTranslation(translator.formMessages.requiredField)),
            contactPhone: yup
              .string()
              .matches(PHONE_REGEX_P, Utils.getTranslation(translator.formMessages.invalidPhone))
              .required(Utils.getTranslation(translator.formMessages.requiredField)),
          })}
          onSubmit={(values, { setSubmitting }) => {
            updatemunicipality(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form>
              <Card>
                <CardHeader
                  subheader={Utils.getTranslation(translator.pages.settings.contact.subheader)}
                  title={Utils.getTranslation(translator.pages.settings.contact.title)}
                />
                <Divider />
                <CardContent>
                  <Field
                    component={TextField}
                    name="contactName"
                    type="text"
                    label={Utils.getTranslation(translator.pages.settings.contact.inputs.contactNameLabel)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="contactEmail"
                    type="text"
                    label={Utils.getTranslation(translator.pages.settings.contact.inputs.contactEmailLabel)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="contactPhone"
                    type="phone"
                    label={Utils.getTranslation(translator.pages.settings.contact.inputs.contactPhoneContact)}
                    margin='normal'
                    fullWidth
                  />
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                  }}
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {Utils.getTranslation(translator.pages.settings.contact.submit)}
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
        }
      </Container>
      <Spinner show={loading} />
    </>
  );
};

export default ContactInformation;
