import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import ErrorService from '../services/ErrorService';
import { useAppDispatch } from '../store/Hooks';

interface ContainerProps {
  show: boolean,
}

const Spinner: React.FC<ContainerProps> = ({
  show,
}) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const closeSpinner = () => {
    const timer1 = setTimeout(() => {
      setOpen(false);
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  };

  const openSpinner = () => {
    const timer1 = setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      if (show) {
        openSpinner();
      } else {
        closeSpinner();
      }
    } catch (e: any) {
      ErrorService.handleError(e, dispatch);
    }
  }, [show]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Spinner;
