/* eslint-disable camelcase */
/* eslint-disable max-len */
import _ from 'lodash';
import QuestionType from '../enums/QuestionType';
import SlotTypes from '../SlotTypes';
import Question from '../Question';
import {
  defaultNiveau, etoilePercentage, etoilePercentage2, etoileProgrammes2, etoileProgression,
} from '../SlotHelp.spec';
import SlotUnit from '../enums/SlotUnit';

const question5_1: Question = {
  id: '62',
  title: 'Intégration du concept de « quartier vert / écoquartier » dans les documents de planification',
  number: '5.1',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 12,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question5_2: Question = {
  id: '63',
  title: 'Aires de conservation aménagées pour le public',
  number: '5.2',
  description: ['Proportion des aire de conservation/milieux naturels accessibles à la population qui sont aménagées, dans les limites des capacités de support de l\'écosystème'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      score: '0',
      labels: ['0% - 10%', '11% - 25%', '26% - 40 %', '+ de 40 %'],
      maxScore: 3,
      help: etoilePercentage,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question5_3: Question = {
  id: '64',
  title: 'Proximité des résidences à la nature',
  number: '5.3',
  description: ['Proportion de résidents vivant à moins de 300m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50% par de la végétation haute'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      labels: ['0% - 10%', '11% - 25%', '26% - 40 %', '+ de 40 %'],
      help: etoilePercentage,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question5_4: Question = {
  id: '65',
  title: 'Proximité des établissements scolaires, des hôpitaux et des maisons pour personnes âgées à la nature',
  number: '5.4',
  description: ['Proportion d\'établissements scolaires, d\'hôpitaux et de maisons pour personnes âgées situés à moins de 300 m d\'une aire de conservation aménagée ou d\'un parc urbain si sa superficie est couverte à plus de 50 % par de la végétation haute'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:number) => {
        if (data >= 41) return 3;
        if (data >= 26) return 2;
        if (data >= 11) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      labels: ['0% - 10%', '11% - 25%', '26% - 40 %', '+ de 40 %'],
      help: etoilePercentage,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question6_1: Question = {
  id: '66',
  title: 'Adoption d\'un règlement interdisant l’envoi des eaux pluviales dans les réseaux d’égouts dans les nouvelles constructions',
  number: '6.1',
  description: ['Actions en faveur de la gestion d\'eau'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 9,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question6_2: Question = {
  id: '67',
  title: 'Coefficient de perméabilité',
  number: '6.2',
  description: ['Coefficient de perméabilité, en ne comptabilisant que les ouvrages végétalisés'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:number) => {
        if (data >= 76) return 3;
        if (data >= 61) return 2;
        if (data >= 41) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoilePercentage2,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question6_3: Question = {
  id: '68',
  title: 'Superficie de la végétation',
  number: '6.3',
  description: ['La superficie de végétation basse inclut les prairies, friches et parcs, alors que la végétation haute inclut tous arbres urbains, boisés urbains et forêts (indice de canopée)'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:any, values: any) => {
        if (data && data.currentYearValue && data.previousYearValue) {
          const { currentYearValue, previousYearValue } = data;

          const { superficie } = values;
          const currentYearAreaInKm2 = currentYearValue * 0.01;
          const previousYearAreaInKm2 = previousYearValue * 0.01;
          const coefficientCurrentYear = (currentYearAreaInKm2 / superficie).toFixed(2);
          const coefficientPreviousYear = (previousYearAreaInKm2 / superficie).toFixed(2);

          const difference = parseFloat(coefficientCurrentYear) - parseFloat(coefficientPreviousYear);
          if (difference > 0) return 1;
          if (difference < 0) return 0;
          if (difference === 0) return 0.5;
          return difference;
        }
        return 0;
      },
      previewFct: (data:any) => {
        if (data && data.areaVegetation && data.areaMunicipality) {
          const { areaMunicipality, areaVegetation } = data;
          return {
            value: `${(((parseInt(areaVegetation, 10) * 0.01) / areaMunicipality) * 100).toFixed(2)}%`,
            label: 'Coefficient',
          };
        }
        return { value: '0%', label: 'Coefficient' };
      },
      score: '0',
      maxScore: 1,
      compairedTo: 'sections[2].subsections[1].questions[2].slots[0].data',
      help: etoileProgression,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question6_4: Question = {
  id: '69',
  title: 'Adoption d\'une Politique de l\'arbre',
  number: '6.4',
  description: ['Actions en faveur des arbres'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 9,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_1: Question = {
  id: '70',
  title: 'Non-utilisation des pesticides dans les activités de l’organisation municipale',
  number: '7.1',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 8,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_2: Question = {
  id: '71',
  title: 'Campagne de sensibilisation à destination des citoyens',
  number: '7.2',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 8,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_3: Question = {
  id: '72',
  title: 'Programme de ruelles vertes ou Bleues-Vertes',
  number: '7.3',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 12,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_4: Question = {
  id: '73',
  title: 'Participation à des programmes de sensibilisation ou de reconnaissance',
  number: '7.4',
  description: ['L\'organisation participe à des programmes nationaux ou internationaux de sensibilisation à la biodiversité ou de reconnaissance des actions posées'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 14,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_5: Question = {
  id: '74',
  title: 'Programmes de jardins partagé',
  number: '7.5',
  description: ['L\'organisation participe à des programme de jardins communautaires et collectifs et/ou d\'aménagements comestibles (nombres de programmes)'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileProgrammes2,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_6: Question = {
  id: '75',
  title: 'Absence de règlement défavorable à l\'aménagement de potager sur les terrains privés',
  number: '7.6',
  description: ['Absence de règlement défavorable à l\'aménagement de potager sur toute ou partie des terrains privés'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 10,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question7_7: Question = {
  id: '76',
  title: 'Programmes de conservation et de restauration de friche',
  number: '7.7',
  description: ['Nombre total de projets réalisés de conservation et restauration de friche en milieu urbain'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileProgrammes2,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

export {
  question5_1,
  question5_2,
  question5_3,
  question5_4,
  question6_1,
  question6_2,
  question6_3,
  question6_4,
  question7_1,
  question7_2,
  question7_3,
  question7_4,
  question7_5,
  question7_6,
  question7_7,
};
