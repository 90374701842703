import {
  Chip, Tooltip,
} from '@mui/material';
import {
  useEffect, useRef, useState,
} from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import _ from 'lodash';
import ChipTooltip from './chips/ChipTooltip';

type Props = {
    isDisabled: boolean,
    frequency: number,
    adhesionYear: number,
    studiedYear: number,
}

const UpdateFrequencyChip: React.FC<Props> = (props) => {
  const updateFrequencyChip = useRef(null);

  const [timeUntilNextUpdate, setTimeUntilNextUpdate] = useState<any>();

  useEffect(() => {
    const timeSinceUpdate = (props.studiedYear - props.adhesionYear) % props.frequency;
    setTimeUntilNextUpdate(props.frequency - timeSinceUpdate);
  }, [props.studiedYear]);

  return (
    <Tooltip title={
      <ChipTooltip title={`Tous les ${props.frequency} ans`}/>
    }>
      <Chip
        icon={<UpdateIcon />}
        size="small"
        color="primary"
        disabled={props.isDisabled}
        label={(props.frequency) ? `Prochaine mise à jour en ${props.studiedYear + timeUntilNextUpdate}` : 'annuel'}
        ref={updateFrequencyChip}
        sx={{ opacity: 0.6 }} />
    </Tooltip>
  );
};

export default UpdateFrequencyChip;
