import {
  Alert, ThemeProvider, Typography, createTheme,
} from '@mui/material';
import QuestionSlot from '../models/QuestionSlot';

type Props = {
    slot?: QuestionSlot,
    message: any,
    severity?: 'error' | 'warning' | 'info' | 'success' | undefined
}

const AlertTheme = createTheme({
  palette: {
    info: {
      main: '#0288d1',
    },
  },
});

const SlotInfoMessage: React.FC<Props> = ({ slot, message, severity }) => (
  <ThemeProvider theme={AlertTheme}>
    <Alert severity={severity}>
      <Typography>{message}</Typography>
    </Alert>
  </ThemeProvider>

);

export default SlotInfoMessage;
