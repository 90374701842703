import {
  Box, styled, TextField, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { PEXBDFormStepContent } from '../components/StyledComponents';
import initialValues from '../models/initialValues';

const PEXBhr = styled('div')(({ theme }) => ({
  width: '80%',
  height: '1px',
  margin: '20px auto',
  background: 'black',
  opacity: 0.2,
}));

const Informations: React.FC = () => {
  const values = useFormikContext().values as typeof initialValues;
  const { handleChange } = useFormikContext();

  return (
    <PEXBDFormStepContent>
      <Box sx={{ mt: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Nom de l'organisation municipale ou MRC: <span style={{ fontWeight: '400' }}>{values.nomMunicipalite}</span>
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', my: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
          Année d'adhésion: <span style={{ fontWeight: '400' }}>{values.anneeAdhesion}</span>
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
          Année étudiée: <span style={{ fontWeight: '400' }}>{values.anneeEtudiee}</span>
        </Typography>
      </Box>

      <PEXBhr></PEXBhr>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          name="nomResponsable"
          size="small"
          value={_.get(values, 'nomResponsable')}
          onChange={handleChange}
          label="Nom et prénom de la personne responsable"
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
        <TextField
          name="fonction"
          size="small"
          value={_.get(values, 'fonction')}
          onChange={handleChange}
          label="Fonction"
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
      </Box>
      <Box sx={{ display: 'flex', mb: 6, justifyContent: 'space-between' }}>
        <TextField
          name="courriel"
          size="small"
          value={_.get(values, 'courriel')}
          onChange={handleChange}
          label="Adresse courriel"
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
        <TextField
          name="telephone"
          size="small"
          value={_.get(values, 'telephone')}
          onChange={handleChange}
          label="Numéro de téléphone"
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
      </Box>

      <PEXBhr></PEXBhr>

      <Box sx={{ display: 'flex', my: 3, justifyContent: 'space-between' }}>
        <TextField
          name="population"
          size="small"
          value={_.get(values, 'population')}
          onChange={handleChange}
          label="Population"
          type='number'
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
        <TextField
          name="superficie"
          size="small"
          value={_.get(values, 'superficie')}
          onChange={handleChange}
          label="Superficie du territoire"
          type="number"
          helperText="km2"
          sx={{ marginTop: '30px', width: '48%' }}
          disabled={_.get(values, 'isDisabled')}
        />
      </Box>
    </PEXBDFormStepContent>
  );
};

export default Informations;
