/* eslint-disable camelcase */
import {
  question1_1,
  question1_2,
  question1_3,
  question1_4,
  question1_5,
  question1_6,
  question1_7,
  question1_8,
  question1_9,
  question1_10,
  question1_11,
  question1_12,
  question1_13,
  question1_14,
} from './section/Gouvernance';

import {
  question2_1,
  question2_2,
  question2_3,
  question2_4,
  question2_5,
  question2_6,
  question2_7,
  question2_8,
  question2_9,
  question2_10,
  question2_11,
  question2_12,
  question2_13,
  question2_14,
  question2_15,
  question2_16,
  question3_1,
  question3_2,
  question3_3,
  question3_4,
  question3_5,
  question3_6,
  question3_7,
  question3_8,
  question3_9,
  question3_10,
  question3_11,
  question3_12,
  question3_13,
  question3_14,
  question3_15,
  question3_16,
  question3_17,
  question3_18,
  question3_19,
  question3_20,
  question3_21,
  question3_22,
  question4_1,
  question4_2,
  question4_3,
  question4_4,
  question4_5,
  question4_6,
  question4_7,
  question4_8,
  question4_9,
} from './section/Preservation';

import {
  question5_1,
  question5_2,
  question5_3,
  question5_4,
  question6_1,
  question6_2,
  question6_3,
  question6_4,
  question7_1,
  question7_2,
  question7_3,
  question7_4,
  question7_5,
  question7_6,
  question7_7,
} from './section/Multifonctionnalite';
import FormSpecification from './FormSpecification';

/* eslint-disable max-len */
const FormSpec: FormSpecification = {
  version: 2,
  acceptedConditions: false,
  municipalityId: '',
  nomMunicipalite: '',
  nomResponsable: '',
  courriel: '',
  telephone: '',
  anneeEtudiee: '',
  anneeAdhesion: '',
  programId: '',
  programName: '',
  templateSlug: '',
  sections: [
    {
      id: '1',
      title: 'Gouvernance',
      subsections: [
        {
          id: '1',
          title: 'Intégration et financement de la préservation de la biodiversité',
          questions: [
            question1_1,
            question1_2,
            question1_3,
            question1_4,
            question1_5,
            question1_6,
            question1_7,
            question1_8,
            question1_9,
            question1_10,
            question1_11,
            question1_12,
            question1_13,
            question1_14,
          ],
        },
      ],
    },
    {
      id: '2',
      title: 'Préservation',
      subsections: [
        {
          id: '1',
          title: 'question 2: Territoires bénéficiant d\'un statut de protection et corridors écologiques',
          questions: [
            question2_1,
            question2_2,
            question2_3,
            question2_4,
            question2_5,
            question2_6,
            question2_7,
            question2_8,
            question2_9,
            question2_10,
            question2_11,
            question2_12,
            question2_13,
            question2_14,
            question2_15,
            question2_16,
          ],
        },
        {
          id: '2',
          title: 'question 3: Grands écosystèmes et espèces à statut',
          questions: [
            question3_1,
            question3_2,
            question3_3,
            question3_4,
            question3_5,
            question3_6,
            question3_7,
            question3_8,
            question3_9,
            question3_10,
            question3_11,
            question3_12,
            question3_13,
            question3_14,
            question3_15,
            question3_16,
            question3_17,
            question3_18,
            question3_19,
            question3_20,
            question3_21,
            question3_22,
          ],
        },
        {
          id: '3',
          title: 'question 4: Espèces exotiques envahissantes',
          questions: [
            question4_1,
            question4_2,
            question4_3,
            question4_4,
            question4_5,
            question4_6,
            question4_7,
            question4_8,
            question4_9,
          ],
        },
      ],
    },
    {
      id: '3',
      title: 'Multifonctionnalité',
      subsections: [
        {
          id: '1',
          title: 'question 5: Santé et loisirs',
          questions: [
            question5_1,
            question5_2,
            question5_3,
            question5_4,
          ],
        },
        {
          id: '2',
          title: 'question 6: Services écosystémiques',
          questions: [
            question6_1,
            question6_2,
            question6_3,
            question6_4,
          ],
        },
        {
          id: '3',
          title: 'question 7: Engagement collectif',
          questions: [
            question7_1,
            question7_2,
            question7_3,
            question7_4,
            question7_5,
            question7_6,
            question7_7,
          ],
        },
      ],
    },
  ],
};

export default FormSpec;
