import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';

const Logs: React.FC = () => (
  <Layout title={Utils.getTranslation(translator.pages.admin.logs.title)}>
    <Spinner show={false}/>
  </Layout>
);

export default Logs;
