import * as yup from 'yup';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Button, Typography, InputAdornment, Tooltip, IconButton,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { forwardRef, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import _ from 'lodash';
import { Calculate } from '@mui/icons-material';
import {
  getDocs, getDoc, doc, query, collection, where,
} from 'firebase/firestore';
import { TextField } from 'formik-mui';
import Spinner from '../../../../components/Spinner';
import Utils from '../../../../utils/Utils';
import translator from '../../../../theme/translator.json';
import FormService from '../../../../services/FormService';
import { db } from '../../../../FirebaseConfig';

interface Props {
  setShow: any,
  show: boolean,
  values: any,
  formId: string,
  spec:any,
  programId: string,
  municipalityId: string,
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const formItems = [
  {
    title: 'Moyenne des membres pour l\'indicateur 1.5',
    fieldName: 'repeater15',
    fieldPath: 'indicateurs[0].subsections[0].sections[4].slots[1].data',
  },
  {
    title: 'Moyenne des membres pour l\'indicateur 1.6',
    fieldName: 'repeater16',
    fieldPath: 'indicateurs[0].subsections[0].sections[5].slots[1].data',
  },
  {
    title: 'Moyenne des membres pour l\'indicateur 2.1',
    fieldName: 'repeater21',
    fieldPath: 'indicateurs[0].subsections[1].sections[0].slots[1].data',
  },
  {
    title: 'Moyenne des membres pour l\'indicateur 2.2',
    fieldName: 'repeater22',
    fieldPath: 'indicateurs[0].subsections[1].sections[1].slots[1].data',
  },
];

const PromptGenerateReport: React.FC<Props> = ({
  setShow, show, values, formId, spec, programId, municipalityId,
}) => {
  const [loading, setLoading] = useState(false);

  const generateReport = async (v:any, resetForm: any) => {
    setLoading(true);
    await FormService.generateReport(v, values, formId, spec, programId, municipalityId);
    setLoading(false);
    resetForm();
    setShow(false);
  };

  const setCalculatedAverage = async (setFieldValue:any, fieldName:string, formFieldPath:string) => {
    if (!formId) return;

    const formDoc = await getDoc(doc(db, 'Forms', formId));
    if (formDoc.exists() && formDoc.data().templateSlug) {
      const { templateSlug } = formDoc.data();

      const q = query(
        collection(db, 'Forms'),
        where('templateSlug', '==', templateSlug),
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size) {
        let n = 0;
        let totalAll = 0;
        querySnapshot.forEach((form:any) => {
          const data = form.data();
          const repeaterData = _.get(data.formData, formFieldPath);
          const population = _.get(data.formData, 'population');
          if (repeaterData && repeaterData.length && population) {
            let totalForm = 0;
            repeaterData.forEach((v:any) => {
              totalForm += parseInt(v.ammount, 10) || 0;
            });
            n += 1;
            totalAll += (totalForm / population);
          }
        });
        let average = totalAll / n;
        if (!average) average = 0;
        setFieldValue(fieldName, average.toFixed(2));
      }
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          repeater15: 0,
          repeater16: 0,
          repeater21: 0,
          repeater22: 0,
        }}
        validationSchema={yup.object({
          repeater15: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)).min(0.01, 'La moyenne doit être plus grande que 0'),
          repeater16: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)).min(0.01, 'La moyenne doit être plus grande que 0'),
          repeater21: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)).min(0.01, 'La moyenne doit être plus grande que 0'),
          repeater22: yup.number().required(Utils.getTranslation(translator.formMessages.requiredField)).min(0.01, 'La moyenne doit être plus grande que 0'),
        })}
        onSubmit={(v, { setSubmitting, resetForm }) => {
          generateReport(v, resetForm);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Box>
            <Form>
              <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                sx={{
                  backgroundColor: 'background.default !important',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                    Générer le rapport
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box sx={{
                    margin: '0 0 15px 0',
                    minWidth: '500px',
                  }}>
                    {formItems.map((item, index:number) => (
                      <Box sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 3,
                      }}
                      key={index}>
                        <Typography>{item.title}</Typography>
                        <Box sx={{ width: '50%' }}>
                          <Field
                            autoFocus
                            fullWidth
                            component={TextField}
                            name={item.fieldName}
                            type="number"
                            color='primary'
                            label="Moyenne des membres"
                            margin='normal'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Calculer la valeur">
                                    <IconButton
                                      aria-label="Calculer la valeur"
                                      onClick={() => setCalculatedAverage(formikProps.setFieldValue, item.fieldName, item.fieldPath)}
                                      onMouseDown={(e: any) => e.preventDefault()}
                                      color="primary"
                                    >
                                      <Calculate/>
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                  }}>
                    <Button
                      variant="outlined"
                      color='error'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setShow(false)}
                    >
                      {Utils.getTranslation(translator.components.promptCreateMunicipality.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={(e) => formikProps.submitForm()}
                    >
                      Générer
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          </Box>
        )}
      </Formik>
      <Spinner show={loading}/>
    </Container>
  );
};

export default PromptGenerateReport;
