import Layout from '../../components/Layout';
import { useAppSelector } from '../../store/Hooks';
import translator from '../../theme/translator.json';
import Utils from '../../utils/Utils';
import User from '../../models/User';
import Permissions from '../../models/enums/Permissions';
import ContactInformation from './components/ContactInformation';
import GeneralInformations from './components/GeneralInformations';
import ChangePassword from './components/ChangePassword';
import ChangeCode from './components/ChangeCode';

const Settings: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User;

  return (
    <>
      <Layout title={Utils.getTranslation(translator.pages.settings.title)}>
        {
          user?.permission === Permissions.MUNICIPALITY
          && <>
            <ContactInformation/>
            <ChangeCode/>
          </>
        }
        {
          user?.permission === (Permissions.ADMIN || Permissions.SUPER_ADMIN)
          && <>
            <GeneralInformations/>
            <ChangePassword/>
          </>
        }
      </Layout>
    </>
  );
};

export default Settings;
