import {
  Box,
} from '@mui/material';

  type Props = {
    sectionNumber:number,
  }

const FormSectionNumber: React.FC<Props> = ({ sectionNumber }) => (<>
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    alignSelf: 'stretch',
    marginRight: '10px',
    width: '25px',
    opacity: 0.6,
  }}>
    <Box sx={{
      backgroundColor: 'primary.main',
      color: 'white',
      borderRadius: '100%',
      display: 'flex',
      width: '30px',
      height: '30px',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      fontSize: '12px',
    }}>{sectionNumber}</Box>
    <Box sx={{
      width: '1px',
      backgroundColor: 'primary.main',
      color: 'white',
      flexGrow: 1,
      marginTop: '10px',
    }}></Box>
  </Box>
</>);

export default FormSectionNumber;
