import {
  Box,
  Button, ThemeProvider, Tooltip, TooltipProps, createTheme, styled, tooltipClasses,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Star, Map, BarChart } from '@mui/icons-material';
import _ from 'lodash';
import ChipTooltip from './ChipTooltip';
import SlotTypes from '../../models/SlotTypes';
import QuestionSlot from '../../models/QuestionSlot';

type Props = {
    showText?: boolean,
    slot: QuestionSlot,
    score: number,
    path?: string,
    data?: any[],
    calcLastYear?: any,
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const scoreTypeChipTheme = createTheme({
  palette: {
    primary: {
      main: '#edda74',
    },
    secondary: {
      main: '#9F8772',
    },
    info: {
      main: '#917e91',
    },
    text: {
      primary: '#917e91',
    },
  },
});

const ScoreTypeChip: React.FC<Props> = ({
  showText,
  slot,
  score,
  calcLastYear,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (slot.compairedTo) {
      checkLastYearDataExists();
    } else {
      setBtnDisabled(false);
    }
  }, [slot.compairedTo]);

  const checkLastYearDataExists = async () => {
    if (slot.compairedTo) {
      if (await calcLastYear(slot.compairedTo)) {
        setBtnDisabled(false);
      } else {
        console.log('here');
        setBtnDisabled(true);
      }
    } else {
      console.log('not available');
    }
    return false;
  };

  const fetchColor = () : any => {
    switch (slot.type) {
      case SlotTypes.ETOILES:
      case SlotTypes.REPEATER:
        return 'info';
      case SlotTypes.GEOMATIQUE:
        return 'secondary';
      case SlotTypes.NIVEAU:
        return 'primary';
      default:
        return 'primary';
    }
  };

  const fetchText = () : string => {
    switch (slot.type) {
      case SlotTypes.ETOILES:
      case SlotTypes.REPEATER:
        return 'étoiles';
      case SlotTypes.GEOMATIQUE:
        return 'géomatique';
      case SlotTypes.NIVEAU:
        return 'niveau';
      default:
        return 'niveau';
    }
  };

  const fetchIcon = () : any => {
    switch (slot.type) {
      case SlotTypes.ETOILES:
      case SlotTypes.REPEATER:
        return <Star />;
      case SlotTypes.GEOMATIQUE:
        return <Map />;
      case SlotTypes.NIVEAU:
        return <BarChart />;
      default:
        return <BarChart />;
    }
  };

  const displayTooltipDetail = () : ReactNode => {
    if (showText) {
      return <></>;
    }

    switch (slot.type) {
      case SlotTypes.ETOILES:
      case SlotTypes.REPEATER:
        if (slot.scoreCalculatedLater) {
          return <ChipTooltip
            slot={slot}
            title="Cet indicateur sera calculé lorsque toutes les municipalités auront soumis leurs données."
          />;
        }
        return <ChipTooltip
          title={slot.help.tooltip.title}
          subtitle={slot.help.tooltip.subtitle}
          elements={slot.help.method}
          slot={slot}
        />;

      case SlotTypes.GEOMATIQUE:
        return <ChipTooltip
          slot={slot}
          title={slot.help.tooltip.title}
          subtitle={slot.help.tooltip.subtitle}
          elements={slot.help.method}
        />;
      default:
        return <ChipTooltip
          slot={slot}
          title={slot.help.tooltip.title}
          subtitle={slot.help.tooltip.subtitle}
          elements={slot.help.method}
        />;
    }
  };
  return (
    <ThemeProvider theme={scoreTypeChipTheme}>
      <NoMaxWidthTooltip title={!showText && displayTooltipDetail()}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}>
          <Button
            sx={{
              fontSize: 12,
              minWidth: 'unset',
              color: fetchColor(),
              fontWeight: 600,
              textTransform: 'none',
            }}
            startIcon={fetchIcon()}
            variant='contained'
            color={fetchColor()}
            size='large'
            disabled={btnDisabled}
          >
            {slot.scoreCalculatedLater ? 'résultats à venir' : score.toString()}
            {showText && ` ${fetchText()}` }
          </Button>
        </Box>
      </NoMaxWidthTooltip>
    </ThemeProvider>
  );
};

export default ScoreTypeChip;
