/* eslint-disable dot-notation */
/* eslint-disable quote-props */
import '../theme/css/global.css';
import {
  AppBar, Box, Button, IconButton, Link, ListItemIcon, MenuItem, MenuList, Popover, styled, Toolbar, Tooltip, Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import {
  useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBack } from '@mui/icons-material';
import {
  query, collection, where, getDocs,
} from 'firebase/firestore';
import Utils from '../utils/Utils';
import User from '../models/User';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import translator from '../theme/translator.json';

import ErrorService from '../services/ErrorService';
import Spinner from './Spinner';
import UserService from '../services/UserService';
import PromptCreateMunicipality from './PromptCreateMunicipality';
import Permissions from '../models/enums/Permissions';
import PromptCreateForm from './promptCreateForm';
import ExportService from '../services/ExportService';
import { db } from '../FirebaseConfig';
import FormState from '../models/enums/FormState';
import FormSection from '../pages/forms/pexbd_v2/models/FormSection';
import FormSubsection from '../pages/forms/pexbd_v2/models/FormSubsection';
import Question from '../pages/forms/pexbd_v2/models/Question';
import QuestionSlot from '../pages/forms/pexbd_v2/models/QuestionSlot';
import SlotTypes from '../pages/forms/pexbd_v2/models/SlotTypes';
import AlertUtil from '../utils/AlertUtil';

type Props = {
  onSidebarOpen: any,
  title: string,
  showBack?: boolean
};

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const Navbar: React.FC<Props> = ({ onSidebarOpen, title, showBack }) => {
  const user = useAppSelector((state) => state.user.user) as User;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const settingsRef = useRef(null);
  const createRef = useRef(null);

  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const [openCreatePopover, setOpenCreatePopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPromptCreateMunicipality, setShowPromptCreateMunicipality] = useState(false);
  const [showPromptCreateForm, setShowPromptCreateForm] = useState(false);

  const signout = async () => {
    try {
      setLoading(true);
      await UserService.logout();
      setLoading(false);
      navigate('/login');
    } catch (e: any) {
      ErrorService.handleError(e, dispatch);
      setLoading(false);
    }
  };

  const downloadFormCSV = async () => {
    try {
      const q = query(collection(db, 'Forms'), where('state', '==', FormState.DONE));
      const querySnapshot = await getDocs(q);

      const allForms: any[] = await Promise.all(querySnapshot.docs.map((formsDoc: any) => {
        const { formData } = formsDoc.data();

        const rows: any[] = [];

        formData.sections.forEach((section: FormSection) => {
          section.subsections.forEach((subsection: FormSubsection) => {
            subsection.questions.forEach((question: Question) => {
              console.log(question.id);
              console.log(question.number);
              console.log(question.number.toString());
              console.log('---------------------------------');
              question.slots.forEach((slot: QuestionSlot) => {
                const timeSinceUpdate = (parseInt(formData.anneeEtudiee, 10) - formData.anneeAdhesion) % question.frequency;
                const timeToNextUpdate = question.frequency - timeSinceUpdate;

                const methodString = slot.help.method.length > 0 ? slot.help.method?.map((obj: any) => {
                  const [key, value] = Object.entries(obj)[0];
                  return `${key} : ${value} pt`;
                }).join('; ') : '';

                let donnees = slot.help.method.length > 0 ? findKeyWithValue(slot.help.method, slot.score) : '';
                if (slot.type === SlotTypes.GEOMATIQUE || !donnees) {
                  // eslint-disable-next-line prefer-destructuring
                  donnees = slot.data[0];
                }

                const { score } = slot;

                let scoreTemp = score;
                if (slot.type === SlotTypes.GEOMATIQUE) {
                  scoreTemp = '';
                } else {
                  scoreTemp = parseFloat(score).toLocaleString('fr-FR');
                }

                let indicatorType = slot.type;
                if (indicatorType === SlotTypes.REPEATER) {
                  indicatorType = SlotTypes.ETOILES;
                }

                const row: any = {};
                row['Municipalité'] = formData.nomMunicipalite;
                row['Année Étudié'] = parseInt(formData.anneeEtudiee, 10);
                row['Thématique'] = section.title;
                row['ID'] = `${question.number.toString()}\u00A0`;
                row['Indicateur'] = question.title;
                row['Type d\'indicateur'] = `${question.type}-${indicatorType}`;
                row['Données'] = donnees;
                row['Unité'] = slot.unit;
                row['Pointage'] = scoreTemp;
                row['Pointage MAX'] = slot.maxScore;
                row['Cibles des EM associé'] = question.idEM;
                row['Commentaires et sources des données'] = question.comments;
                row['Fréquence'] = question.frequency;
                row['Prochaine mise à jour'] = parseInt(formData.anneeEtudiee, 10) + timeToNextUpdate;
                row['Année adhésion'] = formData.anneeAdhesion;
                row['Méthode de pointage'] = methodString;

                rows.push(row);
              });
            });
          });
        });

        return rows;
      }));

      const headers = [
        'Municipalité',
        'Année Étudié',
        'Thématique',
        'ID',
        'Indicateur',
        'Type d\'indicateur',
        'Données',
        'Unité',
        'Pointage',
        'Pointage MAX',
        'Cibles des EM associé',
        'Commentaires et sources des données',
        'Fréquence',
        'Prochaine mise à jour',
        'Année adhésion',
        'Méthode de pointage',
      ];
      const combinedRows = [].concat(...allForms);
      const exportedDate = new Date().toLocaleDateString('fr-CA');
      await ExportService.exportFormCSV(headers, combinedRows, `export-all-forms-${exportedDate}`);
    } catch (e) {
      console.error(e);
      AlertUtil.createErrorAlert('Impossible d\'exporter les formulaires', dispatch);
    }
  };

  const findKeyWithValue = (array: any[], targetValue: any) => {
    if (array.length === 0) return null;
    const foundObject = array?.find((obj) => Object.values(obj).includes(parseFloat(targetValue)));
    return foundObject ? Object.keys(foundObject)[0] : null;
  };
  return (
    <>
      <NavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: 'calc(100% - 280px)',
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box>
            {showBack && <Link
              color="secondary"
              onClick={() => navigate(-1)}
              sx={{
                textDecoration: 'none',
                fontSize: '12px',
                cursor: 'pointer',
              }}><ArrowBack sx={{ fontSize: '10px' }} />retour</Link>}
            <Typography variant="h5" color="black">{title}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          {
            user?.permission === (Permissions.ADMIN || Permissions.SUPER_ADMIN)
              && <Box sx={{ display: 'flex', gap: '15px' }}>
                <Tooltip title="Exporter les formulaires terminés">
                  <Button color="error" variant="outlined" onClick={() => downloadFormCSV()}>
                  Exporter
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => setOpenCreatePopover(true)}
                  ref={createRef}
                  variant="contained"
                  startIcon={<AddIcon/>}
                >
                  {Utils.getTranslation(translator.components.appBar.add)}
                </Button>
              </Box>
          }
          <IconButton
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
            sx={{ ml: 1, mr: '10px' }}
          >
            <MoreVertIcon fontSize='medium'/>
          </IconButton>
        </Toolbar>
      </NavbarRoot>
      <Popover
        anchorEl={createRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={() => setOpenCreatePopover(false)}
        open={openCreatePopover}
        PaperProps={{
          sx: { width: '300px' },
        }}
      >
        <MenuList>
          <MenuItem onClick={() => setShowPromptCreateMunicipality(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            {Utils.getTranslation(translator.components.appBar.createPopover.municipality)}
          </MenuItem>
          <MenuItem onClick={() => setShowPromptCreateForm(true)}>
            <ListItemIcon>
              <VerticalSplitIcon />
            </ListItemIcon>
            {Utils.getTranslation(translator.components.appBar.createPopover.form)}
          </MenuItem>
        </MenuList>
      </Popover>
      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={() => setOpenAccountPopover(false)}
        open={openAccountPopover}
        PaperProps={{
          sx: { width: '300px' },
        }}
      >
        <Box
          sx={{
            py: 1.5,
            px: 2,
          }}
        >
          <Typography variant="overline">
            {
              user?.permission === Permissions.MUNICIPALITY
                ? Utils.getTranslation(translator.components.appBar.accountPopover.title.municipality)
                : Utils.getTranslation(translator.components.appBar.accountPopover.title.admin)
            }

          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
          >
            {
              user?.permission === Permissions.MUNICIPALITY
                ? user?.name
                : user?.email
            }
          </Typography>
        </Box>
        <MenuList
          sx={{
            '& > *': {
              '&:first-of-type': {
                borderTopColor: 'divider',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
              },
              padding: '12px 16px',
            },
          }}
        >
          <MenuItem onClick={() => navigate('/settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            {Utils.getTranslation(translator.components.appBar.accountPopover.settings)}
          </MenuItem>
          <MenuItem onClick={() => signout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            {Utils.getTranslation(translator.components.appBar.accountPopover.signOut)}
          </MenuItem>
        </MenuList>
      </Popover>
      <PromptCreateMunicipality setShow={setShowPromptCreateMunicipality} show={showPromptCreateMunicipality} />
      <PromptCreateForm setShow={setShowPromptCreateForm} show={showPromptCreateForm} />
      <Spinner show={loading}/>
    </>
  );
};

export default Navbar;
