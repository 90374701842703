/* eslint-disable max-len */
const EM = [
  {
    id: 1,
    value: 'Cible #1 - Intégrer la biodiversité à la planification territoriale et réglementaire.',
  },
  {
    id: 2,
    value: 'Cible #2 - Rétablir et restaurer les écosystèmes et leur connectivité.',
  },
  {
    id: 3,
    value: 'Cible #3 - Conserver les milieux naturels existants grâce à des systèmes de zones protégées et d\'autres mesures efficaces et équitables.',
  },
  {
    id: 4,
    value: 'Cible #4 - Assurer la conservation et le rétablissement des espèces vulnérables sauvages et domestiques et gérer efficacement leurs interactions avec l\'humain.',
  },
  {
    id: 5,
    value: 'Cible #5 - Contrôler ou éradiquer les espèces exotiques envahissantes afin d\'éliminer ou de réduire leurs impacts.',
  },
  {
    id: 6,
    value: 'Cible #6 - Réduire la pollution de toutes les sources à des niveaux qui ne nuisent pas à la biodiversité aux fonctions des écosystèmes et à la santé humaine.',
  },
  {
    id: 7,
    value: 'Cible #7 - Viser l\'élimination des déchets plastiques.',
  },
  {
    id: 8,
    value: 'Cible #8 - Viser la réduction de l\'utilisation des pesticides du deux tiers au moins.',
  },
  {
    id: 9,
    value: 'Cible #9 - Contribuer aux mesures d\'atténuation et d\'adaptation aux changements climatiques grâce à des approches fondées sur les écosystèmes.',
  },
  {
    id: 10,
    value: 'Cible #10 - Viser à ce que les zones d\'agriculture, d\'aquaculture et de sylviculture urbaines soient accessibles, gérées durablement et contribuent à la sécurité alimentaire.',
  },
  {
    id: 11,
    value: 'Cible #11 - Prioriser les solutions basées sur la nature en matière de protection contre les aléas et événements climatiques extrêmes et de régulation de la qualité de l\'air et de l\'eau.',
  },
  {
    id: 12,
    value: 'Cible #12 - Augmenter la superficie des espaces verts et bleus et améliorer l\'accès équitable à ces espaces.',
  },
  {
    id: 13,
    value: 'Cible #13- Intégrer la biodiversité dans les cadres de gouvernance et les politiques publiques et accroître les ressources financières allouées à sa conservation et à sa gestion durable.',
  },
  {
    id: 14,
    value: 'Cible #14 - Contribuer par l\'éducation et la participation citoyenne à ce que les populations et les entreprises soient encouragées à faire des choix responsables envers la biodiversité et aient les moyens et les connaissances pour le faire.',
  },
  {
    id: 15,
    value: 'Cible #15 - Assurer la participation équitable et effective des peuples autochtones et des communautés locales à la prise de décisions et au processus d\'acquisition et de transmission des connaissances.',
  },
];

export default EM;
