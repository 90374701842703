/* eslint-disable max-len */
import {
  Box, Checkbox, FormControlLabel, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useState } from 'react';
import OptionalTooltip from '../components/chips/OptionalTooltip';
import { PEXBDFormStepContent } from '../components/StyledComponents';
import SlotTypes from '../models/SlotTypes';
import ScoreTypeChip from '../components/chips/ScoreTypeChip';
import QuestionTypeChip from '../components/chips/QuestionTypeChip';
import QuestionType from '../models/enums/QuestionType';
import EngagementChip from '../components/chips/EngagementChip';
import PrivacyPoliciesDialog from '../components/dialogs/PrivacyPoliciesDialog';
import SlotUnit from '../models/enums/SlotUnit';
import { defaultEtoile, defaultGeomatique, defaultNiveau } from '../models/SlotHelp.spec';

type Props = {
  path:string,
  programId:string,
}

const Instructions: React.FC<Props> = ({ programId, path }) => {
  const { values, setFieldValue } : { values:any, setFieldValue:any} = useFormikContext();
  const [showPolicies, setShowPolicies] = useState(false);

  const handleConditionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(path, event.target.checked);
  };

  return (
    <PEXBDFormStepContent>
      <Box sx={{ mt: '15px' }}>
        <Typography variant="h4" fontWeight={600}>Type de pointages</Typography>
        <Typography variant="body1">Survoler les pastilles pour afficher les détails sur la méthode de pointage.</Typography>
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px', flexWrap: 'wrap', mt: '10px',
        }}>
          <ScoreTypeChip showText={true} score={0} slot={{
            type: SlotTypes.NIVEAU,
            items: [''],
            data: ['0'],
            scoreCalculatedLater: false,
            optional: false,
            score: '',
            scoringFct: (data: string[]) => parseFloat(data[0]),
            maxScore: 1,
            help: defaultNiveau,
            unit: SlotUnit.PERCENTAGE,
          }} />
          <ScoreTypeChip showText={true} score={0} slot={{
            type: SlotTypes.ETOILES,
            items: [''],
            data: ['0'],
            scoreCalculatedLater: false,
            optional: false,
            score: '',
            scoringFct: (data: string[]) => parseFloat(data[0]),
            maxScore: 1,
            help: defaultEtoile,
            unit: SlotUnit.PERCENTAGE,
          }} />
          <ScoreTypeChip showText={true} score={0} slot={{
            type: SlotTypes.GEOMATIQUE,
            items: [''],
            data: ['0'],
            scoreCalculatedLater: false,
            optional: false,
            score: '',
            scoringFct: (data: string[]) => parseFloat(data[0]),
            maxScore: 1,
            help: defaultGeomatique,
            unit: SlotUnit.PERCENTAGE,
          }} />
        </Box>
      </Box>
      <Box sx={{ mt: '25px' }}>
        <Typography variant="h4" fontWeight={600}>Instructions Générales</Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px', flexWrap: 'wrap', mt: '10px',
        }}>
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: '7px',
          }}>
            <QuestionTypeChip showText={true} showTooltip={false} type={QuestionType.ACTIVITY} />
            <Box sx={{
              display: 'flex', alignItems: 'center', gap: '4px',
            }}>
              <Typography variant="body1">-</Typography>
              <Typography variant="body1">Évalue les actions prises par les municipalités avec le pointage niveau.</Typography>

            </Box>
          </Box>
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: '7px',
          }}>
            <QuestionTypeChip showText={true} showTooltip={false} type={QuestionType.INDICATOR} />
            <Box sx={{
              display: 'flex', alignItems: 'center', gap: '4px',
            }}>
              <Typography variant="body1">-</Typography>
              <Typography variant="body1">Évalue l'impact des activités entreprisent par les municipalités avec le pointage étoile.</Typography>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: '7px',
          }}>
            <EngagementChip emTitle="L'organisation participe à des programme de ruelles vertes ou bleues-vertes" emNumber={12}/>
            <Box sx={{
              display: 'flex', alignItems: 'center', gap: '4px',
            }}>
              <Typography variant="body1">-</Typography>
              <Typography variant="body1">Indique la cible associé aux Engagements de Montréal pour l'activité ou l'indicateur</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {programId === 'PlhU9DxMiv4loyvKLkHE' && <Box sx={{
        display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 4,
      }}>
        <div style={{ minWidth: '115px' }}>
          <OptionalTooltip />
        </div>
        <div>
          <Typography variant='body1'>
        Certains sous-indicateurs sont facultatifs, pour s'adapter à la réalité des plus petites municipalités.
          </Typography>
          <Typography variant='body2'>
         Vous avez la possibilité de les remplir s'ils sont pertinents pour vous, le total des points s'ajustera automatiquement.
          </Typography>
        </div>
      </Box>}
      <Box sx={{ mt: '15px' }}>
        <Typography variant="h4" fontWeight={600}>Politique de protection des données personnelle</Typography>
        <Box sx={{
          display: 'flex', alignItems: 'center', gap: '0px',
        }}>
          <FormControlLabel
            name={path}
            onChange={(e: any) => handleConditionChange(e)}
            control={<Checkbox checked={values.acceptedConditions} />}
            sx={{
              mr: '4px',
            }}
            label={'J\'ai lu et accepte'} />
          <Typography
            onClick={() => setShowPolicies(true)}
            sx={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            variant="body1">la politique de protection des données personnelle </Typography><span style={{ color: 'red', marginLeft: '3px' }}> *</span>
        </Box>
      </Box>
      <PrivacyPoliciesDialog setShow={setShowPolicies} show={showPolicies}/>
    </PEXBDFormStepContent>
  );
};

export default Instructions;
