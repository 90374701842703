import * as XLSX from 'xlsx';

class ExportService {
  static exportFormCSV = async (headers: string[], data: any[], fileName: string) => {
    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();

    // Add a new row for headers
    const worksheetData = [headers];

    // Append each row of data
    data.forEach((row) => {
      worksheetData.push(headers.map((header) => row[header] || ''));
    });

    // Create a worksheet with the data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, ws, 'Data');

    // Write the CSV file
    XLSX.writeFile(workbook, `${fileName}.csv`);
  };
}

export default ExportService;
