import '../theme/css/global.css';
import {
  ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
    href: string,
    icon: any,
    title: string,
    small?:boolean
};

const NavItem: React.FC<Props> = ({
  href, icon, title, small,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const active = href ? (location.pathname === href) : false;

  const iconStyles = (small) ? { minWidth: 'unset' } : {};

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          mb: 0.5,
          py: 0,
          px: 2,
        }}
      >
        <ListItemButton
          selected={active}
          onClick={() => navigate(href)}
          sx={{
            borderRadius: 1,
          }}
        >
          <ListItemIcon sx={iconStyles}>
            {icon}
          </ListItemIcon>
          {!small && <ListItemText color="primary.contrastText" primary={<Typography variant="body2" color="#000">{title}</Typography>} />}
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default NavItem;
