import {
  Typography,
} from '@mui/material';

type Props = {
    title:string,
    subtitle?: string,
    elements?: string[],
}

const TooltipDetail: React.FC<Props> = (props) => (<>
  <Typography sx={{
    px: 2, pb: 1, fontSize: 14, fontWeight: 600,
  }}>{props.title}</Typography>
  {props.subtitle && <Typography sx={{ px: 2, fontSize: 14, pb: 1 }}>{props.subtitle}</Typography>}
  {props.elements && props.elements.map((el, i) => <Typography key={i} sx={{ px: 2, fontSize: 14 }}>{el}</Typography>)}
</>);

export default TooltipDetail;
