import {
  Box, Button, Card, CardContent, CardHeader, Container, Divider,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { useAppDispatch } from '../../../store/Hooks';
import translator from '../../../theme/translator.json';
import Utils from '../../../utils/Utils';
import ErrorService from '../../../services/ErrorService';
import Spinner from '../../../components/Spinner';
import UserService from '../../../services/UserService';
import { auth } from '../../../FirebaseConfig';
import AlertUtils from '../../../utils/AlertUtil';
import PromptForCredentials from '../../../components/PromptForCredentials';

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [showPromptCredential, setShowPromptCredential] = useState(false);

  const handlePromptCredentialAlert = (show: boolean) => {
    if (show) setShowPromptCredential(true);
    else setShowPromptCredential(false);
  };

  const changePassword = async (values: any) => {
    try {
      setLoading(true);
      const { password } = values;
      if (auth.currentUser) {
        await UserService.updatePassword(auth.currentUser, password);
      }

      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      // Change password needs reauthantification if credentials have timed out
      if (e.code === 'auth/requires-recent-login') {
        setLoading(false);
        setShowPromptCredential(true);
      } else {
        setLoading(false);
        ErrorService.handleError(e, dispatch);
      }
    }
  };
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          marginTop: '20px',
        }}
      >
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={yup.object({
            password: yup.string().min(6).required(Utils.getTranslation(translator.formMessages.requiredField)),
            passwordConfirmation: yup.string()
              .required(Utils.getTranslation(translator.formMessages.requiredField))
              .oneOf([yup.ref('password'), null], Utils.getTranslation(translator.formMessages.passwordsMustMatch)),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            changePassword(values);
            setSubmitting(false);
            resetForm();
          }}
        >
          {(formikProps) => (
            <Form>
              <Card>
                <CardHeader
                  title={Utils.getTranslation(translator.pages.settings.changePassword.title)}
                />
                <Divider />
                <CardContent>
                  <Field
                    component={TextField}
                    name="password"
                    type="password"
                    label={Utils.getTranslation(translator.pages.settings.changePassword.inputs.password)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="passwordConfirmation"
                    type="password"
                    label={Utils.getTranslation(translator.pages.settings.changePassword.inputs.passwordConfirmation)}
                    margin='normal'
                    fullWidth
                  />
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                  }}
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {Utils.getTranslation(translator.pages.settings.changePassword.submit)}
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Container>
      <Spinner show={loading} />
      {
        showPromptCredential
        && <PromptForCredentials show={showPromptCredential} setShow={handlePromptCredentialAlert}/>
      }
    </>

  );
};

export default ChangePassword;
