import {
  Box, styled, Tab, Tabs,
} from '@mui/material';

import { Fragment, useEffect, useState } from 'react';
import FormState from '../models/enums/FormState';
import { useAppSelector } from '../store/Hooks';
import FormListCard from './FormListCard';

interface PropsContainer {
  onClick?: any
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const FormCardsWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const MunicipalityFormList: React.FC<PropsContainer> = ({ onClick }) => {
  const [rows, setRows] = useState([]) as any[];
  const [tabValue, setTabValue] = useState(0);
  const { forms } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (forms) {
      const formsRowsTemp: any[] = [];
      forms.forEach((form:any) => {
        formsRowsTemp.push(
          {
            ...form,
          },
        );
      });
      setRows(formsRowsTemp);
    }
  }, [forms]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab label="En cours" />
          <Tab label="Analyse terminée" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FormCardsWrapper>
            {rows.map((row:any) => (
              <Fragment key={row.id}>
                {row.state !== FormState.DONE
                && <FormListCard
                  title={row.programName}
                  year={row.studiedYear}
                  linkTitle="remplir"
                  linkUrl={`/form/${row.id}`}
                  formState={row.state}
                />}
              </Fragment>
            ))}
          </FormCardsWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {rows.map((row:any) => (
            <Fragment key={row.id}>
              {row.state === FormState.DONE && <Box>
                <FormListCard
                  title={row.programName}
                  year={row.studiedYear}
                  linkTitle="consulter"
                  linkUrl={`/form/${row.id}`}
                  formState={row.state}
                />
              </Box>}
            </Fragment>
          ))}
        </TabPanel>
      </Box>
    </>);
};

export default MunicipalityFormList;
