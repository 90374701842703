import {
  ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';

type Props = {
    fieldPath:string,
    orientation: 'vertical' | 'horizontal',
    labels?: string[],
}

const NiveauToggleGroup: React.FC<Props> = (props) => {
  const {
    values, handleChange,
  } = useFormikContext();
  return (<>
    <ToggleButtonGroup
      orientation={props.orientation}
      exclusive
      value={_.get(values, props.fieldPath)}
      onChange={handleChange}
      disabled={_.get(values, 'isDisabled')}
    >
      <ToggleButton id={props.fieldPath} value='0' color="primary" size="small" sx={{
        fontWeight: 600, textTransform: 'none', fontSize: '15px',
      }}>
        {(props.labels && props.labels[0]) ? props.labels[0] : 'non réalisé'}
      </ToggleButton>
      <ToggleButton id={props.fieldPath} value='0.5' color="primary" size="small" sx={{
        fontWeight: 600, textTransform: 'none', fontSize: '15px',
      }}>
        {(props.labels && props.labels[1]) ? props.labels[1] : 'en cours'}
      </ToggleButton>
      <ToggleButton id={props.fieldPath} value='1' color="primary" size="small" sx={{
        fontWeight: 600, textTransform: 'none', fontSize: '15px',
      }}>
        {(props.labels && props.labels[2]) ? props.labels[2] : 'réalisé'}
      </ToggleButton>
    </ToggleButtonGroup>
  </>);
};

export default NiveauToggleGroup;
