import {
  Typography,
} from '@mui/material';
import QuestionSlot from '../../models/QuestionSlot';

type Props = {
    title:string,
    subtitle?: string,
    elements?: any[],
    slot?: QuestionSlot
}

const ChipTooltip: React.FC<Props> = (props) => (
  <>
    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
      {props.title}
    </Typography>
    {props.subtitle && (
      <Typography sx={{ fontSize: 14, pb: 1 }}>
        {props.subtitle}
      </Typography>
    )}
    {props.elements && props.elements.map((el, i) => (
      <div key={i}>
        {Object.entries(el).map(([key, value], idx) => (
          <Typography key={idx} sx={{ fontSize: 14 }}>
            {key}: {value?.toString()}pt
          </Typography>
        ))}
      </div>
    ))}
  </>
);

export default ChipTooltip;
