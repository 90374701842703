import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { Clear, Add } from '@mui/icons-material';

type Props = {
  fieldPath:string,
  repeaterFields: any,
}

const Repeater: React.FC<Props> = (props) => {
  const {
    values, handleChange,
  } = useFormikContext();

  const addRow = (push: { (obj: any): void; (arg0: { [k: string]: any; }): void; }) => {
    let initialRepeaterValues: { [k: string]: any; } | null = null;
    const initialRepeaterValuesTemp : any[] = [];
    props.repeaterFields.forEach((field:any) => {
      initialRepeaterValuesTemp.push([field.slug, '']);
    });
    initialRepeaterValues = Object.fromEntries(initialRepeaterValuesTemp);
    push(initialRepeaterValues);
  };

  const getTotalAmount = () : number => {
    let total = 0;
    _.get(values, `${props.fieldPath}.data`).forEach((v:any) => {
      total += parseFloat(v.amount) || 0;
    });
    return Math.round(total * 100) / 100;
  };

  return (<Box
    sx={{
      width: '100%',
    }}
  >
    <FieldArray name={`${props.fieldPath}.data`} validateOnChange={false}>
      {({ insert, remove, push }) => (
        <Box>
          <Box sx={{
            display: 'flex', borderBottom: '1px solid black', justifyContent: 'space-between',
          }}>
            {
              props.repeaterFields.map((field:any, index: number) => <Typography variant="h5" key={index} sx={{
                width: field.width,
                pr: '10px',
              }}>
                {field.label}
              </Typography>)
            }
            <Box sx={{ width: '50px' }}></Box>

          </Box>
          {/* TODO : optimize speed of this */}
          {_.get(values, `${props.fieldPath}.data`)
           && _.get(values, `${props.fieldPath}.data`).length > 0
                  && _.get(values, `${props.fieldPath}.data`).map((row:any, index:number) => (
                    <Box key={index} sx={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', my: 1,
                    }}>
                      {
                        props.repeaterFields.map((field:any, i: number) => <Box key={i} sx={{
                          width: field.width,
                          pr: '10px',
                        }}>
                          <TextField
                            name={`${props.fieldPath}.data.${index}.${field.slug}`}
                            value={_.get(values, `${props.fieldPath}.data.${index}.${field.slug}`)}
                            onChange={handleChange}
                            label={null}
                            variant="standard"
                            size='small'
                            inputProps={{
                              style: { fontSize: '0.9rem' },
                            }}
                            fullWidth
                            multiline={field.type !== 'number'}
                            type={field.type || 'text'}
                            disabled={_.get(values, 'isDisabled')}
                          />
                        </Box>)
                      }
                      {!_.get(values, 'isDisabled')
                      && <Box onClick={() => remove(index)}
                        sx={{
                          width: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Clear fontSize='small' color="primary" />
                      </Box>}
                    </Box>
                  ))}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 3 }}>
            <Box sx={{ flexGrow: 1, mt: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Montant total alloué: <span style={{ fontWeight: '400' }}>{getTotalAmount()} $CAD</span>
              </Typography>
              {_.get(values, 'population') && <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Montant total alloué par habitant: <span style={{ fontWeight: '400' }}>{(getTotalAmount() / _.get(values, 'population')).toFixed(2)} $CAD/habitant</span>
              </Typography>}
            </Box>
            <Button
              onClick={() => addRow(push)}
              variant="text"
              size="small"
              endIcon={<Add />}
              sx={{ textTransform: 'none', float: 'right' }}
              disabled={_.get(values, 'isDisabled')}
            >
                Ajouter
            </Button>
          </Box>
        </Box>
      )}
    </FieldArray>
  </Box>);
};

export default Repeater;
