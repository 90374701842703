import {
  Box, Button, Popover,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { Star, Map, BarChart } from '@mui/icons-material';
import TooltipDetail from './TooltipDetail';
import SlotTypes from '../models/SlotTypes';

type Props = {
    value?:string,
    type: SlotTypes,
    content?: JSX.Element,
}

const fetchColor = (type : SlotTypes) : string => {
  switch (type) {
    case SlotTypes.ETOILES:
    case SlotTypes.REPEATER:
      return '#F49D1A';
    case SlotTypes.GEOMATIQUE:
      return '#9F8772';
    default:
      return '#00AFFF';
  }
};

const fetchIcon = (type : SlotTypes) : ReactNode => {
  switch (type) {
    case SlotTypes.ETOILES:
    case SlotTypes.REPEATER:
      return <Star />;
    case SlotTypes.GEOMATIQUE:
      return <Map style={{ fill: '#000' }}/>;
    default:
      return <BarChart />;
  }
};

const displayTooltipDetail = (type : SlotTypes) : ReactNode => {
  switch (type) {
    case SlotTypes.ETOILES:
    case SlotTypes.REPEATER:
      return <TooltipDetail
        title="Indicateur étoile"
        subtitle="Basé sur la moyenne des autres membres:"
        elements={[
          'Égal à la moy. ou + : 4 pts',
          '75% - 99% : 3 pts',
          '50% - 74% : 2 pts',
          '25% - 49% : 1 pts',
          '1% - 24% : 0 pts',
        ]}
      />;
    case SlotTypes.GEOMATIQUE:
      return <TooltipDetail
        title="Indicateur géomatique"
        subtitle="Pas de pointage, outil de diagnostic"
      />;
    default:
      return <TooltipDetail
        title="Indicateur niveau"
        elements={[
          'réalisé : 1 pt',
          'en cours : 0.5 pt',
          'non réalisé : 0 pt',
        ]}
      />;
  }
};

const IndicateurTooltip: React.FC<Props> = (props) => {
  const iconRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  return (<Box sx={{
    display: 'flex',
    justifyContent: 'flex-end',
  }}>
    <Button
      sx={{
        fontSize: 12, minWidth: 'unset', color: fetchColor(props.type), fontWeight: 600,
      }}
      ref={iconRef}
      variant='contained'
      endIcon={fetchIcon(props.type)}
      onClick={() => setOpenPopover(true)}>
      {props.value && props.value.toString()}
    </Button>
    <Popover
      anchorEl={iconRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      onClose={() => setOpenPopover(false)}
      open={openPopover}
    >
      <Box sx={{ py: 1, textAlign: 'center' }}>
        {props.content
          ? props.content
          : displayTooltipDetail(props.type)
        }
      </Box>
    </Popover>
  </Box>);
};

export default IndicateurTooltip;
