import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { PEXBDFormStepContent } from '../components/StyledComponents';
import FormSection from '../sections/FormSection';

const GeneratedFormStep: React.FC<{path:string, spec: any }> = ({ path, spec }) => {
  const stepSpec = _.get(spec, path);
  const topOfSectionRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (topOfSectionRef.current) {
      topOfSectionRef.current.scrollIntoView({ block: 'start' });
    }
  }, [topOfSectionRef, stepSpec]);

  return (
    <PEXBDFormStepContent>
      <Box ref={topOfSectionRef} sx={{ height: '40px', width: '100%' }}></Box>
      {stepSpec && stepSpec.questions.map((question:any, index: number) => <Box key={index}>
        <FormSection
          question={question}
          sectionPath={`${path}.questions`}
          fieldPath={`${path}.questions[${index}]`}
        />
      </Box>)}
    </PEXBDFormStepContent>
  );
};

export default GeneratedFormStep;
