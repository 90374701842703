import {
  Box, IconButton, InputAdornment, TextField, Tooltip, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { Fragment } from 'react';
import { Calculate } from '@mui/icons-material';
import { PEXBDFormSectionContainer } from '../components/StyledComponents';
import FormSectionNumber from '../components/FormSectionNumber';
import SlotTypes from '../models/SlotTypes';
import NiveauToggleGroup from '../components/NiveauToggleGroup';
import IndicateurTooltip from '../components/IndicateurTooltip';
import Repeater from '../components/Repeater';
import OptionalTooltip from '../components/OptionalTooltip';
import UpdateFrequencyChip from '../components/UpdateFrequencyChip';

type Props = {
    sectionNumber: number,
    sectionTitle: string,
    sectionDescription: string[],
    fieldPath: string,
    sectionsPath: string,
    slots: any[],
    frequency?: number,
};

const FormSection: React.FC<Props> = (props) => {
  const {
    values, handleChange, setFieldValue,
  } = useFormikContext();

  return (
    <PEXBDFormSectionContainer>
      <FormSectionNumber sectionNumber={props.sectionNumber} />
      <Box sx={{ flexGrow: 1, mb: 3 }}>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{
              mb: '5px', height: 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}>
              <Typography variant="h5" sx={{ fontSize: '0.95rem', mr: 2 }}>{props.sectionTitle}</Typography>
              <UpdateFrequencyChip
                isDisabled={_.get(values, 'isDisabled')}
                frequency={props.frequency}
                adhesionYear={parseInt(_.get(values, 'anneeAdhesion'), 10)}
                studiedYear={parseInt(_.get(values, 'anneeEtudiee'), 10)}
                fieldPath={props.fieldPath}
                programId={_.get(values, 'programId')}
                municipalityId={_.get(values, 'municipalityId')}
              />
            </Box>
            {props.sectionDescription && props.sectionDescription.map((description:string, index:number) => (
              <Box key={index} sx={{ width: '80%', mt: 2 }}>
                <Typography variant='body1' key={index} sx={{ mb: 1, fontSize: (index) ? '0.9rem' : '0.95rem' }}>{description}</Typography>
              </Box>
            ))}

            {props.slots && props.slots.map((slot:any, index:number) => {
              switch (slot.type) {
                case SlotTypes.NIVEAU:
                  return (<Fragment key={index}>
                    <Box sx={{
                      display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1,
                    }}>
                      {!slot.hideToolTip && slot.optional && <OptionalTooltip />}
                      {!slot.hideToolTip && <IndicateurTooltip
                        value={slot.scoringFct(_.get(values, `${props.fieldPath}.slots[${index}].data`))}
                        type={slot.type}
                        content={slot.toolTipContent}
                      />}
                    </Box>
                    {slot.beforeText && <Typography sx={{ fontSize: '1em' }}>{slot.beforeText}</Typography>}
                    {slot.items.map((item:string, i:number) => (<Box key={i} sx={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2,
                    }}>
                      <Typography sx={{ fontSize: '0.90em', pl: (slot.beforeText) ? 2 : 0 }}>{item}</Typography>
                      <Box sx={{ flexShrink: 0, ml: 4 }}>
                        <NiveauToggleGroup
                          orientation="horizontal"
                          fieldPath={`${props.fieldPath}.slots[${index}].data[${i}]`}
                          labels={slot.labels}
                        />
                      </Box>
                    </Box>))
                    }</Fragment>);
                case SlotTypes.REPEATER:
                  return (<Fragment key={index}>
                    {!slot.hideToolTip && <IndicateurTooltip
                      type={slot.type}
                      content={slot.toolTipContent}
                    />}
                    <Repeater fieldPath={`${props.fieldPath}.slots[${index}]`} repeaterFields={slot.repeaterFields}/>
                  </Fragment>
                  );
                case SlotTypes.GEOMATIQUE:
                  return (<Fragment key={index}>
                    {!slot.hideToolTip && <IndicateurTooltip
                      type={slot.type}
                    />}
                    {slot.items.map((item:string, i:number) => (<Box key={i} sx={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2,
                    }}>
                      <Typography sx={{ fontSize: '0.90em' }}>{item}</Typography>
                      <Box sx={{ minWidth: 223, ml: 4 }}>
                        <TextField
                          name={`${props.fieldPath}.slots[${index}].data[${i}]`}
                          value={_.get(values, `${props.fieldPath}.slots[${index}].data[${i}]`)}
                          onChange={handleChange}
                          label="Donnée"
                          fullWidth
                          size='small'
                          disabled={_.get(values, 'isDisabled')}
                          InputProps={slot.autofill && {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Calculer la valeur">
                                  <IconButton
                                    aria-label="Calculer la valeur"
                                    onClick={() => setFieldValue(`${props.fieldPath}.slots[${index}].data[${i}]`, slot.autofill(values, props.sectionsPath).toString())}
                                    onMouseDown={(e: any) => e.preventDefault()}
                                    color="primary"
                                  >
                                    <Calculate/>
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>))
                    }</Fragment>);
                case SlotTypes.ETOILES:
                  return (<Fragment key={index}>
                    <Box sx={{
                      display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1,
                    }}>
                      {!slot.hideToolTip && slot.optional && <OptionalTooltip />}
                      {!slot.hideToolTip && <IndicateurTooltip
                        value={slot.scoringFct(_.get(values, `${props.fieldPath}.slots[${index}].data`))}
                        type={slot.type}
                        content={slot.toolTipContent}
                      />}
                    </Box>
                    {slot.beforeText && <Typography sx={{ fontSize: '1em' }}>{slot.beforeText}</Typography>}
                    <Box sx={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2,
                    }}>
                      <Typography sx={{ fontSize: '0.90em', pl: (slot.beforeText) ? 2 : 0 }}>{slot.item}</Typography>
                      <Box sx={{ minWidth: 223, ml: 4 }}>
                        <TextField
                          name={`${props.fieldPath}.slots[${index}].data`}
                          value={_.get(values, `${props.fieldPath}.slots[${index}].data`)}
                          onChange={handleChange}
                          label="Donnée"
                          fullWidth
                          size='small'
                          disabled={_.get(values, 'isDisabled')}
                          InputProps={slot.autofill && {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Calculer la valeur">
                                  <IconButton
                                    aria-label="Calculer la valeur"
                                    onClick={() => setFieldValue(`${props.fieldPath}.slots[${index}].data`, slot.autofill(values, props.sectionsPath).toString())}
                                    onMouseDown={(e: any) => e.preventDefault()}
                                    color="primary"
                                  >
                                    <Calculate/>
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Fragment>);
                case SlotTypes.INFO:
                  return (<Box key={index} sx={{ mt: 6, fontSize: '1em', textDecoration: 'underline' }}>{slot.text}</Box>);
                default:
                  return (<Fragment key={index}></Fragment>);
              }
            })}
            <TextField
              name={`${props.fieldPath}.comments`}
              value={_.get(values, `${props.fieldPath}.comments`)}
              onChange={handleChange}
              label="Commentaires et source des données"
              multiline
              rows={2}
              sx={{ marginTop: '30px' }}
              fullWidth
              disabled={_.get(values, 'isDisabled')}
            />
          </Box>
        </Box>
      </Box>
    </PEXBDFormSectionContainer>
  );
};

export default FormSection;
