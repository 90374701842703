import {
  Chip, ThemeProvider, Tooltip, createTheme,
} from '@mui/material';
import ChipTooltip from './ChipTooltip';

const OptionalTooltipTheme = createTheme({
  palette: {
    primary: {
      main: '#D77234',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: '9px',
        },
      },
    },
  },
});

const OptionalTooltip: React.FC = () => (
  <ThemeProvider theme={OptionalTooltipTheme}>
    <Tooltip title={
      ChipTooltip({
        title: 'Question optionnelle lorsque la population est inférieure à 25 000 habitants',
        elements: [],
      })}
    >
      <Chip label="Facultative" size='small' color="primary" sx={{ textTransform: 'none', fontWeight: 600 }} />
    </Tooltip>
  </ThemeProvider>

);

export default OptionalTooltip;
