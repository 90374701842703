/* eslint-disable quote-props */
import SlotHelp from './SlotHelp';

const defaultNiveau: SlotHelp = {
  tooltip: {
    title: 'Pointage niveau',
    subtitle: '',
  },
  method: [
    { 'non réalisé': 0 },
    { 'en cours': 0.5 },
    { 'réalisé': 1 },
  ],
};

const niveauDeclaration: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { 'toutes déclarées': 1 },
    { 'partiellement': 0.5 },
    { 'non déclarées': 0 },
  ],
};

const niveauActions: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '4 actions et +': 1 },
    { '1 à 3 actions': 0.5 },
    { '0 action réalisée': 0 },
  ],
};

const defaultGeomatique: SlotHelp = {
  tooltip: {
    title: 'Pas de pointage, outil de diagnostic',
    subtitle: '',
  },
  method: [],
};

const defaultEtoile: SlotHelp = {
  tooltip: {
    title: 'Pointage selon la cible provincial (30%):',
    subtitle: '',
  },
  method: [
    { '21% et +': 3 },
    { '11% - 20%': 2 },
    { '6% - 10%': 1 },
    { '0% - 5%': 0 },
  ],
};

const etoileInitiatives: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '3 initiatives et +': 3 },
    { '2 initiatives': 2 },
    { '1 initiative': 1 },
    { 'Pas d\'initiative': 0 },
  ],
};

const etoileActions: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '1 pt par action (Maximum de 3 pts)': 1 },
  ],
};

const etoileEspeces: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '3 espèces et +': 3 },
    { '2 espèces': 2 },
    { '1 espèces': 1 },
    { '0 espèces': 0 },
  ],
};

const etoileProjets: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '+ de 6 projets': 3 },
    { '3 - 6 projets': 2 },
    { '1 - 2 projets': 1 },
    { '0 projets': 0 },
  ],
};

const etoileProjets2: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '3 projets et +': 3 },
    { '2 projets': 2 },
    { '1 projet': 1 },
    { '0 projet': 0 },
  ],
};

const etoileProjets3: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '1 pt par projet (Maximum de 3 pts)': 1 },
  ],
};

const etoileProgrammes: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '2 programmes et +': 3 },
    { '1 programme': 2 },
    { '0 programme': 0 },
  ],
};

const etoileProgrammes2: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '1 pt par programme (Maximum de 3 pts)': 1 },
  ],
};

const etoilePercentage: SlotHelp = {
  tooltip: {
    title: 'Pointage étoile',
    subtitle: '',
  },
  method: [
    { '+ de 40%': 3 },
    { '26 - 40%': 2 },
    { '11 - 25%': 1 },
    { '0-10%': 0 },
  ],
};

const etoilePercentage2: SlotHelp = {
  tooltip: {
    title: '',
    subtitle: '',
  },
  method: [
    { '76% et +': 3 },
    { '61 - 75%': 2 },
    { '41 - 60%': 1 },
    { '0 - 40%': 0 },
  ],
};

const etoileProgression: SlotHelp = {
  tooltip: {
    title: 'Basé sur la progression de la superficie',
    subtitle: '',
  },
  method: [
    { 'Augmentation': 1 },
    { 'Maintien': 0.5 },
    { 'Diminution': 0 },
  ],
};

const defaultBasedAverage: SlotHelp = {
  tooltip: {
    title: 'Pointage étoile',
    subtitle: 'Basé sur la moyenne des autres municipalités :',
  },
  method: [
    { 'Égal à la moy. ou +': 4 },
    { '75% - 99%': 3 },
    { '50% - 74%': 2 },
    { '25% - 49%': 1 },
    { '1% - 24%': 0 },
  ],
};

export {
  defaultNiveau,
  niveauDeclaration,
  niveauActions,
  defaultEtoile,
  etoileActions,
  etoileEspeces,
  etoileProjets,
  etoileProjets2,
  etoileProjets3,
  etoileInitiatives,
  etoileProgrammes,
  etoileProgrammes2,
  etoilePercentage,
  etoilePercentage2,
  defaultBasedAverage,
  defaultGeomatique,
  etoileProgression,
};
