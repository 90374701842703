import '../theme/css/global.css';
import {
  Box, styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoShort from '../theme/assets/logo-short.png';
import NavItem from './NavItem';
import MenuItem from '../models/MenuItem';
import sidebarMenuItemsAdmin from '../routing/SidebarMenuItemsAdmin';
import User from '../models/User';
import { useAppSelector } from '../store/Hooks';
import Permissions from '../models/enums/Permissions';
import sidebarMenuItemsMunicipality from '../routing/SidebarMenuItemsMunicipality';

const SidebarSmallContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '80px',
  padding: '20px',
  background: theme.palette.background.paper,
}));

const SidebarSmall: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User;
  const navigate = useNavigate();

  return (
    <SidebarSmallContainer>
      <Box
        onClick={() => navigate('/')}
        sx={{
          maxWidth: '50px',
          marginBottom: '20px',
          cursor: 'pointer',
        }}>
        <img src={LogoShort} alt="logo" />
      </Box>
      <Box sx={{ marginTop: '15px' }}>
        {user?.permission === Permissions.ADMIN && sidebarMenuItemsAdmin.map((item: MenuItem) => (
          <NavItem
            key={item.title}
            icon={item.icon}
            href={item.href}
            title={item.title}
            small
          />
        ))}
        {user?.permission === Permissions.MUNICIPALITY && sidebarMenuItemsMunicipality.map((item: MenuItem) => (
          <NavItem
            key={item.title}
            icon={item.icon}
            href={item.href}
            title={item.title}
            small
          />
        ))}

      </Box>
    </SidebarSmallContainer>
  );
};

export default SidebarSmall;
