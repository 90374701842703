import { LocationCity } from '@mui/icons-material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MenuItem from '../models/MenuItem';

const sidebarMenuItemsAdmin = [
  {
    href: '/',
    icon: (<ContentPasteIcon fontSize="small" />),
    title: 'Formulaires',
  },
  {
    href: '/municipalities',
    icon: (<LocationCity fontSize="small" />),
    title: 'Municipalités',
  },
  // {
  //   href: '/reports',
  //   icon: (<Assessment fontSize="small" />),
  //   title: 'Rapports',
  // },
] as MenuItem[];

export default sidebarMenuItemsAdmin;
