import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';

interface Props {
  setShow: any,
  show: boolean
}

const PrivacyPoliciesDialog: React.FC<Props> = ({ setShow, show }) => (
  <Container>
    <Dialog
      open={show}
      onClose={() => setShow(false)}>
      <DialogTitle>
      Politique de protection des données personnelle
      </DialogTitle>
      <DialogContent>
      Le but du site <span>{process.env.REACT_APP_PUBLIC_URL}</span> est de permettre
      de compiler les données concernant la performance des organismes municipaux
      en matière de gestion de la biodiversité.
        <br/>
        <br/>
      Advenant le cas où vous nous fournissez une adresse courriel et un numéro de
      téléphone pour être inclus dans la liste de diffusion du programme, sachez que toutes
      les adresses courriels et numéro de téléphone recueillies par Réseau Environnement
      sont uniquement utilisés par Réseau Environnement pour communiquer avec vous aux fins
      explicitement mentionnées sur le site. Vos données ne seront jamais partagées à des tiers ou utilisées à d'autres fins par Réseau Environnement.
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => setShow(false)} color="primary">
        Fermer
        </Button>
      </DialogActions>
    </Dialog>
  </Container>
);
export default PrivacyPoliciesDialog;
