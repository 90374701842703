import {
  Dialog,
  Typography, AppBar, Toolbar, Slide, IconButton, Box, Button, TextField, InputAdornment, Tooltip, Alert, AlertTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { forwardRef, useEffect } from 'react';
import _ from 'lodash';
import { Calculate } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { Field, useFormikContext } from 'formik';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { doc, updateDoc } from 'firebase/firestore';
import ScoreTypeChip from './chips/ScoreTypeChip';
import { db } from '../../../../FirebaseConfig';
import FormState from '../../../../models/enums/FormState';
import QuestionSlot from '../models/QuestionSlot';

interface Props {
  show: boolean,
  setShow: (show: boolean) => void,
  formId: string,
  spec:any,
  formValues: any,
  updateForm: (path: string, data: any) => void,
  calculateAverage: (path: string) => void
  saveForm: () => void,
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PromptGenerateReport: React.FC<Props> = ({
  setShow, show, formId, spec, updateForm, formValues, calculateAverage,
}) => {
  const {
    values, setFieldValue, submitForm,
  } = useFormikContext<any>();

  useEffect(() => {
    values.forEach(async (item:any, index:number) => {
      const municipalityAverage: any = await calculateMunicipalityAverage(item.fieldPath);

      const difference = (municipalityAverage / _.get(values, `[${index}].average`)) * 100;
      updateForm(item.fieldPath, difference);
    });
  }, [show]);

  const handleGenerate = async (e:any) => {
    e.preventDefault();
    submitForm();

    const formDocRef = doc(db, 'Forms', formId);

    await updateDoc(formDocRef, {
      state: FormState.DONE,
    });

    setShow(false);
  };

  const calculateMunicipalityAverage = async (fieldPath: string) => {
    const data = _.get(formValues, `${fieldPath}.data`);
    let municipalityAverage = 0;

    await Promise.all(data.map((v:any) => {
      municipalityAverage += parseInt(v.amount, 10) || 0;
      return municipalityAverage;
    }));
    municipalityAverage /= formValues.population;
    return municipalityAverage.toFixed(2);
  };

  const handleChange = (e:any, pathName:string, fieldPath: string, municipalityAverage: any) => {
    setFieldValue(pathName, e.target.value);
    const difference = (municipalityAverage / e.target.value) * 100;
    updateForm(fieldPath, difference);
  };

  const handleAutomaticCalc = async (fieldPath: any, pathName:string, municipalityAverage: any) => {
    const newAverage: any = await calculateAverage(fieldPath);
    setFieldValue(pathName, newAverage);
    const difference = (municipalityAverage / newAverage) * 100;
    updateForm(fieldPath, difference);
  };

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Générer les résultats - {formValues.nomMunicipalite}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setShow(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Alert
        iconMapping={{
          error: <ErrorOutlineIcon sx={{ color: '#fff' }} fontSize="inherit" />,
        }}
        sx={{
          backgroundColor: 'error.main',
          color: '#fff',
          borderRadius: '0 0 4px 4px',
        }} severity="error">
        <AlertTitle><strong>Attention!</strong></AlertTitle>
        <strong>Assurez-vous que toutes les municipalités aient terminé de remplir leur formulaire. Sans quoi, la moyenne des municipalités seront mauvaises.</strong>
      </Alert>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '15px',
      }}>
        {values.map((item: any, index:number) => {
          const data = _.get(formValues, `${item.fieldPath}.data`);
          let municipalityAverage = 0;
          data.forEach((v:any) => {
            municipalityAverage += parseInt(v.amount, 10) || 0;
          });
          municipalityAverage /= formValues.population;

          return (
            <Box key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0.6,
                }}>
                  <Box sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    borderRadius: '100%',
                    display: 'flex',
                    width: '30px',
                    height: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0,
                    fontSize: '12px',
                  }}>{item.number}</Box>
                  <Box sx={{
                    width: '1px',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    flexGrow: 1,
                    marginTop: '10px',
                  }}></Box>
                </Box>
                <Typography variant="h5" sx={{ fontSize: '18px' }}>{item.title}</Typography>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px',
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '5px',
                  border: '1px solid #2e7a38',
                  borderRadius: '4px',
                  padding: '9px',
                }}>
                  <Typography variant="body2" sx={{ fontSize: '11px' }}><strong>Montant {formValues.nomMunicipalite}</strong></Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>{municipalityAverage.toFixed(2)}$/habitant</Typography>
                </Box>
                <Box>
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
                    <path
                      d="M272 96a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 320a48
                      48 0 1 0 -96 0 48 48 0 1 0 96 0zM400 288c17.7 0 32-14.3 32-32s-14.3-32-32-32H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H400z"
                    />
                  </svg>
                </Box>
                <Field
                  autoFocus
                  component={TextField}
                  name={`[${index}].average`}
                  type="number"
                  color='primary'
                  label="Moyenne des municipalités"
                  margin='normal'
                  variant="outlined"
                  value={_.get(values, `[${index}].average`)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e:any) => {
                    handleChange(e, `[${index}].average`, item.fieldPath, municipalityAverage.toFixed(2));
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Calculer la valeur">
                          <IconButton
                            aria-label="Calculer la valeur"
                            onClick={() => handleAutomaticCalc(item.fieldPath, `[${index}].average`, municipalityAverage.toFixed(2))}
                            onMouseDown={(e: any) => e.preventDefault()}
                            color="primary"
                          >
                            <Calculate/>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <ArrowRightAltIcon/>
                <ScoreTypeChip
                  slot={_.get(formValues, `${item.fieldPath}`) as QuestionSlot}
                  score={_.get(formValues, `${item.fieldPath}.score`)}
                  path={`${item.fieldPath}.data`}
                  data={_.get(spec, `${item.fieldPath}.data`)}
                />
              </Box>
            </Box>
          );
        })}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '15px',
        }}>
          <Button
            variant="outlined"
            color='error'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => setShow(false)}
          >
          Annuler
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => handleGenerate(e)}
          >
          Générer
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PromptGenerateReport;
