import { Star } from '@mui/icons-material';
import {
  Box, Typography,
} from '@mui/material';

type Props = {
    data:any,
}

const FormScores: React.FC<Props> = (props) => (<Box sx={{
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  gap: 3,
}}>
  <Box sx={{
    width: '50%',
    backgroundColor: 'white',
    padding: 2,
    borderRadius: '4px',
    textAlign: 'center',
  }}>
    <Typography variant='h3' sx={{ my: 1 }}>{`Niveau: ${props.data.summary.niveau_score.toString()} / 5`}</Typography>
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      gap: 2,
      alignItems: 'flex-end',
    }}>
      {[1, 2, 3, 4, 5].map((i) => (
        <Box
          key={i}
          sx={{
            width: 20,
            height: 20 * i,
            border: '1px solid',
            borderColor: '#00AFFF',
            borderRadius: '4px',
            backgroundColor: (i <= props.data.summary.niveau_score) ? '#00AFFF' : 'transparent',
          }}></Box>
      ))}
    </Box>
    <Box sx={{
      width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: 2,
    }}>
      <Typography variant='body1' sx={{ my: 1 }}>{`Pointage: ${props.data.summary.niveau.toString()} / ${props.data.summary.niveau_max.toString()}`}</Typography>
      <div>
        <Typography variant='body2'>{`Gouvernance: ${props.data.gouvernance.niveau.toString()} / ${props.data.gouvernance.niveau_max.toString()}`}</Typography>
        <Typography variant='body2'>{`Préservation: ${props.data.preservation.niveau.toString()} / ${props.data.preservation.niveau_max.toString()}`}</Typography>
        <Typography variant='body2'>
          {`Multifonctionnalité: ${props.data.multifonctionnalite.niveau.toString()} / ${props.data.multifonctionnalite.niveau_max.toString()}`}
        </Typography>
      </div>
    </Box>
  </Box>
  <Box sx={{
    width: '50%',
    backgroundColor: 'white',
    padding: 2,
    borderRadius: '4px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  }}>
    <Typography variant='h3' sx={{ my: 1 }}>{`Étoiles: ${props.data.summary.etoiles_score.toString()} / 5`}</Typography>
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      gap: 2,
      alignItems: 'center',
      flexGrow: 1,
    }}>
      {[1, 2, 3, 4, 5].map((i) => (
        <Star
          key={i}
          fontSize='large'
          sx={{
            opacity: (i <= props.data.summary.etoiles_score) ? 1 : 0.2,
            color: '#F49D1A',
          }} />
      ))}
    </Box>
    <Box sx={{
      width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: 2,
    }}>
      <Typography variant='body1' sx={{ my: 1 }}>{`Pointage: ${props.data.summary.etoiles.toString()} / ${props.data.summary.etoiles_max.toString()}`}</Typography>
      <div>
        <Typography variant='body2'>{`Gouvernance: ${props.data.gouvernance.etoiles.toString()} / ${props.data.gouvernance.etoiles_max.toString()}`}</Typography>
        <Typography variant='body2'>{`Préservation: ${props.data.preservation.etoiles.toString()} / ${props.data.preservation.etoiles_max.toString()}`}</Typography>
        <Typography variant='body2'>
          {`Multifonctionnalité: ${props.data.multifonctionnalite.etoiles.toString()} / ${props.data.multifonctionnalite.etoiles_max.toString()}`}
        </Typography>
      </div>
    </Box>
  </Box>
</Box>);

export default FormScores;
