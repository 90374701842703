import {
  Alert,
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, FormControlLabel, Typography,
} from '@mui/material';
import {
  collection, doc, getDoc, getDocs, query, updateDoc, where,
} from 'firebase/firestore';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { db } from '../../../FirebaseConfig';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import AlertUtils from '../../../utils/AlertUtil';
import Utils from '../../../utils/Utils';
import Informations from './steps/Informations';
import Instructions from './steps/Instructions';
import translator from '../../../theme/translator.json';
import FormState from '../../../models/enums/FormState';
import FormSidebar from './components/FormSidebar';
import { PEXBDFormContainer, PEXBDFormWrapper } from './components/StyledComponents';
import GeneratedFormStep from './steps/GeneratedFormStep';
import FormSpecPEXBD from './models/FormPEXBD.spec';
import User from '../../../models/User';
import Permissions from '../../../models/enums/Permissions';
import PromptGenerateReport from './components/PromptGenerateReport';
import FormService from '../../../services/FormService';
import Spinner from '../../../components/Spinner';

type Props = {
  formId:string | undefined,
}

const FormPEXBDV2: React.FC<Props> = ({ formId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;

  const [loading, setLoading] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState([]) as any[];
  const [formIsInit, setFormIsInit] = useState(false);
  const [formState, setFormState] = useState('');
  const [formSpec, setFormSpec] = useState([]) as any;
  const [showGenerateReportPrompt, setShowGenerateReportPrompt] = useState(false);
  const [programId, setProgramId] = useState('');
  const [submitForAnalysisPopupOpen, setSubmitForAnalysisPopupOpen] = useState(false);
  const [submitFormCheckbox, setSubmitFormCheckbox] = useState(false);

  const [questionsCalculatedLater, setQuestionsCalculatedLater] = useState([]) as any;

  useEffect(() => {
    const fetchInitialValues = async () => {
      if (!initialFormValues || !formId || !user) return;

      const formDocRef = doc(db, 'Forms', formId);

      const formDoc = await getDoc(formDocRef);

      if (formDoc.exists() && formDoc.data().state && formDoc.data().formData) {
        setFormSpec();
        setFormState(formDoc.data().state);
        const { formData } = formDoc.data();

        setProgramId(formDoc.data().programId);
        if (formDoc.data().programId === 'qqZTdJVoBk2f4DXjW4Ef') setFormSpec(FormSpecPEXBD);

        formData.isDisabled = ((formDoc.data().state !== FormState.IN_PROGRESS) && (user?.permission !== Permissions.ADMIN));

        setInitialFormValues(formData);
        setFormIsInit(true);
      }
    };
    fetchInitialValues();
    setLoading(false);
  }, [user]);

  useEffect(() => {
    generateQuestions();
  }, []);

  const generateQuestions = async () => {
    const initialQuestionsCalculatedLater = [{
      title: 'Montants investis dans des programmes incitatifs',
      number: '1.5',
      fieldName: '[0]',
      fieldPath: 'sections[0].subsections[0].questions[4].slots[0]',
      average: 0,
    },
    {
      title: 'Montant alloué à la biodiversité',
      number: '1.7',
      fieldName: '[1]',
      fieldPath: 'sections[0].subsections[0].questions[6].slots[0]',
      average: 0,
    },
    {
      title: 'Montant contribué à un fonds',
      number: '1.9',
      fieldName: 'repeater19',
      fieldPath: 'sections[0].subsections[0].questions[8].slots[0]',
      average: 0,
    },
    {
      title: 'Montant investis par habitants pour ces programmes',
      number: '1.14',
      fieldName: 'repeater114',
      fieldPath: 'sections[0].subsections[0].questions[13].slots[0]',
      average: 0,
    }];
    const newQuestions = await Promise.all(initialQuestionsCalculatedLater.map(async (question: any) => {
      const average = await calculatedAverage(question.fieldPath);
      return ({
        ...question,
        average,
      });
    }));

    setQuestionsCalculatedLater(newQuestions);
  };

  const calculatedAverage = async (path:string) => {
    if (!formId) return;

    let average = 0;

    const formDoc = await getDoc(doc(db, 'Forms', formId));

    if (formDoc.exists() && formDoc.data().templateSlug) {
      const { templateSlug } = formDoc.data();

      const q = query(
        collection(db, 'Forms'),
        where('templateSlug', '==', templateSlug),
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.size) {
        let n = 0;
        let totalAll = 0;
        querySnapshot.forEach((form:any) => {
          const data = form.data();

          const repeaterData = _.get(data.formData, `${path}.data`);
          const population = _.get(data.formData, 'population');
          if (repeaterData && repeaterData.length && population) {
            let totalForm = 0;
            repeaterData.forEach((v:any) => {
              totalForm += parseInt(v.amount, 10) || 0;
            });
            n += 1;
            totalAll += (totalForm / population);
          }
        });
        average = totalAll / n;
        if (!average) average = 0;
      }
    }
    // eslint-disable-next-line consistent-return
    return average.toFixed(2);
  };

  const saveData = async (formData:any) => {
    if (formId) {
      try {
        const formDocRef = doc(db, 'Forms', formId);

        await updateDoc(formDocRef, {
          formData,
        });

        AlertUtils.createSuccessAlert('Le formulaire a été sauvegardé.', dispatch);
      } catch {
        AlertUtils.createErrorAlert(Utils.getTranslation(translator.errorMessages.general.unknown), dispatch);
      }
    } else {
      AlertUtils.createErrorAlert('Il y a eu un problème en sauvegardant le formulaire.', dispatch);
    }
  };

  function renderStepContent(step:number) {
    switch (step) {
      case 0:
        return <Instructions path="acceptedConditions" programId={programId} />;
      case 1:
        return <Informations />;
      default: {
        let stepNumberRelativeToForm = step - 2;

        for (let i = 0; i < formSpec.sections.length; i += 1) {
          const numSubsectionsInSection = formSpec.sections[i].subsections.length;

          if (numSubsectionsInSection <= stepNumberRelativeToForm) {
            stepNumberRelativeToForm -= numSubsectionsInSection;
          } else if (
            formSpec.sections
            && formSpec.sections[i]
            && formSpec.sections[i].subsections
            && formSpec.sections[i].subsections[stepNumberRelativeToForm]
          ) {
            return <GeneratedFormStep path={`sections[${i}].subsections[${stepNumberRelativeToForm}]`} spec={formSpec} />;
          }
        }
        return <div>Rien trouvé</div>;
      }
    }
  }

  function getStepTitle(step:number) : string {
    switch (step) {
      case 0:
        return 'Instructions';
      case 1:
        return 'Informations de base';
      default: {
        let stepNumberRelativeToForm = step - 2;

        for (let i = 0; i < formSpec.sections.length; i += 1) {
          const numStepsInIndicateur = formSpec.sections[i].subsections.length;
          if (numStepsInIndicateur <= stepNumberRelativeToForm) {
            // step is not in this indicator
            stepNumberRelativeToForm -= numStepsInIndicateur;
          } else if (
            formSpec.sections
            && formSpec.sections[i]
            && formSpec.sections[i].title
          ) {
            // step is in this indicator
            return formSpec.sections[i].title;
          }
        }
        return 'Rien trouvé';
      }
    }
  }

  function handleShowGenerateDialog(state: boolean) {
    setShowGenerateReportPrompt(state);
  }

  function handleSetStep(step:number) {
    setActiveStep(step);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleSubmitFormForAnalysis = (formikSubmitFunction:any, id:string) => {
    formikSubmitFunction();
    setSubmitForAnalysisPopupOpen(false);
    FormService.update({
      id,
      state: FormState.WAITING_RESULT,
    });
    setFormState(FormState.WAITING_RESULT);
  };

  return (
    <>
      <PEXBDFormWrapper>
        <PEXBDFormContainer>

          { formIsInit && formSpec
          && <>
            <Formik
              initialValues={initialFormValues}
              onSubmit={(values) => saveData(values)}
            >
              {(formikProps) => (
                <>
                  <FormSidebar activeStep={activeStep} setActiveStep={handleSetStep}/>
                  <Box sx={{ width: '100%' }}>
                    <Form style={{
                      width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                    }}>
                      {formState === FormState.WAITING_RESULT
                  && <Alert severity="warning">L'analyse de vos données est en cours, vous ne pouvez plus modifier le formulaire.</Alert>}
                      {formState === FormState.DONE
                  && <Alert severity="error">
                    L'analyse de ce formulaire est complétée. Veuillez retourner à votre tableau de bord pour consulter les résultats ou sélectionner un autre formulaire.
                  </Alert>}
                      <Box sx={{
                        px: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 68, borderBottom: '1px solid #eeeeee',
                      }}>
                        <Typography variant="h2" align='center'>{getStepTitle(activeStep)}</Typography>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                          {formState === FormState.IN_PROGRESS && <Button
                            onClick={() => setSubmitForAnalysisPopupOpen(true)}
                            sx={{ mr: 1 }}
                            color="primary"
                            variant="outlined"
                            disabled={formikProps.isSubmitting || formikProps.values.isDisabled || !formikProps.values.acceptedConditions}>
                        Soumettre pour analyse
                          </Button>}
                          <Button color="primary" variant="contained" type="submit" disabled={formikProps.isSubmitting || formikProps.values.isDisabled}>
                        Sauvegarder
                          </Button>
                        </Box>
                      </Box>
                      {renderStepContent(activeStep)}
                      <Box sx={{
                        px: 2, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'background.default', height: 68,
                      }}>
                        <Box>
                          {activeStep !== 0 && (
                            <Button
                              onClick={handleBack}
                              disabled={formikProps.isSubmitting}
                            >
                          Précédent
                            </Button>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          {formState === FormState.WAITING_RESULT
                        && user?.permission === Permissions.ADMIN
                        && <Button color="secondary" variant="contained" onClick={() => handleShowGenerateDialog(true)}>
                        Analyser et générer résultats
                        </Button>}
                        </Box>
                        <Box>
                          {activeStep < 8 && <Button
                            onClick={handleNext}
                            variant="outlined"
                            color="primary"
                            // eslint-disable-next-line max-len
                            disabled={formikProps.isSubmitting || !formikProps.values.acceptedConditions || (activeStep === 1 && !formikProps.values.population) || (activeStep === 1 && !formikProps.values.superficie)}
                          >
                           Suivant
                          </Button>}
                          {
                            activeStep === 8 && <Button
                              onClick={() => setSubmitForAnalysisPopupOpen(true)}
                              variant="contained"
                              color="primary"
                              disabled={formikProps.isSubmitting || formikProps.values.isDisabled || !formikProps.values.acceptedConditions}>
                                Soumettre pour analyse
                            </Button>
                          }
                        </Box>
                      </Box>
                    </Form>

                    <Dialog
                      open={submitForAnalysisPopupOpen}
                      onClose={() => setSubmitForAnalysisPopupOpen(false)}
                    >
                      <DialogTitle>
                      Soumettre le formulaire pour analyse
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        Vous êtes sur le point de soumettre ce formulaire pour analyse. Une fois soumis, le formulaire ne pourra plus être modifié.
                        </DialogContentText>
                        <FormControlLabel
                          label="Je confirme que les renseignements fournis sont valides"
                          sx={{ marginTop: '20px' }}
                          control={
                            <Checkbox
                              checked={submitFormCheckbox}
                              onChange={(e) => {
                                setSubmitFormCheckbox(e.target.checked);
                              }}/>
                          }
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setSubmitForAnalysisPopupOpen(false)}>Annuler</Button>
                        <Button
                          onClick={() => handleSubmitFormForAnalysis(formikProps.submitForm, formId || '')}
                          variant="contained"
                          sx={{ color: 'white' }}
                          disabled={!submitFormCheckbox}>Soumettre pour analyse</Button>
                      </DialogActions>
                    </Dialog>
                    {
                      questionsCalculatedLater.length > 0
                        ? <Formik
                          initialValues={questionsCalculatedLater}
                          onSubmit={() => saveData(formikProps.values)}
                        >
                          {(GenerateReportFormikProps) => (
                            <PromptGenerateReport
                              calculateAverage={calculatedAverage}
                              updateForm={(path: string, data: any) => {
                                formikProps.setFieldValue(`${path}.score`, _.get(formSpec, `${path}.scoringFct`)(data));
                              }}
                              formValues={formikProps.values}
                              setShow={setShowGenerateReportPrompt}
                              show={showGenerateReportPrompt}
                              formId={formId || ''}
                              spec={formSpec}
                              saveForm={formikProps.submitForm}
                            />
                          )}
                        </Formik>
                        : <></>
                    }

                  </Box>
                </>
              )}
            </Formik>
          </>
          }
        </PEXBDFormContainer>
      </PEXBDFormWrapper>
      <Spinner show={loading}/>

    </>
  );
};

export default FormPEXBDV2;
