import { ArrowForward } from '@mui/icons-material';
import {
  Box, Divider, Link, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import iconBiodiversite from '../theme/assets/icons/icon-biodiversité-vert.png';
import FormState from '../models/enums/FormState';
import Utils from '../utils/Utils';

interface Props {
  title:string,
  year:string,
  linkTitle:string,
  linkUrl:string,
  formState:FormState,
}

const FormListCard: React.FC<Props> = ({
  title, year, linkTitle, linkUrl, formState,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      width: '25%',
      p: 2,
      my: 2,
    }}>
      <Box
        onClick={() => navigate(linkUrl)}
        sx={{
          cursor: 'pointer',
          background: 'white',
          p: 1,
          borderRadius: '4px',
          boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.15)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}>
        <img src={iconBiodiversite} style={{
          width: '100px',
          marginTop: '-60px',
        }}></img>
        <Box sx={{
          mb: 2, fontSize: '13px', borderRadius: 4, background: '#82af88', color: 'white', padding: '1px 8px',
        }}>{Utils.getFormStatusDisplayString(formState)}</Box>
        <Typography variant="h5">{title}</Typography>
        <Divider sx={{ width: '20%', my: 2 }} />
        <Typography sx={{ mb: 2 }}>{year}</Typography>
        <Link
          color="secondary"
          onClick={() => navigate(linkUrl)}
          sx={{
            mb: 1,
            textDecoration: 'none',
            fontSize: '12px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>{linkTitle}<ArrowForward sx={{ fontSize: '12px', ml: 1 }} /></Link>
      </Box>
    </Box>
  );
};

export default FormListCard;
