import {
  Box, Chip,
} from '@mui/material';

const OptionalTooltip: React.FC = () => (<Box sx={{
  display: 'flex',
  justifyContent: 'flex-end',
}}>

  <Chip label="optionnel" size='small' color="warning" sx={{ color: 'white', fontSize: '12px', textTransform: 'none' }} />

</Box>);

export default OptionalTooltip;
