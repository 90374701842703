/* eslint-disable max-len */
import { Calculate, Update } from '@mui/icons-material';
import {
  Box, Chip, Link, Typography,
} from '@mui/material';
import IndicateurTooltip from '../components/IndicateurTooltip';
import OptionalTooltip from '../components/OptionalTooltip';
import { PEXBDFormStepContent } from '../components/StyledComponents';
import SlotTypes from '../models/SlotTypes';

const Instructions: React.FC<{programId:string}> = ({ programId }) => (
  <PEXBDFormStepContent>
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, mt: 8, mb: 2,
    }}>
      <div style={{ minWidth: '115px' }}>
        <IndicateurTooltip type={SlotTypes.NIVEAU}/>
      </div>
      <div>
        <Typography variant='body1'>
          Cette icône indique que la méthode de pointage du sous-indicateur est de type <span style={{ fontWeight: 600 }}>Niveau</span>.
        </Typography>
        <Typography variant='body2'>
          Cliquez sur cet icône pour obtenir les détails sur la méthode de pointage. Lorsque possible, le pointage du sous-indicateur s'affiche automatiquement à gauche de l'icône.
        </Typography>
      </div>
    </Box>
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, my: 2,
    }}>
      <div style={{ minWidth: '115px' }}>
        <IndicateurTooltip type={SlotTypes.ETOILES}/>
      </div>
      <div>
        <Typography variant='body1'>
          Cette icône indique que la méthode de pointage du sous-indicateur est de type <span style={{ fontWeight: 600 }}>Étoiles</span>.
        </Typography>
        <Typography variant='body2'>
          Cliquez sur cet icône pour obtenir les détails sur la méthode de pointage. Lorsque possible, le pointage du sous-indicateur s'affiche automatiquement à gauche de l'icône.
        </Typography>
      </div>
    </Box>
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, my: 2,
    }}>
      <div style={{ minWidth: '115px' }}>
        <IndicateurTooltip type={SlotTypes.GEOMATIQUE}/>
      </div>
      <div>
        <Typography variant='body1'>
          Cette icône indique que le sous-indicateur est un indicateur <span style={{ fontWeight: 600 }}>Géomatique</span>.
        </Typography>
        <Typography variant='body2'>
          Les indicateurs géomatiques n'ont pas de pointage, ils sont seulement utilisés pour des fins de diagnostic.
        </Typography>
      </div>
    </Box>
    {programId === 'PlhU9DxMiv4loyvKLkHE' && <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 4,
    }}>
      <div style={{ minWidth: '115px' }}>
        <OptionalTooltip />
      </div>
      <div>
        <Typography variant='body1'>
        Certains sous-indicateurs sont facultatifs, pour s'adapter à la réalité des plus petites municipalités.
        </Typography>
        <Typography variant='body2'>
         Vous avez la possibilité de les remplir s'ils sont pertinents pour vous, le total des points s'ajustera automatiquement.
        </Typography>
      </div>
    </Box>}
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, my: 2,
    }}>
      <div style={{ minWidth: '115px', textAlign: 'right' }}>
        <Calculate color='primary'/>
      </div>
      <div>
        <Typography variant='body1'>
          Cette icône indique que le sous-indicateur peut être <span style={{ fontWeight: 600 }}>calculé automatiquement</span>.
        </Typography>
        <Typography variant='body2'>
          Cliquez sur cet icône pour initier le calcul automatique.
        </Typography>
      </div>
    </Box>
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 4,
    }}>
      <div style={{ minWidth: '115px' }}>
        <Chip
          icon={<Update />}
          size="small"
          color="primary"
          label='Tous les 3 ans'
          sx={{ opacity: 0.6 }} />
      </div>
      <div>
        <Typography variant="body1">Cette pastille indique la fréquence à laquelle le sous indicateur doit être mis à jour.</Typography>
        <Typography variant="body2">Cliquez sur cette pastille pour récupérer les données de l'année précédente lorsque possible.</Typography>
      </div>
    </Box>
    <Box sx={{
      display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 6,
    }}>
      <div style={{ minWidth: '115px' }}>
      </div>
      <div>
        <Typography variant="body1">Compléter au besoin la colonne "Commentaires et sources de données" </Typography>
      </div>
    </Box>
    <Typography variant="body1">Reportez vous au <Link>Guide Méthodologique</Link> pour plus d'informations sur chaque sous-indicateur.</Typography>
  </PEXBDFormStepContent>
);

export default Instructions;
