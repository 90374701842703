import * as yup from 'yup';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Slide, Button, FormControlLabel, Checkbox,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { forwardRef, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import _ from 'lodash';
import Spinner from './Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch } from '../store/Hooks';
import AlertUtil from '../utils/AlertUtil';
import ErrorCodes from '../models/enums/ErrorCodes';
import MunicipalityService from '../services/MunicipalityService';

interface Props {
  setShow: any,
  show: boolean,
  id: string,
  name: string,
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PromptDeleteMunicipality: React.FC<Props> = ({
  setShow, show, id, name,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const deleteMunicipality = async (v: any, resetForm: any) => {
    try {
      setLoading(true);

      await MunicipalityService.deleteAccount(id);
      await MunicipalityService.delete(id);

      setShow(false);
      resetForm();
    } catch (e: any) {
      let message = '';
      switch (e.code) {
        case ErrorCodes.INVALID_ARGUMENT: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.passwordToShort);
          break;
        }
        default: {
          message = await Utils.getTranslation(translator.errorMessages.general.unknown);
          break;
        }
      }
      if (message) AlertUtil.createErrorAlert(message as string, dispatch);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{
          confirmation: false,
        }}
        validationSchema={yup.object({
          confirmation: yup.boolean().required(Utils.getTranslation(translator.formMessages.requiredField)),
        })}
        onSubmit={(v, { setSubmitting, resetForm }) => {
          deleteMunicipality(v, resetForm);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Box>
            <Form>
              <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                sx={{
                  backgroundColor: 'background.default !important',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                    Supprimer la municipalité
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box sx={{
                    margin: '0 0 15px 0',
                    minWidth: '500px',
                  }}>

                        La municipalité <span style={{ fontWeight: 'bold' }}>{name}</span> sera supprimée, cette action est irréversible.
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={<Checkbox checked={formikProps.values.confirmation} />}
                      label="Je comprends, supprimer la municipalité"
                      name="confirmation"
                      onChange={formikProps.handleChange}
                    />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                  }}>
                    <Button
                      variant="outlined"
                      color='error'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setShow(false)}
                    >
                      {Utils.getTranslation(translator.components.promptCreateMunicipality.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color='error'
                      sx={{ mt: 3, mb: 2, color: 'white' }}
                      onClick={(e) => formikProps.submitForm()}
                      disabled={!formikProps.values.confirmation}
                    >
                      Supprimer
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          </Box>
        )}
      </Formik>
      <Spinner show={loading}/>
    </Container>
  );
};

export default PromptDeleteMunicipality;
