import { Home } from '@mui/icons-material';
import MenuItem from '../models/MenuItem';

const sidebarMenuItemsMunicipality = [
  {
    href: '/',
    icon: (<Home fontSize="small" />),
    title: 'Accueil',
  },
  // {
  //   href: '/reports',
  //   icon: (<Assessment fontSize="small" />),
  //   title: 'Rapports',
  // },
] as MenuItem[];

export default sidebarMenuItemsMunicipality;
