import {
  Checkbox, FormControl, InputLabel, ListItemIcon, MenuItem, Select, ListItemText,
} from '@mui/material';
import {
  collection, getDocs, orderBy, query,
} from 'firebase/firestore';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { db } from '../FirebaseConfig';

interface Props {
    onClick?: any
  }

const MunicipalitesSelect: React.FC<Props> = ({ onClick }) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [municipalitiesArr, setMunicipalitiesArr] = useState([]) as any[];
  const [showSelect, setShowSelect] = useState(false);
  const { values, setFieldValue } : { values:any, setFieldValue:any} = useFormikContext();

  useEffect(() => {
    const fetchMunicipalities = async () => {
      const q = query(
        collection(db, 'Municipalities'),
        orderBy('name'),
      );
      const querySnapshot = await getDocs(q);
      const municipalitiesArrTemp: any[] = [];

      querySnapshot.forEach((municipality:any) => {
        const data = municipality.data();
        if (!data.formList.some((e : {templateSlug:string, id:string}) => e.templateSlug === `${values.program}.${values.year}`)) {
          municipalitiesArrTemp.push(
            {
              id: municipality.ref.id,
              name: data.name,
            },
          );
        }
      });
      setMunicipalitiesArr(municipalitiesArrTemp);
    };

    setFieldValue('municipalities', []);
    setIsAllSelected(false);
    if (values.program && values.year) {
      setShowSelect(true);
      fetchMunicipalities();
    }
  }, [values.program, values.year]);

  const handleChangeMunicipalitySelect = (event : any) => {
    const { value } = event.target;
    setIsAllSelected(value.length === municipalitiesArr.length);

    if (value[value.length - 1] === 'all') {
      setIsAllSelected(true);
      setFieldValue(
        'municipalities',
        values.municipalities.length === municipalitiesArr.length ? [] : municipalitiesArr.map((m : {id:string, name:string}) => m.id),
      );
      return;
    }
    setFieldValue('municipalities', value);
  };

  const handleClickSelectAll = () => {
    setFieldValue(
      'municipalities',
      isAllSelected ? [] : municipalitiesArr.map((m : {id:string, name:string}) => m.id),
    );
    setIsAllSelected(!isAllSelected);
  };

  return (
    <>
      {showSelect && !!municipalitiesArr.length && <FormControl fullWidth>
        <InputLabel>Sélectionner les municipalités</InputLabel>
        <Select
          multiple
          name="municipalities"
          label="Sélectionner les municipalités"
          value={values.municipalities}
          onChange={(e) => handleChangeMunicipalitySelect(e)}
          // TODO : clean this up
          renderValue={(s:any) => s.map((el:any) => municipalitiesArr.find((el2:any) => el === el2.id).name).join(', ')}
        >
          <MenuItem onClick={() => handleClickSelectAll()}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
              />
            </ListItemIcon>
            <ListItemText
              primary="Tout sélectionner"
            />
          </MenuItem>
          {municipalitiesArr.map((option: {id:string, name:string}) => (
            <MenuItem key={option.id} value={option.id}>
              <ListItemIcon>
                <Checkbox checked={values.municipalities.some((el: string) => el === option.id)} />
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
    </>
  );
};

export default MunicipalitesSelect;
