import _ from 'lodash';
import TooltipDetail from '../../components/TooltipDetail';
import SlotTypes from '../SlotTypes';

/* eslint-disable camelcase */
/* eslint-disable max-len */
const indicateur3_1 = {
  id: '',
  title: 'Superficie des aires protégées',
  number: '3.1',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des aires protégées en hectares',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur3_2 = {
  id: '',
  title: 'Superficie des sites protégés par la conservation volontaire (SPCV) non reconnus au RAPQ',
  number: '3.2',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des sites protégés par la conservation volontaire (SPCV) non reconnus au RAPQ en hectares',
      ],
      data: [''],
      score: '',
    },
  ],
};
const indicateur3_3 = {
  id: '',
  title: 'Superficie des espaces d\'intérêt pour la conservation identifiés en vertu d\'autres mécanismes et outils',
  number: '3.3',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des espaces d\'intérêt pour la conservation identifiés en vertu d\'autres mécanismes et outils en hectares',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur3_4 = {
  id: '',
  title: 'Proportion du territoire dont l\'affectation et l\'usage bénéficient  d\'une mesure de conservation',
  number: '3.4',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Proportion du territoire dont l\'affectation et l\'usage bénéficient d\'un statut de protection en pourcentage',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 21) return 3;
        if (data >= 11) return 2;
        if (data >= 6) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title="Pointage selon la cible provincial (30%):"
        elements={[
          '21% et + : 3 pts',
          '11% - 20% : 2 pts',
          '6% - 10% : 1 pts',
          '0% - 5% : 0 pts',
        ]}
      />,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[0].slots[0].data[0]`)) || 0;
        const v2 = parseFloat(_.get(values, `${sectionPath}[1].slots[0].data[0]`)) || 0;
        const v3 = parseFloat(_.get(values, `${sectionPath}[2].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return ((v1 + v2 + v3) / (superficie * 100)).toFixed(2);
      },
      maxScore: 3,
    },
  ],
};

const indicateur4_1 = {
  id: '',
  title: 'Identification des milieux naturels d\'intérêt pour la connectivité',
  number: '4.1',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Les milieux naturels d\'intérêt pour la connectivité ont été identifiés.',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur4_2 = {
  id: '',
  title: 'Largeur des mailles effectives',
  number: '4.2',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Largeur des mailles effectives en hectares',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur4_3 = {
  id: '',
  title: 'Parcelles de milieux naturels terrestres',
  number: '4.3',
  description: [
    'Fragmentation:',
  ],
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie totale des milieux naturels terrestres (hectares)',
        'Nombre de parcelles des milieux naturels terrestres',
        'Taille moyenne des parcelles (hectares)',
        'Taille de la plus grande parcelle non fragmentée (hectares)',
      ],
      data: ['', '', '', ''],
      score: '',
    },
  ],
};

const indicateur4_4 = {
  id: '',
  title: 'Portrait des bandes riveraines  (ou milieux riverains)',
  number: '4.4',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        '% des cours d\'eau bordés par des bandes riveraines significatives',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur4_5 = {
  id: '',
  title: 'Création de liens végétaux et/ou de corridors de transit',
  number: '4.5',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre de liens végétal et/ou corridor de transit qui ont été crées dans l\'année. Minimum de 500 m2, lien végétal minimum 20 m de largeur.',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '3 initiatives et + : 3 pts',
          '2 initiatives : 2 pts',
          '1 initiative : 1 pt',
          'Pas d\'initiative : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur4_6 = {
  id: '',
  title: 'Plan d\'action pour la connectivité',
  number: '4.6',
  frequency: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Création d\'un plan d\'action ayant pour objectif d\'augmenter la connectivité incluant des objectifs chiffrés',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur4_7 = {
  id: '',
  title: 'Intégration des zones noyaux ou des milieux naturels d\'intérêt et du concept de corridor de connectivité dans les outils d\'urbanisme et d\'aménagement du territoire',
  number: '4.7',
  description: [
    'Les zones noyaux ou les milieux naturels d\'intérêt pour la biodiversité, ainsi que le concept de corridor de connectivité, ont été intégrés dans dans les outils d\'urbanisme et aménagement du territoire (par exemple sous forme de zone d\'usage restreint permettant de favoriser le maintien minimum de biodiversité):',
  ],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU)',
        'Schéma d\'aménagement et de développement (SAD) ou Plan métropolitain d\'aménagement et de développement (PMAD)',
        'Plan de développement de la zone agricole (PDZA)',
      ],
      data: ['0', '0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 3,
    },
  ],
};

const indicateur4_7_Group2 = {
  id: '',
  title: 'Intégration des zones noyaux ou des milieux naturels d\'intérêt et du concept de corridor de connectivité dans les outils d\'urbanisme et d\'aménagement du territoire',
  number: '4.7',
  description: [
    'Les zones noyaux ou les milieux naturels d\'intérêt pour la biodiversité, ainsi que le concept de corridor de connectivité, ont été intégrés dans dans les outils d\'urbanisme et aménagement du territoire (par exemple sous forme de zone d\'usage restreint permettant de favoriser le maintien minimum de biodiversité):',
  ],
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'Règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU)',
        'Schéma d\'aménagement et de développement (SAD) ou Plan métropolitain d\'aménagement et de développement (PMAD)',
        'Plan de développement de la zone agricole (PDZA)',
      ],
      data: ['0', '0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 3,
    },
  ],
};

const indicateur5_1 = {
  id: '',
  title: 'Écosystèmes forestiers',
  number: '5.1',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des écosystémes forestiers en hectares',
      ],
      data: [''],
      score: '',
    },
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Part des écosystèmes forestiers par rapport à la superfie totale du territoire en pourcentage',
      ],
      data: [''],
      score: '',
      hideToolTip: true,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[0].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / (superficie * 100);
      },
    },
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Nombre de types d\'écosystèmes forestiers (nombre de types écologiques différents selon les données écoforestières)',
        'Diversité des écosystèmes forestiers (indice de diversité de Shannon appliqué aux types écologiques selon les données écoforestières)',
      ],
      data: ['', ''],
      score: '',
      hideToolTip: true,
    },
  ],
};

const indicateur5_2 = {
  id: '',
  title: 'Milieux humides',
  number: '5.2',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des milieux humides en hectares',
      ],
      data: [''],
      score: '',
    },
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Part des milieux humides par rapport à la superfie totale du territoire en pourcentage',
      ],
      data: [''],
      score: '',
      hideToolTip: true,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / (superficie * 100);
      },
    },
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Nombre de types différents de milieux humides présents sur le territoire',
        'Diversité des milieux humides (indice de diversité de Shannon appliqué à la classe de milieux humides)',
        'Nombre de complexes (groupement de milieux humides séparés par moins de 30m) de milieux humides',
        'Taille moyenne des complexes de milieux humides',
      ],
      hideToolTip: true,
      data: ['', '', '', ''],
      score: '',
    },
  ],
};

const indicateur5_3 = {
  id: '',
  title: 'Milieux hydriques',
  number: '5.3',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Superficie des milieux hydriques en hectares',
      ],
      data: [''],
      score: '',
    },
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Part des milieux hydriques par rapport à la superfie totale du territoire en pourcentage',
      ],
      data: [''],
      score: '',
      hideToolTip: true,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[2].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return v1 / (superficie * 100);
      },
    },
  ],
};
const indicateur5_4 = {
  id: '',
  title: 'Superficie du territoire couvert par les trois grands types d\'écosystèmes',
  number: '5.4',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Part des trois milieux par rapport à la supercifie totale du territoire en pourcentage',
      ],
      data: [''],
      score: '',
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[0].slots[0].data[0]`)) || 0;
        const v2 = parseFloat(_.get(values, `${sectionPath}[1].slots[0].data[0]`)) || 0;
        const v3 = parseFloat(_.get(values, `${sectionPath}[2].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return (v1 + v2 + v3) / superficie;
      },
    },
  ],
};

const indicateur5_5 = {
  id: '',
  title: 'Projets de restauration et/ou création de milieux humides et hydriques',
  number: '5.5',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre total de projets de restauration et/ou création de milieux humides et hydriques en cours ou réalisés par la municipalité (toutes années confondues)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 9) return 3;
        if (data >= 4) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '9+ projets : 3 pts',
          '4-8 projets : 2 pts',
          '1-3 projets : 1 pt',
          '0 projet : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur6_1 = {
  id: '',
  title: 'Liste des espèces à statut présentes sur le territoire selon le CDPNQ',
  number: '6.1',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'La liste des espèces fauniques et floristiques à statut sont listées d\'après les données fournies par le CDPNQ',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur6_2 = {
  id: '',
  title: 'Liste complémentaire des espèces à statut présentes sur le territoire',
  number: '6.2',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'La liste des données du CDPNQ est complétée avec des inventaires de terrain, des activités d\'observation citoyenne et les données COSEPAC',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre d\'actions réalisées pour augmenter les connaissances sur les espèces à statut (inventaires réalisés par la municipalité) dans l\'année étudiée (Sauf études réalisées dans le cadre de projet de développement)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '1 pt par action',
          'Maximum de 3 pts',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur6_3 = {
  id: '',
  title: 'Déclaration des espèces à statut présentes sur le territoire',
  number: '6.3',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Les occurrences connues d\'espèces à statut présentes sur le territoire ont été déclarées au Centre des données du patrimoine naturel du Québec qu\'elles aient été identifiées par les employés de l\'organisation municipale, ou fournies par les citoyens ou les contracteurs',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      labels: ['non déclarées', 'partiellement', 'toutes déclarées'],
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          'toutes déclarées : 1 pt',
          'partiellement : 0.5 pt',
          'non déclarées : 0 pt',
        ]}
      />,
      maxScore: 1,
    },
  ],
};

const indicateur6_4 = {
  id: '',
  title: 'Programme de suivi d\'une ou de plusieurs espèces sentinelles',
  number: '6.4',
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Un programme de suivi d\'une ou plusieurs espèces sentinelles a été mis en place, ou l\'organisme municipal y participe, et est en cours',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur6_4_Group2 = {
  id: '',
  title: 'Programme de suivi d\'une ou de plusieurs espèces sentinelles',
  number: '6.4',
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'Un programme de suivi d\'une ou plusieurs espèces sentinelles a été mis en place, ou l\'organisme municipal y participe, et est en cours',
      ],
      data: ['0'],
      score: '',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
    },
  ],
};

const indicateur6_5 = {
  id: '',
  title: 'Plan de rétablissement d\'une ou de plusieurs espèces sentinelles',
  number: '6.5',
  slots: [
    {
      type: SlotTypes.ETOILES,
      item: 'Mise en place ou participation à un plan de rétablissement ciblant une ou plusieurs espèces sentinelles (nombre d\'espèces)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '3 espèces et + : 3 pts',
          '2 espèces : 2 pts',
          '1 espèces : 1 pt',
          '0 espèces ciblées : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur6_5_Group2 = {
  id: '',
  title: 'Plan de rétablissement d\'une ou de plusieurs espèces sentinelles',
  number: '6.5',
  slots: [
    {
      optional: true,
      type: SlotTypes.ETOILES,
      item: 'Mise en place ou participation à un plan de rétablissement ciblant une ou plusieurs espèces sentinelles (nombre d\'espèces)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '3 espèces et + : 3 pts',
          '2 espèces : 2 pts',
          '1 espèces : 1 pt',
          '0 espèces ciblées : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur6_6 = {
  id: '',
  title: 'Intégration des habitats fauniques  et floristiques d\'intérêt dans les outils d\'urbanisme et aménagement du territoire',
  number: '6.6',
  description: ['Les habitats fauniques et floristiques d\'intérêt sont présents dans les outils d\'urbanisme et aménagement du territoire :'],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU)',
        'Schéma d\'aménagement et de développement (SAD) ou Plan métropolitain d\'aménagement et de développement (PMAD)',
      ],
      data: ['0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 2,
    },
  ],
};

const indicateur6_6_Group2 = {
  id: '',
  title: 'Intégration des habitats fauniques  et floristiques d\'intérêt dans les outils d\'urbanisme et aménagement du territoire',
  number: '6.6',
  description: ['Les habitats fauniques et floristiques d\'intérêt sont présents dans les outils d\'urbanisme et aménagement du territoire :'],
  slots: [
    {
      optional: true,
      type: SlotTypes.NIVEAU,
      items: [
        'Règlement de zonage et autres règlements d\'urbanisme et plan d\'urbanisme (PU)',
        'Schéma d\'aménagement et de développement (SAD) ou Plan métropolitain d\'aménagement et de développement (PMAD)',
      ],
      data: ['0', '0'],
      score: '',
      scoringFct: (data: string[]) => data.reduce((sum:number, item: string) => sum + parseFloat(item), 0),
      maxScore: 2,
    },
  ],
};

const indicateur7_1 = {
  id: '',
  title: 'Liste des espèces exotiques envahissantes présentes sur le territoire',
  number: '7.1',
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [
        'Liste des espèces exotiques envahissantes présentes sur le territoire et dans son environnement immédiat',
      ],
      data: [''],
      score: '',
    },
  ],
};

const indicateur7_2 = {
  id: '',
  title: 'Mesures pour éviter la propagation des espèces exotiques envahissantes',
  number: '7.2',
  description: [
    'Des mesures pour éviter la propagation des espèces exotiques envahissantes ont été réalisées:',
  ],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Établissement d\'une directive de gestion des EEE pour les travaux effectués en régie',
        'Intégration d\'articles sur la gestion des EEE dans les devis pour les travaux réalisés en sous-traitance',
        'Développement d\'un plan d\'action pour lutter contre les EEE',
        'Intégration de la gestion des EEE dans une politique adoptée par le Conseil',
        'Allocation d\'un budget annuel alloué à l\'éradication des EEE',
        'Les occurrences connues d\'EEE présentes sur le territoire ont été déclarées sur l\'outil Sentinelle',
      ],
      data: ['0', '0', '0', '0', '0', '0'],
      score: '',
      scoringFct: (data: string[]) => {
        const realised = data.filter((x) => x === '1').length;
        if (realised >= 4) return 1;
        if (realised >= 1) return 0.5;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '4 actions et + : 1 pt',
          '1 à 3 actions : 0.5 pt',
          '0 action réalisée : 0 pt',
        ]}
      />,
      maxScore: 1,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre de projets de contrôle des espèces exotiques envahissantes (toutes années confondues)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 6) return 3;
        if (data >= 3) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '6 projets et + : 3 pts',
          '3 à 5 projets : 2 pts',
          '1 à 2 projets : 1 pt',
          '0 projet : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre total de programme de sensibilisation des citoyens (et des municipalités dans le cas des communautés métropolitaines et des MRC) mis en place (toutes années confondues)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        return data;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '3 programmes et + : 3 pts',
          '2 programmes : 2 pts',
          '1 programme : 1 pt',
          '0 programme : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

const indicateur7_2_Group2 = {
  id: '',
  title: 'Mesures pour éviter la propagation des espèces exotiques envahissantes',
  number: '7.2',
  description: [
    'Des mesures pour éviter la propagation des espèces exotiques envahissantes ont été réalisées:',
  ],
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [
        'Établissement d\'une directive de gestion des EEE pour les travaux effectués en régie',
        'Intégration d\'articles sur la gestion des EEE dans les devis pour les travaux réalisés en sous-traitance',
        'Développement d\'un plan d\'action pour lutter contre les EEE',
        'Intégration de la gestion des EEE dans une politique adoptée par le Conseil',
        'Allocation d\'un budget annuel alloué à l\'éradication des EEE',
        'Les occurrences connues d\'EEE présentes sur le territoire ont été déclarées sur l\'outil Sentinelle',
      ],
      data: ['0', '0', '0', '0', '0', '0'],
      score: '',
      scoringFct: (data: string[]) => {
        const realised = data.filter((x) => x === '1').length;
        if (realised >= 4) return 1;
        if (realised >= 1) return 0.5;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '4 actions et + : 1 pt',
          '1 à 3 actions : 0.5 pt',
          '0 action réalisée : 0 pt',
        ]}
      />,
      maxScore: 1,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre de projets de contrôle des espèces exotiques envahissantes (toutes années confondues)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '3 projets et + : 3 pts',
          '2 projets : 2 pts',
          '1 projet : 1 pt',
          '0 projet : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
    {
      type: SlotTypes.ETOILES,
      item: 'Nombre total de programme de sensibilisation des citoyens (et des municipalités dans le cas des communautés métropolitaines et des MRC) mis en place (toutes années confondues)',
      data: '',
      score: '',
      scoringFct: (data:number) => {
        if (data >= 2) return 3;
        if (data >= 1) return 2;
        return 0;
      },
      toolTipContent: <TooltipDetail
        title=""
        elements={[
          '2 programmes et + : 3 pts',
          '1 programme : 2 pt',
          '0 programme : 0 pt',
        ]}
      />,
      maxScore: 3,
    },
  ],
};

export {
  indicateur3_1,
  indicateur3_2,
  indicateur3_3,
  indicateur3_4,
  indicateur4_1,
  indicateur4_2,
  indicateur4_3,
  indicateur4_4,
  indicateur4_5,
  indicateur4_6,
  indicateur4_7,
  indicateur4_7_Group2,
  indicateur5_1,
  indicateur5_2,
  indicateur5_3,
  indicateur5_4,
  indicateur5_5,
  indicateur6_1,
  indicateur6_2,
  indicateur6_3,
  indicateur6_4,
  indicateur6_4_Group2,
  indicateur6_5,
  indicateur6_5_Group2,
  indicateur6_6,
  indicateur6_6_Group2,
  indicateur7_1,
  indicateur7_2,
  indicateur7_2_Group2,
};
