import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';

const Notifications: React.FC = () => (
  <Layout title={Utils.getTranslation(translator.pages.notifications.title)}>
    <Spinner show={false}/>
  </Layout>
);

export default Notifications;
