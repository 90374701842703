enum SlotTypes {
    NIVEAU = 'niveau',
    REPEATER = 'repeater',
    GEOMATIQUE = 'geomatique',
    ETOILES = 'etoiles',
    INFO = 'info',
    ACTIVITY = 'activity',
    INDICATOR = 'indicator',
  }

export default SlotTypes;
