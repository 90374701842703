import User from '../models/User';
import { useAppSelector } from '../store/Hooks';
import Permissions from '../models/enums/Permissions';
import HomeMunicipality from './HomeMunicipality';
import HomeAdmin from './HomeAdmin';

const Dashboard: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User;

  return (
    <>
      {user?.permission === Permissions.ADMIN && <HomeAdmin />}
      {user?.permission === Permissions.MUNICIPALITY && <HomeMunicipality /> }
    </>
  );
};

export default Dashboard;
