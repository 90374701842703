import {
  Box, Collapse, List, ListItem, ListItemButton, ListItemText, styled,
} from '@mui/material';
import LogoPEXBD from '../../../../theme/assets/PEXBD.png';

type Props = {
    activeStep:number,
    setActiveStep:(num: number) => void,
}

const PEXBDFormSidebar = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  width: '250px',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
}));

const PEXBDFormNavItem = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const PEXBDFormNavItemParent = styled(ListItem)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const PEXBDFormNavItemChild = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius as number * 3,
  borderBottomLeftRadius: theme.shape.borderRadius as number * 3,
  paddingLeft: '15px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
}));

const FormSidebar: React.FC<Props> = ({ activeStep, setActiveStep }) => (<>
  <PEXBDFormSidebar>
    <Box
      sx={{
        maxWidth: '200px',
        margin: '40px 20px 20px',
      }}>
      <img src={LogoPEXBD} alt="logo" />
    </Box>
    <List
      sx={{ width: '90%', marginLeft: '10%' }}
      component="nav"
    >
      <PEXBDFormNavItem
        selected={activeStep === 0}
        onClick={() => setActiveStep(0)}
      >
        <ListItemText
          primary="Instructions"
        />
      </PEXBDFormNavItem>
      <PEXBDFormNavItem
        selected={activeStep === 1}
        onClick={() => setActiveStep(1)}
      >
        <ListItemText
          primary="Informations de base"
        />
      </PEXBDFormNavItem>
      <PEXBDFormNavItemParent
        selected={activeStep >= 2 && activeStep <= 3}
      >
        <PEXBDFormNavItemChild
          selected={activeStep >= 2 && activeStep <= 3}
          onClick={() => setActiveStep(2)}
        >
          <ListItemText
            primary="Gouvernance"
          />
        </PEXBDFormNavItemChild>
        <Collapse in={activeStep >= 2 && activeStep <= 3} timeout="auto" unmountOnExit>
          <List
            sx={{ marginLeft: '10px', padding: 0 }}
            component="nav"
            dense
          >
            <PEXBDFormNavItemChild
              selected={activeStep === 2}
              onClick={() => setActiveStep(2)}
            >
              <ListItemText
                primary="Intégration de la préservation de la biodiversité dans les activités municipales"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 3}
              onClick={() => setActiveStep(3)}
            >
              <ListItemText
                primary="Ressources attribuées à la biodiversité"
              />
            </PEXBDFormNavItemChild>
          </List>
        </Collapse>
      </PEXBDFormNavItemParent>
      <PEXBDFormNavItemParent
        selected={activeStep >= 4 && activeStep <= 8}
      >
        <PEXBDFormNavItemChild
          selected={activeStep >= 4 && activeStep <= 8}
          onClick={() => setActiveStep(4)}
        >
          <ListItemText
            primary="Préservation"
          />
        </PEXBDFormNavItemChild>
        <Collapse in={activeStep >= 4 && activeStep <= 8} timeout="auto" unmountOnExit>
          <List
            sx={{ marginLeft: '10px', padding: 0 }}
            component="nav"
            dense
          >
            <PEXBDFormNavItemChild
              selected={activeStep === 4}
              onClick={() => setActiveStep(4)}
            >
              <ListItemText
                primary="Territoire dont l'affectation et l'usage bénéficient d'une vocation de conservation"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 5}
              onClick={() => setActiveStep(5)}
            >
              <ListItemText
                primary="Fragmentation et corridors écologiques"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 6}
              onClick={() => setActiveStep(6)}
            >
              <ListItemText
                primary="Grands écosystèmes et occupation du sol"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 7}
              onClick={() => setActiveStep(7)}
            >
              <ListItemText
                primary="Espèces à statut"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 8}
              onClick={() => setActiveStep(8)}
            >
              <ListItemText
                primary="Espèces exotiques envahissantes"
              />
            </PEXBDFormNavItemChild>
          </List>
        </Collapse>
      </PEXBDFormNavItemParent>
      <PEXBDFormNavItemParent
        selected={activeStep >= 9 && activeStep <= 11}
      >
        <PEXBDFormNavItemChild
          selected={activeStep >= 9 && activeStep <= 11}
          onClick={() => setActiveStep(9)}
        >
          <ListItemText
            primary="Multifonctionnalité"
          />
        </PEXBDFormNavItemChild>
        <Collapse in={activeStep >= 9 && activeStep <= 11} timeout="auto" unmountOnExit>
          <List
            sx={{ marginLeft: '10px', padding: 0 }}
            component="nav"
            dense
          >
            <PEXBDFormNavItemChild
              selected={activeStep === 9}
              onClick={() => setActiveStep(9)}
            >
              <ListItemText
                primary="Santé et loisirs"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 10}
              onClick={() => setActiveStep(10)}
            >
              <ListItemText
                primary="Services écosystémiques"
              />
            </PEXBDFormNavItemChild>
            <PEXBDFormNavItemChild
              selected={activeStep === 11}
              onClick={() => setActiveStep(11)}
            >
              <ListItemText
                primary="Engagement collectif lié à la biodiversité"
              />
            </PEXBDFormNavItemChild>
          </List>
        </Collapse>
      </PEXBDFormNavItemParent>
    </List>
  </PEXBDFormSidebar>
</>);

export default FormSidebar;
