import * as yup from 'yup';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Slide,
  Select, Button, FormHelperText, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { forwardRef, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import _ from 'lodash';
import Spinner from './Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch } from '../store/Hooks';
import AlertUtil from '../utils/AlertUtil';
import ErrorCodes from '../models/enums/ErrorCodes';
import FormState from '../models/enums/FormState';
import FormService from '../services/FormService';

interface Props {
  setShow: any,
  show: boolean,
  selectedForms: any[]
}

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const PromptModifyStatus: React.FC<Props> = ({ setShow, show, selectedForms }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const changeStatus = async (v: any, resetForm: any) => {
    try {
      setLoading(true);

      await Promise.all(selectedForms.map(async (form) => {
        FormService.update({
          id: form.id,
          state: v.status,
        });
      }));
      setShow(false);
      resetForm();
    } catch (e: any) {
      let message = '';
      switch (e.code) {
        case ErrorCodes.ALREADY_EXISTS: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.alreadyInUse);
          break;
        }
        case ErrorCodes.INVALID_ARGUMENT: {
          message = await Utils.getTranslation(translator.errorMessages.municipalityCreate.passwordToShort);
          break;
        }
        default: {
          message = await Utils.getTranslation(translator.errorMessages.general.unknown);
          break;
        }
      }
      if (message) AlertUtil.createErrorAlert(message as string, dispatch);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Formik
        initialValues={{
          status: '',
        }}
        validationSchema={yup.object({
          status: yup.string().required(Utils.getTranslation(translator.formMessages.requiredField)),
        })}
        onSubmit={(v, { setSubmitting, resetForm }) => {
          changeStatus(v, resetForm);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Box>
            <Form>
              <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                sx={{
                  backgroundColor: 'background.default !important',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                    Modifier le statut
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box sx={{
                    margin: '0 0 15px 0',
                    minWidth: '500px',
                  }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                        Modification de statut pour:
                        {selectedForms.map((form, index) => (
                          <Typography sx={{ fontSize: '0.9em', fontWeight: 600 }} key={index}>{`${form.municipalityName}, ${form.programName} - ${form.studiedYear}`}</Typography>
                        ))}
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ textAlign: 'right', mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel>Statut</InputLabel>
                          <Select
                            name="status"
                            label="Statut"
                            value={formikProps.values.status}
                            onChange={formikProps.handleChange}
                            error={formikProps.touched.status && Boolean(formikProps.errors.status)}
                          >
                            <MenuItem value={FormState.IN_PROGRESS}>En cours</MenuItem>
                            <MenuItem value={FormState.WAITING_RESULT}>Envoyé pour analyse</MenuItem>
                            <MenuItem value={FormState.DONE}>Analyse terminée</MenuItem>
                          </Select>
                          {formikProps.touched.status && formikProps.errors.status && <FormHelperText>{formikProps.errors.status}</FormHelperText>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '15px',
                  }}>
                    <Button
                      variant="outlined"
                      color='error'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setShow(false)}
                    >
                      {Utils.getTranslation(translator.components.promptCreateMunicipality.cancel)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={(e) => formikProps.submitForm()}
                    >
                      Modifier
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          </Box>
        )}
      </Formik>
      <Spinner show={loading}/>
    </Container>
  );
};

export default PromptModifyStatus;
