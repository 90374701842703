/* eslint-disable camelcase */
/* eslint-disable max-len */
import _ from 'lodash';
import SlotTypes from '../SlotTypes';
import QuestionType from '../enums/QuestionType';
import Question from '../Question';
import {
  defaultEtoile, defaultGeomatique, defaultNiveau, etoileActions, etoileEspeces, etoileInitiatives, etoileProgrammes, etoileProjets, etoileProjets2, niveauActions, niveauDeclaration,
} from '../SlotHelp.spec';
import SlotUnit from '../enums/SlotUnit';

const question2_1: Question = {
  id: '15',
  title: 'Identification des milieux naturels d’intérêt pour la connectivité',
  number: '2.1',
  description: [''],
  idEM: 2,
  type: QuestionType.ACTIVITY,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question2_2: Question = {
  id: '16',
  title: 'Superficie des aires protégées',
  number: '2.2',
  description: [''],
  frequency: 3,
  type: QuestionType.INDICATOR,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_3: Question = {
  id: '17',
  title: 'Portrait des bandes riveraines (ou milieux riverains)',
  number: '2.3',
  description: ['Pourcentage des cours d’eau bordés par des bandes riveraines significatives'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question2_4: Question = {
  id: '18',
  title: 'Superficie des sites protégés par la conservation volontaire (SPCV) non reconnus au RAPQ',
  number: '2.4',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: ['Superficie des sites protégés par la conservation volontaire (SPCV) non reconnus au RAPQ en hectares'],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_5: Question = {
  id: '19',
  title: 'Superficie des espaces d\'intérêt pour la conservation identifiés en vertu d\'autres mécanismes et outils',
  number: '2.5',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_6: Question = {
  id: '20',
  title: 'Territoire dont l’affectation et l’usage bénéficie d’une vocation de conservation',
  number: '2.6',
  description: [''],
  frequency: 3,
  type: QuestionType.INDICATOR,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data:number) => {
        if (data >= 21) return 3;
        if (data >= 11) return 2;
        if (data >= 6) return 1;
        return 0;
      },
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[1].slots[0].data[0]`)) || 0;
        const v2 = parseFloat(_.get(values, `${sectionPath}[3].slots[0].data[0]`)) || 0;
        const v3 = parseFloat(_.get(values, `${sectionPath}[4].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return ((((v1 + v2 + v3) / 100) / superficie) * 100).toFixed(2);
      },
      maxScore: 3,
      help: defaultEtoile,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question2_7: Question = {
  id: '21',
  title: 'Largeur des mailles effectives',
  number: '2.7',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_8: Question = {
  id: '22',
  title: 'Superficie totale des milieux naturels terrestres',
  number: '2.8',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_9: Question = {
  id: '23',
  title: 'Nombre de parcelles des milieux naturels terrestres',
  number: '2.9',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question2_10: Question = {
  id: '24',
  title: 'Taille moyenne des parcelles',
  number: '2.10',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_11: Question = {
  id: '25',
  title: 'Taille de la plus grande parcelle non fragmentée',
  number: '2.11',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question2_12: Question = {
  id: '26',
  title: 'Création d\'un plan d\'action ayant pour objectif d\'augmenter la connectivité incluant des objectifs chiffrés',
  number: '2.12',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 5,
  idEM: 2,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question2_13: Question = {
  id: '27',
  title: 'Nombre de liens végétal et/ou corridor de transit qui ont été créés dans l\'année',
  number: '2.13',
  description: ['Minimum de 500 m2, lien végétal minimum 20 m de largeur'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      score: '0',
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      maxScore: 3,
      help: etoileInitiatives,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question2_14: Question = {
  id: '28',
  title: 'Intégration des zones noyaux ou des milieux naturels d’intérêt et du concept de corridor de connectivité dans le règlement de zonage et autres règlements d\'urbanismes et plan d\'urbanisme.',
  number: '2.14',
  description: ['Règlement de zonage et autres règlements d’urbanisme et plan d’urbanisme (PU).'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 3,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question2_15: Question = {
  id: '29',
  title: 'Intégration des zones noyaux ou des milieux naturels d’intérêt et du concept de corridor de connectivité dans le schéma d’aménagement et de développement (SAD) ou Plan métropolitain d’aménagement et de développement (PMAD)',
  number: '2.15',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 3,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question2_16: Question = {
  id: '30',
  title: 'Intégration des zones noyaux ou des milieux naturels d’intérêt et du concept de corridor de connectivité dans le Plan de développement de la zone agricole (PDZA)',
  number: '2.16',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 3,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      score: '0',
      scoringFct: (data: string[]) => parseFloat(data[0]),
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

// CATEGORIE - GRANDS ÉCOSYSTÈMES ET ESPÈCES À STATUT
const question3_1: Question = {
  id: '31',
  title: 'Superficie des écosystèmes forestiers',
  number: '3.1',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question3_2: Question = {
  id: '32',
  title: 'Part de la superficie des écosystèmes forestiers',
  number: '3.2',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[0].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return ((v1 / 100 / superficie) * 100).toFixed(2);
      },
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question3_3: Question = {
  id: '33',
  title: 'Nombre de types d\'écosystèmes forestiers',
  number: '3.3',
  description: ['(nombre de types écologiques différents selon les données écoforestières)'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_4: Question = {
  id: '34',
  title: 'Diversité des écosystèmes forestiers',
  number: '3.4',
  description: ['(indice de diversité de Shannon appliqué aux types écologiques selon les données écoforestières)'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_5: Question = {
  id: '35',
  title: 'Superficie des milieux humides',
  number: '3.5',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question3_6: Question = {
  id: '36',
  title: 'Part des milieux humides par rapport à la superfie totale du territoire',
  number: '3.6',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[4].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return ((v1 / 100 / superficie) * 100).toFixed(2);
      },
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question3_7: Question = {
  id: '37',
  title: 'Nombre de types différents de milieux humides présents sur le territoire',
  number: '3.7',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_8: Question = {
  id: '38',
  title: 'Diversité des milieux humides',
  number: '3.8',
  description: ['(indice de diversité de Shannon appliqué à la classe de milieux humides)'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_9: Question = {
  id: '39',
  title: 'Nombre de complexes de milieux humides',
  number: '3.9',
  description: ['(groupement de milieux humides séparés par moins de 30m) de milieux humides'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_10: Question = {
  id: '40',
  title: 'Taille moyenne des complexes de milieux humides',
  number: '3.10',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question3_11: Question = {
  id: '41',
  title: 'Superficie des milieux hydriques',
  number: '3.11',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question3_12: Question = {
  id: '42',
  title: 'Part des milieux hydriques',
  number: '3.12',
  description: ['Par rapport à la superfie totale du territoire'],
  type: QuestionType.INDICATOR,
  frequency: 3,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[], values: any) => data,
      autofill: (values: any, sectionPath: string) => {
        const v1 = parseFloat(_.get(values, `${sectionPath}[10].slots[0].data[0]`)) || 0;
        const superficie = parseFloat(_.get(values, 'superficie')) || 1;
        return ((v1 / 100 / superficie) * 100).toFixed(2);
      },
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.PERCENTAGE,
    },
  ],
};

const question3_13: Question = {
  id: '43',
  title: 'La liste des espèces à statut présente sur le territoire est connue',
  number: '3.13',
  description: ['donnée obtenue par le géomaticien du programme'],
  type: QuestionType.ACTIVITY,
  frequency: 3,
  doneByRE: true,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: true,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_14: Question = {
  id: '44',
  title: 'Obtention de la liste complémentaire des espèces à statut présentes sur le territoire',
  number: '3.14',
  description: ['La liste des données du CDPNQ est complétée avec des inventaires de terrain, des activités d\'observation citoyenne et les données COSEPAC'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_15: Question = {
  id: '45',
  title: 'Déclaration des espèces à statut présentes sur le territoire',
  number: '3.15',
  description: ['Les occurrences connues d’espèces à statut présentes sur le territoire ont été déclarées au Centre des données du patrimoine naturel du Québec qu’elles aient été identifiées par les employés de l’organisation municipale, ou fournies par les citoyens ou les contracteurs'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      labels: ['non déclarées', 'partiellement', 'toutes déclarées'],
      maxScore: 1,
      help: niveauDeclaration,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_16: Question = {
  id: '46',
  title: 'Mise en place d\'un Programme de suivi d’une ou plusieurs espèces sentinelles',
  number: '3.16',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_17: Question = {
  id: '47',
  title: 'Actions réalisées pour augmenter les connaissances sur les espèces à statut',
  number: '3.17',
  description: ['Sauf études réalisées dans le cadre de projet de développement'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileActions,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_18: Question = {
  id: '48',
  title: 'Création d\'un plan de rétablissement d’une ou de plusieurs espèces sentinelles',
  number: '3.18',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_19: Question = {
  id: '49',
  title: 'Nombre d’espèces touchées par un plan de rétablissement',
  number: '3.19',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileEspeces,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question3_20: Question = {
  id: '50',
  title: 'Intégration des habitats fauniques et floristiques d’intérêt dans le règlement de zonage et autres règlements d’urbanisme et plan d\'urbanisme (PU)',
  number: '3.20',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 3,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_21: Question = {
  id: '51',
  title: 'Intégration des habitats fauniques et floristiques d’intérêt dans le schéma d’aménagement et de développement (SAD) ou plan métropolitain d’aménagement et de développement (PMAD)',
  number: '3.21',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 3,
  idEM: 4,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: true,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: defaultNiveau,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question3_22: Question = {
  id: '52',
  title: 'Réalisation de projets de restauration et/ou création de milieux humides et hydriques',
  number: '3.22',
  description: ['Nombre total de projets'],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 7) return 3;
        if (data >= 3) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileProjets,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

// CATEGORIE - Espèces exotiques envahissantes
const question4_1: Question = {
  id: '53',
  title: 'La liste des espèces exotiques envahissantes présentes sur le territoire est connue',
  number: '4.1',
  description: ['donnée obtenue par le géomaticien du programme'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  doneByRE: true,
  slots: [
    {
      type: SlotTypes.GEOMATIQUE,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => data,
      score: '0',
      help: defaultGeomatique,
      unit: SlotUnit.HECTARE,
    },
  ],
};

const question4_2: Question = {
  id: '54',
  title: 'Déclaration des EEE présentes sur le territoire',
  number: '4.2',
  description: ['Les occurrences connues d’EEE présentes sur le territoire ont été déclarées sur l’outil Sentinelle'],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_3: Question = {
  id: '55',
  title: 'Développement d’un plan d’action pour lutter contre les EEE',
  number: '4.3',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_4: Question = {
  id: '56',
  title: 'Allocation d’un budget annuel alloué à l’éradication des EEE',
  number: '4.4',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_5: Question = {
  id: '57',
  title: 'Établissement d’une directive de gestion des EEE pour les travaux effectués en régie',
  number: '4.5',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_6: Question = {
  id: '58',
  title: 'Intégration d’articles sur la gestion des EEE dans les devis pour les travaux réalisés en sous-traitance',
  number: '4.6',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_7: Question = {
  id: '59',
  title: 'Intégration de la gestion des EEE dans une politique adoptée par le Conseil',
  number: '4.7',
  description: [''],
  type: QuestionType.ACTIVITY,
  frequency: 1,
  idEM: 5,
  slots: [
    {
      type: SlotTypes.NIVEAU,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data: string[]) => parseFloat(data[0]),
      score: '0',
      maxScore: 1,
      help: niveauActions,
      unit: SlotUnit.OPTIONS,
    },
  ],
};

const question4_8: Question = {
  id: '60',
  title: 'Nombre total de programmes de sensibilisation',
  number: '4.8',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 2) return 3;
        if (data >= 1) return 2;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileProgrammes,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

const question4_9: Question = {
  id: '61',
  title: 'Projets de contrôle des EEE',
  number: '4.9',
  description: [''],
  type: QuestionType.INDICATOR,
  frequency: 1,
  slots: [
    {
      type: SlotTypes.ETOILES,
      items: [''],
      data: ['0'],
      scoreCalculatedLater: false,
      optional: false,
      scoringFct: (data:number) => {
        if (data >= 3) return 3;
        if (data >= 2) return 2;
        if (data >= 1) return 1;
        return 0;
      },
      score: '0',
      maxScore: 3,
      help: etoileProjets2,
      unit: SlotUnit.NUMBER_OF,
    },
  ],
};

export {
  question2_1,
  question2_2,
  question2_3,
  question2_4,
  question2_5,
  question2_6,
  question2_7,
  question2_8,
  question2_9,
  question2_10,
  question2_11,
  question2_12,
  question2_13,
  question2_14,
  question2_15,
  question2_16,
  question3_1,
  question3_2,
  question3_3,
  question3_4,
  question3_5,
  question3_6,
  question3_7,
  question3_8,
  question3_9,
  question3_10,
  question3_11,
  question3_12,
  question3_13,
  question3_14,
  question3_15,
  question3_16,
  question3_17,
  question3_18,
  question3_19,
  question3_20,
  question3_21,
  question3_22,
  question4_1,
  question4_2,
  question4_3,
  question4_4,
  question4_5,
  question4_6,
  question4_7,
  question4_8,
  question4_9,
};
