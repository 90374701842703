/* eslint-disable quote-props */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import {
  addDoc, collection, deleteDoc, doc, getDoc, setDoc, updateDoc,
} from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import FormState from '../models/enums/FormState';
import Form from '../models/Form';
import FormSpecPEXBD from '../pages/forms/pexbd_v2/models/FormPEXBD.spec';
import SlotTypes from '../pages/forms/pexbd/models/SlotTypes';
import Question from '../pages/forms/pexbd_v2/models/Question';
import FormSubsection from '../pages/forms/pexbd_v2/models/FormSubsection';
import FormSection from '../pages/forms/pexbd_v2/models/FormSection';

class FormService {
  static createForm = async (programId : string, year : number, municipalities: string[]) => {
    const programDocRef = doc(db, 'Programs', programId);
    const programDocSnap = await getDoc(programDocRef);
    const programDocData = programDocSnap.data();

    // create studied year if it doesnt exist
    const templateDocRef = doc(db, 'Programs', programId, 'Templates', year.toString());
    const templateDocSnap = await getDoc(templateDocRef);

    if (!templateDocSnap.exists()) {
      await setDoc(templateDocRef, {
        id: year.toString(),
      });
    }

    municipalities.map(async (municipalityId: string) => {
      let formSpec = {} as typeof FormSpecPEXBD;
      if (programId === 'qqZTdJVoBk2f4DXjW4Ef') formSpec = FormSpecPEXBD;

      const municipalityDocRef = doc(db, 'Municipalities', municipalityId);
      const municipalityDocSnap = await getDoc(municipalityDocRef);

      const municipalityDocData = municipalityDocSnap.data();

      const formData: any = {};

      if (formSpec && municipalityDocData) {
        formData.programId = programId;
        formData.programName = (programDocData) ? programDocData.name : '';
        formData.municipalityId = municipalityId;
        formData.nomMunicipalite = municipalityDocData.name;
        formData.nomResponsable = municipalityDocData.contactInformation.name;
        formData.courriel = municipalityDocData.contactInformation.email;
        formData.telephone = municipalityDocData.contactInformation.phone;
        formData.anneeEtudiee = String(year);
        formData.anneeAdhesion = municipalityDocData.adhesionYearPEXBD;
        formData.sections = [];

        formSpec.sections.forEach((section, sectionIndex) => {
          if (formSpec.nomMunicipalite === 'Canton et Ville de Valcourt') {
            console.log('here');
          }
          formData.sections.push({
            id: section.id,
            title: section.title,
            subsections: [],
          });

          section.subsections.forEach((subsection, subsectionIndex) => {
            formData.sections[sectionIndex].subsections.push({
              id: subsection.id,
              title: subsection.title,
              questions: [],
            });

            subsection.questions.forEach((question, questionIdex) => {
              formData.sections[sectionIndex].subsections[subsectionIndex].questions.push({
                ...question,
                slots: [],
              });
              question.slots.forEach((slot: any) => {
                formData.sections[sectionIndex].subsections[subsectionIndex].questions[questionIdex].slots.push({
                  data: slot.data || '',
                  score: slot.score || '',
                  type: slot.type || '',
                  maxScore: slot.maxScore || '',
                  help: slot.help || '',
                  unit: slot.unit || '',
                });
              });
            });
          });
        });
      }

      const formDocRef = await addDoc(collection(db, 'Forms'), {
        municipalityId,
        studiedYear: year,
        state: FormState.IN_PROGRESS,
        programId,
        programName: (programDocData) ? programDocData.name : '',
        templateSlug: `${programId}.${year.toString()}`,
        formData,
      });

      if (municipalityDocSnap.exists() && municipalityDocSnap.data()) {
        await updateDoc(municipalityDocRef, {
          formList: [
            ...municipalityDocSnap.data().formList,
            {
              id: formDocRef.id,
              templateSlug: `${programId}.${year.toString()}`,
            },
          ],
        });
      }
    });
  };

  static update = (form: Form) => updateDoc(doc(db, 'Forms', form.id), form);

  static delete = async (formIds: string[]) => {
    for (const formId of formIds) {
      const formDocRef = doc(db, 'Forms', formId);
      const formDocSnap = await getDoc(formDocRef);

      if (formDocSnap.exists()) {
        addDoc(collection(db, 'DeletedDocs'), {
          ...formDocSnap.data(),
          deletedDocType: 'form',
        });

        const municipalityDocRef = doc(db, 'Municipalities', formDocSnap.data().municipalityId);
        const municipalityDocSnap = await getDoc(municipalityDocRef);

        if (municipalityDocSnap.exists()) {
          let { formList } = municipalityDocSnap.data();
          if (formList) {
            formList = formList.filter((item: {id:string, templateSlug:string}) => item.id !== formId);
            await updateDoc(doc(db, 'Municipalities', formDocSnap.data().municipalityId), {
              formList,
            });
          }
        }
        await deleteDoc(formDocRef);

        const reportDocRef = doc(db, 'Reports', formId);
        await deleteDoc(reportDocRef);
      }
    }
  };

  static generateReport = async (repeaterMoyennes: any, values: any, formId: string, spec: typeof FormSpecPEXBD, programId:string, municipalityId: string) => {
    const reportTemp = [] as any;
    const thematiqueTotals = [
      { niveau: 0, etoiles: 0 },
      { niveau: 0, etoiles: 0 },
      { niveau: 0, etoiles: 0 },
    ];
    spec.sections.forEach((section:FormSection, sectionIndex:number) => {
      const indicateurReport = [] as any;
      section.subsections.forEach((subsection:FormSubsection, subsectionIndex:number) => {
        subsection.questions.forEach((question: Question, questionIndex:number) => {
          const questionReport = {
            question,
          } as any;
          question.slots.forEach((slot:any, slotIndex:number) => {
            const slotData = values.sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].slots[slotIndex];
            switch (slot.type) {
              case SlotTypes.NIVEAU:
                if (slot.scoringFct(slotData.data) !== '' && parseFloat(slot.scoringFct(slotData.data)) > 0) {
                  const score = parseFloat(slot.scoringFct(slotData.data));
                  if (questionReport.niveau) {
                    questionReport.niveau += score;
                  } else {
                    questionReport.niveau = score;
                  }
                  if (slot.maxScore) {
                    thematiqueTotals[sectionIndex].niveau += slot.maxScore;
                  }
                } else if (slot.maxScore && !slot.optional) {
                  thematiqueTotals[sectionIndex].niveau += slot.maxScore;
                }
                break;
              case SlotTypes.ETOILES:
                if (slot.scoringFct(slotData.data) !== '' && parseFloat(slot.scoringFct(slotData.data)) > 0) {
                  const score = parseInt(slot.scoringFct(slotData.data), 10);
                  if (questionReport.etoile) {
                    questionReport.etoile += score;
                  } else {
                    questionReport.etoile = score;
                  }
                  if (slot.maxScore) {
                    thematiqueTotals[sectionIndex].etoiles += slot.maxScore;
                  }
                } else if (slot.maxScore && !slot.optional) {
                  thematiqueTotals[sectionIndex].etoiles += slot.maxScore;
                }
                break;
              case SlotTypes.REPEATER:
                if (repeaterMoyennes) {
                  let repeaterMoyenne;
                  if (section.id === '48c01830-0e97-413d-b40a-f2d5ebdad867') {
                    repeaterMoyenne = repeaterMoyennes.repeater15;
                  } else if (section.id === '376d07e5-a2ac-4dc0-98b0-8e6f25c91e09') {
                    repeaterMoyenne = repeaterMoyennes.repeater16;
                  } else if (section.id === '2e33c369-5de1-4b5e-a616-a65bf476cff7') {
                    repeaterMoyenne = repeaterMoyennes.repeater21;
                  } else if (section.id === '7a5ebb3b-a503-4cbf-93da-af8f56b816fd') {
                    repeaterMoyenne = repeaterMoyennes.repeater22;
                  }
                  let totalForm = 0;
                  if (slotData.data) {
                    slotData.data.forEach((v:any) => {
                      totalForm += parseInt(v.amount, 10) || 0;
                    });
                  }
                  const ratio = totalForm / values.population;
                  if (questionReport.etoile) {
                    questionReport.etoile += FormService.scoreRepeater(ratio, repeaterMoyenne);
                  } else {
                    questionReport.etoile = FormService.scoreRepeater(ratio, repeaterMoyenne);
                  }
                }
                if (slot.maxScore) {
                  thematiqueTotals[sectionIndex].etoiles += slot.maxScore;
                }
                break;
              default:
                break;
            }
          });
          indicateurReport.push(questionReport);
        });
      });
      reportTemp.push(indicateurReport);
    });
    const report = {
      gouvernanceDetail: reportTemp[0],
      preservationDetail: reportTemp[1],
      multifonctionnaliteDetail: reportTemp[2],
    } as any;

    const gouvernanceNiveauMax = thematiqueTotals[0].niveau;
    const gouvernanceEtoilesMax = thematiqueTotals[0].etoiles;
    const preservationNiveauMax = thematiqueTotals[1].niveau;
    const preservationEtoilesMax = thematiqueTotals[1].etoiles;
    const multifonctionnaliteNiveauMax = thematiqueTotals[2].niveau;
    const multifonctionnaliteEtoilesMax = thematiqueTotals[2].etoiles;
    const grandTotalNiveauMax = gouvernanceNiveauMax + preservationNiveauMax + multifonctionnaliteNiveauMax;
    const grandTotalEtoilesMax = gouvernanceEtoilesMax + preservationEtoilesMax + multifonctionnaliteEtoilesMax;

    let gNiveauTotal = 0;
    let gEtoileTotal = 0;
    report.gouvernanceDetail.forEach((entry:any) => {
      if (entry.niveau) gNiveauTotal += entry.niveau;
      if (entry.etoile) gEtoileTotal += entry.etoile;
    });

    report.gouvernance = {
      niveau: gNiveauTotal,
      niveau_max: gouvernanceNiveauMax,
      etoiles: gEtoileTotal,
      etoiles_max: gouvernanceEtoilesMax,
    };

    let pNiveauTotal = 0;
    let pEtoileTotal = 0;
    report.preservationDetail.forEach((entry:any) => {
      if (entry.niveau) pNiveauTotal += entry.niveau;
      if (entry.etoile) pEtoileTotal += entry.etoile;
    });

    report.preservation = {
      niveau: pNiveauTotal,
      niveau_max: preservationNiveauMax,
      etoiles: pEtoileTotal,
      etoiles_max: preservationEtoilesMax,
    };

    let mNiveauTotal = 0;
    let mEtoileTotal = 0;
    report.multifonctionnaliteDetail.forEach((entry:any) => {
      if (entry.niveau) mNiveauTotal += entry.niveau;
      if (entry.etoile) mEtoileTotal += entry.etoile;
    });

    report.multifonctionnalite = {
      niveau: mNiveauTotal,
      niveau_max: multifonctionnaliteNiveauMax,
      etoiles: mEtoileTotal,
      etoiles_max: multifonctionnaliteEtoilesMax,
    };

    const niveauTotal = report.gouvernance.niveau + report.preservation.niveau + report.multifonctionnalite.niveau;
    const etoilesTotal = report.gouvernance.etoiles + report.preservation.etoiles + report.multifonctionnalite.etoiles;
    report.summary = {
      niveau: niveauTotal,
      niveau_max: grandTotalNiveauMax,
      niveau_score: FormService.scoreNiveauTotal(niveauTotal / grandTotalNiveauMax),
      etoiles: etoilesTotal,
      etoiles_max: grandTotalEtoilesMax,
      etoiles_score: FormService.scoreEtoilesTotal(etoilesTotal / grandTotalEtoilesMax),
    };
    console.log(values);
    await setDoc(doc(db, 'Reports', formId), {
      id: formId,
      municipalityId,
      programId,
      report,
      municipalityName: values.nomMunicipalite,
      studiedYear: values.anneeEtudiee,
      programName: values.programName,
    });
  };

  // static exportForm = async () => {
  //   let formSpec = {} as typeof FormSpecPEXBD;
  //   // if (formData.programId === '2Py0tJHhTfbE8qWPjcd4') formSpec = FormSpecGroup1;
  //   // if (formData.programId === 'PlhU9DxMiv4loyvKLkHE') formSpec = FormSpecGroup2;
  //   if (formData.programId === 'qqZTdJVoBk2f4DXjW4Ef') formSpec = FormSpecPEXBD;

  //   const workbook = XLSX.utils.book_new();

  //   const worksheet = XLSX.utils.aoa_to_sheet([
  //     ['Nom de la municipalité', 'Année étudiée', 'Année d\'adhésion', 'Nom du programme'],
  //     [formData.nomMunicipalite, formData.anneeEtudiee, formData.anneeAdhesion, formData.programName],
  //     [''],
  //     ['Personne Responsable', 'Fonction', 'Courriel', 'Téléphone'],
  //     [formData.nomResponsable, formData.fonction, formData.courriel, formData.telephone],
  //     [''],
  //     ['Population', 'Superficie (km2)'],
  //     [formData.population, formData.Superficie],
  //   ]);
  //   worksheet['!cols'] = [{ wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Informations Générales');

  //   console.log(formId);
  //   const reportDocRef = doc(db, 'Reports', formId);
  //   const reportDoc = await getDoc(reportDocRef);

  //   console.log(reportDoc.data());

  //   if (reportDoc.exists() && reportDoc.data() && reportDoc.data().report) {
  //     const reportDocData = reportDoc.data();

  //     const reportRows = [];

  //     reportDocData.report.gouvernanceDetail.forEach((item:any) => {
  //       reportRows.push({
  //         item: `${item.number} - ${item.title}`,
  //         niveau: item.niveau || '',
  //         etoiles: item.etoile || '',
  //       });
  //     });

  //     reportRows.push({ item: 'Gouvernance: total', niveau: reportDocData.report.gouvernance.niveau, etoiles: reportDocData.report.gouvernance.etoiles });
  //     reportRows.push({ item: 'Gouvernance: score maximum', niveau: reportDocData.report.gouvernance.niveau_max, etoiles: reportDocData.report.gouvernance.etoiles_max });

  //     reportDocData.report.preservationDetail.forEach((item:any) => {
  //       reportRows.push({
  //         item: `${item.number} - ${item.title}`,
  //         niveau: item.niveau || '',
  //         etoiles: item.etoile || '',
  //       });
  //     });

  //     reportRows.push({ item: 'Preservation: total', niveau: reportDocData.report.preservation.niveau, etoiles: reportDocData.report.preservation.etoiles });
  //     reportRows.push({ item: 'Preservation: score maximum', niveau: reportDocData.report.preservation.niveau_max, etoiles: reportDocData.report.preservation.etoiles_max });

  //     reportDocData.report.multifonctionnaliteDetail.forEach((item:any) => {
  //       reportRows.push({
  //         item: `${item.number} - ${item.title}`,
  //         niveau: item.niveau || '',
  //         etoiles: item.etoile || '',
  //       });
  //     });

  //     reportRows.push({ item: 'Multifonctionnalité: total', niveau: reportDocData.report.multifonctionnalite.niveau, etoiles: reportDocData.report.multifonctionnalite.etoiles });
  //     reportRows.push({
  //       item: 'Multifonctionnalité: score maximum', niveau: reportDocData.report.multifonctionnalite.niveau_max, etoiles: reportDocData.report.multifonctionnalite.etoiles_max,
  //     });

  //     reportRows.push({ item: 'Grand total', niveau: reportDocData.report.summary.niveau, etoiles: reportDocData.report.summary.etoiles });
  //     reportRows.push({ item: 'Grand total: score maximum', niveau: reportDocData.report.summary.niveau_max, etoiles: reportDocData.report.summary.etoiles_max });

  //     const w = XLSX.utils.json_to_sheet(reportRows);
  //     XLSX.utils.sheet_add_aoa(w, [['Indicateur', 'Niveau', 'Étoiles']], { origin: 'A1' });
  //     XLSX.utils.book_append_sheet(workbook, w, 'Résultats');
  //   }

  //   formData.sections.forEach((section:any, sectionIndex:number) => {
  //     const r = [] as any[];
  //     const additionalSheets = [] as any[];
  //     section.subsections.forEach((subsections:any, stepIndex:number) => {
  //       let currRow = { indicateur: formSpec.sections[sectionIndex].subsections[stepIndex].title } as any;
  //       let isFirstSection = true;
  //       subsections.questions.forEach((question:any, questionIndex:number) => {
  //         const sectionSpec = formSpec.sections[sectionIndex].subsections[stepIndex].questions[sectionIndex];
  //         if (isFirstSection) {
  //           currRow = {
  //             ...currRow,
  //             section: `${sectionSpec.number} - ${sectionSpec.title}`,
  //           };
  //         } else {
  //           currRow = {
  //             section: `${sectionSpec.number} - ${sectionSpec.title}`,
  //           };
  //         }
  //         isFirstSection = false;
  //         let isFirstSlot = true;
  //         section.slots.forEach((slot:any, slotIndex:number) => {
  //           const slotSpec = sectionSpec.slots[slotIndex] as any;
  //           const slotType = slotSpec.type;

  //           if (slotType === SlotTypes.NIVEAU) {
  //             slotSpec.items.forEach((item:string, itemIndex:number) => {
  //               if (isFirstSlot) {
  //                 currRow = {
  //                   ...currRow,
  //                 };
  //                 isFirstSlot = false;
  //               } else {
  //                 currRow = {};
  //               }
  //               r.push({
  //                 ...currRow,
  //                 type: 'Niveau',
  //                 item,
  //                 donnee: (slotSpec.labels)
  //                   ? FormService.convertNiveauScoreToLabel(slot.data[itemIndex], slotSpec.labels) : FormService.convertNiveauScoreToLabel(slot.data[itemIndex]),
  //                 commentaires: section.comments,
  //               });
  //             });
  //           }

  //           if (slotType === SlotTypes.ETOILES) {
  //             if (isFirstSlot) {
  //               currRow = {
  //                 ...currRow,
  //               };
  //               isFirstSlot = false;
  //             } else {
  //               currRow = {};
  //             }
  //             r.push({
  //               ...currRow,
  //               type: 'Étoiles',
  //               item: slotSpec.item,
  //               donnee: slot.data,
  //               commentaires: section.comments,
  //             });
  //           }

  //           if (slotType === SlotTypes.GEOMATIQUE) {
  //             slotSpec.items.forEach((item:string, itemIndex:number) => {
  //               if (isFirstSlot) {
  //                 currRow = {
  //                   ...currRow,
  //                 };
  //                 isFirstSlot = false;
  //               } else {
  //                 currRow = {};
  //               }
  //               r.push({
  //                 ...currRow,
  //                 type: 'Géomatique',
  //                 item,
  //                 donnee: slot.data[itemIndex],
  //                 commentaires: section.comments,
  //               });
  //             });
  //           }

  //           if (slotType === SlotTypes.REPEATER) {
  //             const additionalSheet = { worksheet: null as any, title: '' };
  //             const dummyRow = {} as any;
  //             const headerRow = [] as string[];
  //             slotSpec.repeaterFields.forEach((field:any) => {
  //               dummyRow[field.slug] = '';
  //               headerRow.push(field.label);
  //             });
  //             additionalSheet.worksheet = XLSX.utils.json_to_sheet([
  //               dummyRow,
  //               ...slot.data,
  //             ], { skipHeader: true });
  //             XLSX.utils.sheet_add_aoa(additionalSheet.worksheet, [headerRow], { origin: 'A1' });
  //             additionalSheet.title = sectionSpec.number;
  //             additionalSheets.push(additionalSheet);
  //           }
  //         });
  //       });
  //     });
  //     const w = XLSX.utils.json_to_sheet(r);
  //     XLSX.utils.sheet_add_aoa(w, [['Indicateur', 'Sous-indicateur', 'Type', 'Item', 'Données', 'Commentaires']], { origin: 'A1' });
  //     XLSX.utils.book_append_sheet(workbook, w, formSpec.sections[sectionIndex].title);

  //     additionalSheets.forEach((sheet:any) => {
  //       XLSX.utils.book_append_sheet(workbook, sheet.worksheet, sheet.title);
  //     });
  //   });

  //   XLSX.writeFile(workbook, `pexbd-export-${formData.nomMunicipalite}-${formData.anneeEtudiee}.xlsx`, { compression: true });
  // };

  // private static convertNiveauScoreToLabel = (score : string, labels = ['non réalisé', 'en cours', 'réalisé']) : string => {
  //   if (score === '0.5') return labels[1];
  //   if (score === '1') return labels[2];
  //   return labels[0];
  // };

  private static scoreRepeater = (ratio:number, moyenne: number) : number => {
    const relative = ratio / moyenne;
    if (relative >= 1) return 4;
    if (relative >= 0.75) return 3;
    if (relative >= 0.50) return 3;
    if (relative >= 0.25) return 3;
    return 0;
  };

  private static scoreNiveauTotal = (p: number) : number => {
    if (p >= 0.95) return 5;
    if (p >= 0.75) return 4;
    if (p >= 0.50) return 3;
    if (p >= 0.20) return 2;
    return 1;
  };

  private static scoreEtoilesTotal = (p: number) : number => {
    if (p >= 0.95) return 5;
    if (p >= 0.65) return 4;
    if (p >= 0.40) return 3;
    if (p >= 0.20) return 2;
    return 1;
  };
}

export default FormService;
