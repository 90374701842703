import { Box } from '@mui/material';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import FormsTable from '../components/FormsTable';

const HomeAdmin: React.FC = () => (
  <Layout title="Formulaires">
    <Spinner show={false}/>
    <Box sx={{
      p: 2,
      height: 'calc(100vh - 64px)',
    }}>
      <FormsTable />
    </Box>
  </Layout>
);

export default HomeAdmin;
