import {
  Box, IconButton, InputAdornment, TextField, Tooltip, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Calculate, Map } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { PEXBDFormSectionContainer } from '../components/StyledComponents';
import FormSectionNumber from '../components/FormSectionNumber';
import SlotTypes from '../models/SlotTypes';
import NiveauToggleGroup from '../components/NiveauToggleGroup';
import Repeater from '../components/Repeater';
import UpdateFrequencyChip from '../components/UpdateFrequencyChip';
import EngagementChip from '../components/chips/EngagementChip';
import EM from '../models/EM';
import QuestionTypeChip from '../components/chips/QuestionTypeChip';
import ScoreTypeChip from '../components/chips/ScoreTypeChip';
import OptionalTooltip from '../components/chips/OptionalTooltip';
import ChipTooltip from '../components/chips/ChipTooltip';
import { db } from '../../../../FirebaseConfig';
import AlertUtil from '../../../../utils/AlertUtil';
import { useAppDispatch, useAppSelector } from '../../../../store/Hooks';
import Spinner from '../../../../components/Spinner';
import Question from '../models/Question';
import QuestionSlot from '../models/QuestionSlot';
import User from '../../../../models/User';
import Permissions from '../../../../models/enums/Permissions';
import SlotUnit from '../models/enums/SlotUnit';
import SlotNaNMessage from '../components/SlotNaNMessage';
import SlotInfoMessage from '../components/SlotInfoMessage';

type Props = {
    fieldPath: string,
    sectionPath: string,
    question: Question,
};

const FormSection: React.FC<Props> = ({ fieldPath, sectionPath, question }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user) as User;

  const [timeUntilNextUpdate, setTimeUntilNextUpdate] = useState<any>();
  const [loading, setLoading] = useState(false);

  const {
    values, handleChange, setFieldValue, isSubmitting,
  } = useFormikContext<any>();

  useEffect(() => {
    const timeSinceUpdate = (values.anneeEtudiee - values.anneeAdhesion) % question.frequency;
    setTimeUntilNextUpdate(question.frequency - timeSinceUpdate);
  }, [question.frequency]);

  const setDataFromLastYear = async () => {
    setLoading(true);
    const q = query(
      collection(db, 'Forms'),
      where('municipalityId', '==', values.municipalityId),
      where('programId', '==', values.programId),
      where('studiedYear', '==', values.anneeEtudiee - 1),
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docData = querySnapshot.docs[0].data();

      if (!docData) {
        if (user.permission === Permissions.ADMIN) { AlertUtil.createErrorAlert('Impossible de trouver les données de l\'année précédente', dispatch); }
        return;
      }
      const { formData } = docData;
      if (!formData) {
        if (user.permission === Permissions.ADMIN) { AlertUtil.createErrorAlert('Impossible de trouver les données de l\'année précédente', dispatch); }
        return;
      }
      const previousYearSection = _.get(formData, fieldPath);
      if (!previousYearSection || !previousYearSection.slots) {
        if (user.permission === Permissions.ADMIN) { AlertUtil.createErrorAlert('Impossible de trouver les données de l\'année précédente', dispatch); }
        return;
      }
      setFieldValue(`${fieldPath}.slots`, previousYearSection.slots);
    }
    setLoading(false);
    AlertUtil.createSuccessAlert('Données récupérées avec succès', dispatch);
  };

  const getPreviousYearAnswer = async (path: string) => {
    const q = query(
      collection(db, 'Forms'),
      where('municipalityId', '==', values.municipalityId),
      where('programId', '==', values.programId),
      where('studiedYear', '==', values.anneeEtudiee - 1),
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;

    const docData = querySnapshot.docs[0].data();
    if (!docData) return;

    const { formData } = docData;
    if (!formData) return;

    const previousYearValue = _.get(formData, `${path}`);
    if (!previousYearValue) return;

    // eslint-disable-next-line consistent-return
    return previousYearValue;
  };

  const handleChangeScore = async (value: any, slot: QuestionSlot, path: string) => {
    setFieldValue(`${path}.data`, [value]);
    let score = 0;
    if (slot.compairedTo) {
      const comparedToSlot = await getPreviousYearAnswer(slot.compairedTo);
      if (comparedToSlot) {
        score = slot.scoringFct({
          currentYearValue: value,
          previousYearValue: comparedToSlot,
        }, values);
      } else {
        score = 0;
      }
    } else {
      score = slot.scoringFct([value]);
    }
    setFieldValue(`${path}.score`, score);
  };

  const displayUnit = (slot: QuestionSlot) => {
    switch (slot.unit) {
      case SlotUnit.PERCENTAGE:
        return <Typography variant="body1">%</Typography>;
      case SlotUnit.DOLLAR:
        return <Typography variant="body1">CAD</Typography>;
      case SlotUnit.HECTARE:
        return <Typography variant="body1">ha</Typography>;
      default:
        return <></>;
    }
  };

  return (
    <PEXBDFormSectionContainer>
      <FormSectionNumber question={question} />
      <Box sx={{ flexGrow: 1, mb: 3 }}>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{
              mb: '5px', display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'space-between', gap: '10px',
            }}>
              <Box sx={{
                display: 'flex', alignItems: 'flex-start', gap: '5px', justifyContent: 'space-between',
              }}>
                <Box sx={{
                  display: 'flex', flexDirection: 'column', gap: '3px', alignItems: 'flex-start', justifyContent: 'flex-start',
                }}>
                  <Box sx={{
                    display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px',
                  }}>
                    <QuestionTypeChip showIcon={false} showText={true} type={question.type} />
                    {
                      (question.frequency && question.frequency > 1 && timeUntilNextUpdate !== question.frequency)
                        ? <UpdateFrequencyChip
                          isDisabled={_.get(values, 'isDisabled')}
                          frequency={question.frequency}
                          adhesionYear={parseInt(_.get(values, 'anneeAdhesion'), 10)}
                          studiedYear={parseInt(_.get(values, 'anneeEtudiee'), 10)}
                        /> : <></>
                    }
                    {
                      (question.slots.some((slot) => slot.optional) && values.population < 25000) && <OptionalTooltip />
                    }
                  </Box>
                  <Typography variant="h5" sx={{ fontSize: '18px' }}>{question.title}</Typography>
                </Box>
                <Box sx={{
                  display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px',
                }}>
                  <Box sx={{
                    display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap',
                  }}>

                    {question.idEM && <EngagementChip emNumber={question.idEM} emTitle={EM.find((e: any) => e.id === question.idEM)?.value} />}
                  </Box>
                </Box>
              </Box>
              {question.description[0] && question.description.map((description:string, index:number) => (
                <Box key={index} sx={{ mt: 2 }}>
                  <Typography variant='body1' key={index} sx={{ mb: 1, fontSize: '16px' }}>{description}</Typography>
                </Box>
              ))}
              {
                question.doneByRE
                && <SlotInfoMessage severity='info' message="Réalisé par l’équipe de Réseau Environnement"/>
              }
            </Box>
            {question.slots && question.slots.map((slot: QuestionSlot, index:number) => {
              switch (slot.type) {
                case SlotTypes.NIVEAU:
                  return (<Fragment key={index}>
                    {slot.items?.map((item:string, i:number) => (<Box key={i} sx={{
                      display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '7px', marginTop: '10px',
                    }}>
                      <ScoreTypeChip
                        slot={slot}
                        score={_.get(values, `${fieldPath}.slots[${index}].score`)}
                        path={`${fieldPath}.slots[${index}].data`}
                        data={_.get(values, `${fieldPath}.slots[${index}].data`)}
                      />
                      {
                        !question.doneByRE
                        && <>
                          <NiveauToggleGroup
                            slot={slot}
                            orientation="horizontal"
                            fieldPath={`${fieldPath}.slots[${index}]`}
                            labels={slot.labels}
                          />
                          {
                            (question.frequency > 1 && timeUntilNextUpdate !== question.frequency) && <Tooltip title={
                              <ChipTooltip slot={slot} title="Utiliser les données de l'année précédente" />
                            }>
                              <span>
                                <IconButton disabled={isSubmitting || values.isDisabled} onClick={setDataFromLastYear} color='primary'>
                                  <UpdateIcon/>
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </>
                      }
                    </Box>))
                    }</Fragment>);
                case SlotTypes.REPEATER:
                  return (<Fragment key={index}>
                    <Box sx={{
                      display: 'flex', mb: '15px', justifyContent: 'flex-start', alignItems: 'center', gap: 1,
                    }}>
                      <ScoreTypeChip
                        score={_.get(values, `${fieldPath}.slots[${index}].score`)}
                        slot={slot}
                      />
                    </Box>
                    {
                      !question.doneByRE
                        && <>
                          <Repeater fieldPath={`${fieldPath}.slots[${index}]`} repeaterFields={slot.repeaterFields}/>
                        </>
                    }
                  </Fragment>
                  );
                case SlotTypes.GEOMATIQUE:
                  return (<Fragment key={index}>
                    {slot.items.length > 0 && slot.items.map((item:string, i:number) => (<Box key={i} sx={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2,
                    }}>
                      <Box sx={{
                        minWidth: 223, display: 'flex', gap: '7px', alignItems: 'center', justifyContent: 'flex-start',
                      }}>
                        {
                          !question.doneByRE
                        && <>
                          <Tooltip title={
                            <ChipTooltip slot={slot} title="Indicateur géomatique" subtitle="Pas de pointage, outil de diagnostic" />
                          }>
                            <Box sx={{
                              width: '35px',
                              height: '35px',
                              borderRadius: '4px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#9F8772',
                              flexShrink: 0,
                            }}>
                              <Map style={{ fill: '#fff' }}/>
                            </Box>
                          </Tooltip>
                          <TextField
                            name={`${fieldPath}.slots[${index}].data[${i}]`}
                            value={_.get(values, `${fieldPath}.slots[${index}].data[${i}]`)}
                            onChange={(e) => handleChangeScore(e.target.value, slot, `${fieldPath}.slots[${index}]`)}
                            label="Donnée"
                            type="number"
                            fullWidth
                            size='small'
                            disabled={_.get(values, 'isDisabled')}
                            InputProps={{
                              endAdornment: (
                                slot.autofill
                              && <InputAdornment position="end">
                                <Tooltip title="Calculer la valeur">
                                  <IconButton
                                    aria-label="Calculer la valeur"
                                    onClick={() => (slot.autofill ? setFieldValue(`${fieldPath}.slots[${index}].data[${i}]`, slot.autofill(values, sectionPath).toString()) : '')}
                                    onMouseDown={(e: any) => e.preventDefault()}
                                    color="primary"
                                  >
                                    <Calculate/>
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">{displayUnit(slot)}</InputAdornment>
                              ),
                            }}
                          />
                          {
                            (question.frequency > 1 && timeUntilNextUpdate !== question.frequency) && <Tooltip title={
                              <ChipTooltip slot={slot} title="Utiliser les données de l'année précédente" />
                            }>
                              <IconButton disabled={isSubmitting || values.isDisabled} onClick={setDataFromLastYear} color='primary'>
                                <UpdateIcon/>
                              </IconButton>
                            </Tooltip>
                          }
                        </>
                        }
                      </Box>
                    </Box>))
                    }</Fragment>);
                case SlotTypes.ETOILES:
                  return (<Fragment key={index}>
                    <SlotNaNMessage slot={slot} lastYearsData={getPreviousYearAnswer} />
                    <Box sx={{
                      minWidth: 223, display: 'flex', gap: '7px', alignItems: 'center', marginTop: '10px',
                    }}>
                      <ScoreTypeChip
                        score={_.get(values, `${fieldPath}.slots[${index}].score`)}
                        path={`${fieldPath}.slots[${index}].data`}
                        data={_.get(values, `${fieldPath}.slots[${index}].data`)}
                        calcLastYear={getPreviousYearAnswer}
                        slot={slot}/>
                      <TextField
                        name={`${fieldPath}.slots[${index}].data`}
                        value={_.get(values, `${fieldPath}.slots[${index}].data`)}
                        onChange={(e) => handleChangeScore(e.target.value, slot, `${fieldPath}.slots[${index}]`)}
                        label="Donnée"
                        size='small'
                        type="number"
                        disabled={_.get(values, 'isDisabled')}
                        InputProps={{
                          endAdornment: (
                            slot.autofill
                            && <InputAdornment position="end">
                              <Tooltip title="Calculer la valeur">
                                <IconButton
                                  aria-label="Calculer la valeur"
                                  onClick={() => {
                                    if (slot.autofill) {
                                      const value = slot.autofill(values, sectionPath).toString();
                                      setFieldValue(`${fieldPath}.slots[${index}].data`, value.toString());
                                      handleChangeScore(value, slot, `${fieldPath}.slots[${index}]`);
                                    }
                                  }}
                                  onMouseDown={(e: any) => e.preventDefault()}
                                  color="primary"
                                >
                                  <Calculate/>
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment position="start">{displayUnit(slot)}</InputAdornment>
                          ),
                        }}
                      />
                      {
                        slot.previewFct
                        && <Box>
                          <Typography>
                            {slot.previewFct({ areaVegetation: _.get(values, `${fieldPath}.slots[${index}].data[0]`), areaMunicipality: values.superficie }).label}
                          </Typography>
                          {slot.previewFct({ areaVegetation: _.get(values, `${fieldPath}.slots[${index}].data[0]`), areaMunicipality: values.superficie }).value}
                        </Box>
                      }
                      {
                        (question.frequency > 1 && timeUntilNextUpdate !== question.frequency) && <Tooltip title={
                          <ChipTooltip slot={slot} title="Utiliser les données de l'année précédente" />
                        }>
                          <span>
                            <IconButton onClick={setDataFromLastYear} color='primary'>
                              <UpdateIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    </Box>
                  </Fragment>);
                default:
                  return (<Fragment key={index}></Fragment>);
              }
            })}
            <TextField
              name={`${fieldPath}.comments`}
              value={_.get(values, `${fieldPath}.comments`) || ''}
              onChange={handleChange}
              label="Commentaires et source des données"
              multiline
              rows={2}
              sx={{ marginTop: '20px' }}
              fullWidth
              disabled={_.get(values, 'isDisabled')}
            />
            <p style={{
              fontSize: 12,
              color: '#880091',
              textAlign: 'center',
              margin: 0,
            }}>
              {_.get(values, `${fieldPath}.scoreCalculatedLater`) && '*Le pointage sera calculé une fois que les municipalités auront remplis le formulaire.'}</p>
          </Box>
        </Box>
      </Box>
      <Spinner show={loading}/>
    </PEXBDFormSectionContainer>
  );
};

export default FormSection;
